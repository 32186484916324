
import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

export interface IReportData {
    date?: string,
    subject?: string,
    host?: string,
    room?: string,
    catering_code?: string,
    menu_category?: string,
    menu_items?: string,
    quantities?: number,
    item_price?: number,
    room_email?: string,
    symbol?: string,
    start_date?: string,
    end_date?: string,
    attendees?: string[],
    status?: string,
    setup?: number,
    breakdown?: number,
    is_all_day?: string,
    is_cancelled?: string,
    recurrence?: string,
    notes?: string[],
    catering?: string,
}

@Injectable({
    providedIn: 'root'
})
export class ReportsService extends BaseService<any> {
    constructor(protected http: CommsService) {
        super();
        this.model.name = 'report';
        this.model.route = '/reports';
        this.observers.list = this.subjects.list.asObservable();
    }

   // Convert data to local format
   public processItem(item: any) {
    const data: IReportData = {
        date: (item['Date'] || '').replace(' ', 'T').replace(' ', ''),
        subject: item['Subject'] || item.subject,
        host: item['Host'] || item.organizer,
        room: item['Room'] || item.location,
        catering_code: item['Code'],
        menu_category: item['Category'],
        menu_items: item['Item Name'],
        quantities: item['Item Quantity'],
        item_price: item['Item Price'],
        room_email: item.room_email,
        symbol: item.room_email ? this.symbolForRoom(item.room_email) : '',
        start_date: item.start,
        end_date: item.end,
        attendees: item.attendees,
        status: item.responseStatus,
        setup: item.setup,
        breakdown: item.breakdown,
        is_all_day: item.isAllDay ? 'yes' : 'no',
        is_cancelled: item.isCancelled ? 'yes' : 'no',
        recurrence: item.recurrence,
        notes: item.notes,
        catering: item.catering,
    }
    return data;
}

    /**
     * Get currency code for the given room
     * @param email Email of the room
     */
    private symbolForRoom(email: string) {
        const room = this.parent.Spaces.item(email);
        if (room) {
            const building = this.parent.Spaces.building(room) as any;
            if (building) {
                return building.currency || 'USD';
            }
        }
        return 'USD';
    }
}
