import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../shared/globals/base.component';
import { AppService } from '../../services/app.service';

import * as dayjs from 'dayjs';
import { IEvent, ISpace } from '../../services/data/models/interfaces';

@Component({
    selector: 'app-week-view',
    templateUrl: './week-view.template.html',
    styleUrls: ['./week-view.styles.scss']
})
export class WeekViewComponent extends BaseComponent implements OnInit {
    public model: any = { settings: {} };

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.model.days = [];
        this.generateTimeBlocks();
        this.subscription('date', this.service.listen('APP.date', (date) => {
            const old_date = dayjs(this.model.date || null).startOf('week');
            const old_week_end = dayjs(old_date).endOf('week');
            this.model.date = date;
            const new_date = dayjs(date || null);
            this.model.selected = null;
            if (!new_date.isBetween(old_date, old_week_end, 'm', '[]')) {
                this.updateBookings();
            } else {
                this.updateActiveDate();
            }
        }));
        this.subscription('level', this.service.listen('APP.level', (lvl) => {
            this.model.level = lvl;
            this.model.selected = false;
            this.updateBookings();
        }));
        this.subscription('room_type', this.service.listen('APP.room_type', (type) => {
            this.model.room_type = type || -1;
            this.updateBookings();
        }));
        this.interval('bookings', () => this.updateBookings(), 5 * 60 * 1000);
        this.service.Analytics.screen(`Week View`);
        this.service.Analytics.page(`/week-view`);
    }

    public generateTimeBlocks() {
        // Get settings
        const start = this.model.settings.start_hour || 7;
        const end = this.model.settings.end_hour || 21;
        const block_size = this.model.settings.block_size || 60;
        // Generate times
        const start_time = dayjs().hour(start % 24).minute((start % 1) * 60).second(0);
        const end_time = dayjs(start_time).hour(end % 24).minute((end % 1) * 60);
        const length = end_time.diff(start_time, 'm');
        // Generate time blocks
        this.model.blocks = [];
        for (let time = dayjs(start_time); time.isSameOrBefore(end_time, 'm'); time = time.add(block_size, 'm')) {
            this.model.blocks.push({
                id: time.format('H:00'),
                display: time.format('h:00 A'),
                offset: Math.abs(start_time.diff(time, 'm')) / length,
                length: block_size / length,
                hour: time.minute() === 0,
                hour_value: time.format('HH'),
                raw: time.valueOf()
            });
        }
        this.updateBookings();
    }

    public updateBookings() {
        this.timeout('update', () => {
            const date = dayjs(this.model.date || null);
            const week_start = dayjs(date).startOf('week');
            const week_end = dayjs(week_start).endOf('week');
            this.model.days = [];
            for (let day = dayjs(week_start); day.isSameOrBefore(week_end, 'd'); day = day.add(1, 'd')) {
                this.model.days.push({
                    time: day.valueOf(),
                    display: day.format('ddd Do MMM'),
                    active: day.isSame(date, 'd'),
                    weekend: day.day() === 0 || day.day() === 6,
                    bookings: []
                });
            }
            this.model.update_level = this.model.level;
            this.model.loading = true;
            this.service.Events.query({
                zone_ids: this.model.level !== -1 ? this.model.level : this.service.Buildings.current().id,
                period_start: week_start.unix(),
                period_end: week_end.unix()
            }).then((events: IEvent[]) => {
                // const type = this.model.room_type;
                // const room_list = rooms.filter(i => type && type !== -1 ? i.zones.indexOf(type) >= 0 : true);
                const systemIds = events.map(event => event.system.id).filter((el, i, r) => r.indexOf(el) === i);

                this.service.Spaces.clearTimeline(week_start.valueOf(), week_end.valueOf());
                for (const sysId of systemIds) {
                    const spaceEvents = events.filter(event => event.system.id === sysId);
                    this.service.Spaces.updateTimeline(sysId, spaceEvents);
                }
                for (const d of this.model.days) {
                    d.bookings = this.getBookingsForDate(d.time, events || []);
                }
                this.model.loading = false;
            }, () => this.model.loading = false);
        });
    }

    public getBookingsForDate(date: number, bookings: IEvent[]) {
        let day = dayjs(date);
        let end = null;
        const used_ids = [];
        const bkn_list = [];
        for (const blk of (this.model.blocks || [])) {
            day = day.hour(+blk.hour_value);
            end = dayjs(day).add(1, 'h');
            const bkns: IEvent[] = [];
            for (const bkn of bookings) {
                const start = dayjs(bkn.event_start * 1000);
                if (start.isBetween(day, end, 'm', '[)') && used_ids.indexOf(bkn.id) < 0) {
                    bkns.push(bkn);
                    used_ids.push(bkn.id);
                }
            }
            bkns.sort((a, b) => a.event_start - b.event_start || a.event_end - b.event_end);
            bkn_list.push(bkns);
        }
        return bkn_list;
    }

    private updateActiveDate() {
        const date = dayjs(this.model.date || null);
        for (const day of this.model.days) {
            day.active = dayjs(day.time).isSame(date, 'd');
        }
    }

    public selectBlock(item, index, block) {
        this.model.selected = item.bookings[index];
        const hours = dayjs(block.raw).hour();
        const minutes = dayjs(block.raw).minute();
        this.model.time = dayjs(item.time).hour(hours).minute(minutes);
    }

}
