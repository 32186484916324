import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '../../../../shared/globals/base.component';
import { AppService } from '../../../../services/app.service';
import { IEvent, ISpace, ICateringOrderDisplay } from '../../../../services/data/models/interfaces';
import { Utils } from '../../../../shared/utility.class';

import * as dayjs from 'dayjs';

@Component({
    selector: 'catering-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent extends BaseComponent implements OnInit {
    constructor(private service: AppService, private _route: ActivatedRoute) {
        super();
    }

    public model: any = {};
    /** object to store visual controls */
    public display: any = {};
    /** Top Show Dropdown options - tbd */
    public show_options: string[] = ['Show All', 'Show Kitchen', 'Show Pantry', 'Show Empty', 'Show Non-Empty'];
    public show_selection = 0;

    /** Grid/Table controls */
    public order_headings: string[] = [
        'Time',
        'Room',
        'Meeting Type',
        'Host',
        'Package Types',
        'Attendees (Expected)',
        'Order Status',
        'Room Status',
    ];
    /** Column widths as % - futureproofing for column resizing */
    public column_widths: number[] = [10.0, 10.2, 10.0, 10.0, 10.2, 8.2, 20.2, 20.2];
    /** Status List */
    public orders_statuses: string[] = ['accepted', 'preparing', 'ready', 'delivered', 'cancelled'];
    /** Room Status List */
    public room_statuses: string[] = ['Setup', 'Cleaning', 'In Use', 'Available'];
    /** List of orders for the current day */
    public orders: ICateringOrderDisplay[] = [];

    public filtered_orders: ICateringOrderDisplay[] = [];
    /**  */
    public view_id: string;
    /** Whether the orders are being loaded */
    public loading: boolean;

    ngOnInit(): void {
        /** Dropdown display control */
        this.model.show_dropdown = new Array(this.orders.length).fill(false);
        this.model.show_room_dropdown = new Array(this.orders.length).fill(false);
        this.model.settings = this.service.Settings.get('app.day_view') || {};
        this.init();
    }

    public init() {
        const user = this.service.Users.current();
        if (!this.service.Settings.setup) {
            setTimeout(() => this.init(), 500);
        }
        this.loading = true;
        this.subscription(
            'date',
            this.service.listen('APP.date', (date) => {
                this.model.date = date;
                this.updateTime();
                this.loadOrders();
            })
        );

        this.subscription(
            'bld',
            this.service.Buildings.listen((bld) => (bld ? this.loadOrders() : ''))
        );

        this.subscription(
            'route.query',
            this._route.queryParamMap.subscribe((params) => {
                if (params.has('id')) {
                    this.view_id = params.get('id');
                    let order = this.orders.find((i) => i.booking.id === this.view_id);
                    if (!order && sessionStorage) {
                        const booking_str = sessionStorage.getItem('CONCIERGE.view_order');
                        if (booking_str) {
                            order = this.processOrder(JSON.parse(booking_str));
                        }
                    }
                    if (order) {
                        this.view_id = null;
                        this.showCateringDetails(order);
                    }
                }
            })
        );

        this.updateTime();
        this.interval('time', () => this.updateTime(), 10 * 1000);
        this.interval('time', () => this.loadOrders(), 2 * 60 * 1000);
    }

    /**
     * Grab bookings with catering orders for the selected date
     */
    public loadOrders() {
        this.timeout('load_order', () => {
            const bld = this.service.Buildings.current();
            if (bld) {
                const date = dayjs(this.model.date).startOf('d');
                this.display.date = date.format('DD MMM YYYY');
                this.service.Spaces.query({
                    period_start: date.unix(),
                    period_end: date.add(1, 'd').unix(),
                    zone_ids: bld.id,
                }).then(
                    (room_list) => {
                        this.orders = [];
                        for (const room of room_list) {
                            for (const event of room.events) {
                                if (event.extension_data.catering) {
                                    const order = this.processOrder({ ...event });
                                    if (order) {
                                        this.orders.push(order);
                                    }
                                }
                            }
                        }
                        this.orders.sort((a, b) => a.date - b.date);
                        if (this.view_id) {
                            const order = this.orders.find((i) => i.booking.id === this.view_id);
                            if (order) {
                                this.view_id = null;
                                this.showCateringDetails(order);
                            }
                        }
                        this.displayChange(this.show_selection);
                        this.loading = false;
                    },
                    (e) => {
                        this.loading = false;
                    }
                );
            }
        });
    }

    /** Changing the "show" option */
    public displayChange(index: number) {
        this.show_selection = index;
        switch (index) {
            case 1: // Kitchen
                this.filtered_orders = this.orders.filter((i) => i.is_kitchen);
                break;
            case 2: // Pantry
                this.filtered_orders = this.orders.filter((i) => i.is_pantry);
                break;
            case 3: // Empty
                this.filtered_orders = this.orders.filter((i) => !i.items || !i.items.length);
                break;
            case 4: // Non-Empty
                this.filtered_orders = this.orders.filter((i) => i.items && i.items.length > 0);
                break;
            default:
                this.filtered_orders = [...this.orders];
        }
    }

    /** Update the status of an order */
    public statusUpdate(status, order, index) {
        const old_status = order.status;
        order.status = status || '';
        this.model.show_dropdown[index] = false;
        const booking = order.booking;
        booking.catering = { ...booking.catering, order_status: status };
        this.service.Events.updateItem(booking.id, booking).then(
            () => {},
            () => {
                this.service.error('Failed to update status of meeting order');
                order.status = old_status || '';
            }
        );
    }

    /** Update the status of a room */
    public roomStatusUpdate(status, order, index) {
        const old_status = order.room.status;
        order.room.status = status || '';
        this.model.show_room_dropdown[index] = false;
        const room = order.room;
        this.service.Spaces.updateItem(order.room.id, room).then(
            () => {
                // do nothing
            },
            () => {
                this.service.error('Failed to update status of room');
                order.room.status = old_status || '';
            }
        );
    }

    /** quickly borrowed from day view */
    public updateTime() {
        // Get settings
        const start = this.model.settings.start_hour || 6.5;
        const end = this.model.settings.end_hour || 21.5;
        // Generate times
        const start_time = dayjs()
            .hour(start % 24)
            .minute((start % 1) * 60);
        const end_time = dayjs()
            .hour(end % 24)
            .minute((end % 1) * 60);
        const length = Math.abs(Math.floor(start_time.diff(end_time, 'm')));
        const now = dayjs(this.model.date || '');
        const hour = Math.floor(now.hour() + now.minute() / 60);
        this.model.now = {
            display: now.format('h:mm A'),
            date: now.format('DD MMM YYYY'),
            raw: now.valueOf(),
            dow: now.format('dddd'),
            offset: Math.floor(now.diff(start_time, 'm')) / length,
            today: now.isSame(dayjs(), 'd'),
            active: `${hour}:00`,
        };
    }

    /** Initiate catering modal to display notes and details */
    public showCateringDetails(order: ICateringOrderDisplay) {
        this.timeout(
            'show catering',
            () => {
                if (this.model.show_dropdown.indexOf(true) > -1 || this.model.show_room_dropdown.indexOf(true) > -1) {
                    return;
                }
                this.service.Overlay.openModal(
                    'catering-details',
                    {
                        data: {
                            order,
                        },
                    },
                    (event) => {
                        if (event.type === 'updated') {
                            order.booking = event.data.order.booking;
                            console.log('Order:', order);
                            const index = this.orders.findIndex((i) => i.booking.id === order.booking.id);
                            if (index >= 0) {
                                console.log('Replaced old order');
                                this.orders.splice(index, 1, order);
                                console.log('Orders:', this.orders[index]);
                            }
                            sessionStorage.removeItem('CONCIERGE.view_order');
                            this.displayChange(this.show_selection);
                        } else {
                            event.close();
                        }
                    }
                );
            },
            1
        );
    }

    /**
     * Process catering order data from given booking
     * @param booking Booking with catering order details
     */
    private processOrder(booking: IEvent): ICateringOrderDisplay {
        if (booking.extension_data.catering && booking.extension_data.room) {
            const order: any = booking.extension_data.catering || {};
            const kitchen = Utils.unique(order.kitchen || []).join(' + ');
            const pantry = Utils.unique(order.pantry || []).join(' + ');
            const type = kitchen && pantry ? `${kitchen} + ${pantry}` : kitchen + pantry;

            const catering_order = {
                name: booking.extension_data.room.name,
                date: booking.extension_data.date,
                host: booking.extension_data.organiser,
                is_external: booking.extension_data.visitors,
                room: booking.extension_data.room,
                attendee_count: (booking.attendees || []).length,
                status: order.status || order.order_status || '',
                items: order.items,
                booking,
                is_kitchen: !!kitchen,
                is_pantry: !!pantry,
                type,
                expected: booking.attendees.length, // booking.expected_attendees[booking.room.id]
            };
            return catering_order;
        }
        return null;
    }
}
