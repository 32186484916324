
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/globals/base.component';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.template.html',
    styleUrls: ['./notifications.styles.scss']
})
export class AppNotificationsComponent extends BaseComponent implements OnInit {
    public model: any = {};

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.model.settings = this.service.Settings.get('app.notifications');
        this.service.Events.listen<any>('notification_list', (resp) => {
            if (!resp) { return; }
            this.model.parking = resp.parking;
            this.model.catering = resp.catering;
            this.model.not_confirmed = resp.not_confirmed;
        });
    }
}
