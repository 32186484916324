
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { BaseComponent } from '../../../../../shared/globals/base.component';
import { IBuilding, ICateringMenuCategory, ICateringMenuItem } from '../../../../../services/data/models/interfaces';
import { AppService } from '../../../../../services/app.service';
import { CateringGroupModalComponent } from '../../../overlays/group-modal/group-modal.component';
import { CateringItemModalComponent } from '../../../overlays/item-modal/item-modal.component';


@Component({
    selector: 'catering-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss'],
    animations: [
        trigger('show', [
            transition(':enter', [style({ opacity: 0, height: 0 }), animate(200, style({ opacity: 1, height: '*' }))]),
            transition(':leave', [style({ opacity: 1, height: '*' }), animate(200, style({ opacity: 0, height: 0 }))]),
        ])
    ]
})
export class CateringCategoryListComponent extends BaseComponent {
    @Input() public category: ICateringMenuCategory;
    /** Emitter for change events to the menu */
    @Output() public event = new EventEmitter();
    /** Active building */
    public building: IBuilding;
    /** Show options for the category */
    public shown_option: number;

    /** Currrency symbol for the active building */
    public get symbol(): string {
        const bld = this.building || this.service.Buildings.current();
        return (bld as any).currency || 'USD';
    }

    constructor(public service: AppService) {
        super();
    }

    /**
     * Add new item to the menu category
     */
    public newItem() {
        this.itemAction({});
    }

    /**
     * Edit the given item
     * @param item Item to edit
     */
    public editItem(item: ICateringMenuItem) {
        this.itemAction({ item });
    }

    /**
     * Edit or Add new item based of the passed data
     * @param data
     */
    private itemAction(data: { item?: ICateringMenuItem }) {
        const category = this.shown_option || this.shown_option === 0
                ? this.category.items[this.shown_option]
                : this.category
        this.shown_option = null;
        this.service.Overlay.openModal('catering-item', {
            cmp: CateringItemModalComponent,
            data: { ...data, category }
        }, (event) => {
            if (event.type === 'finish') {
                this.event.emit('update');
            }
            event.close();
        });
    }

    /**
     * Add new sub-category or package to the menu
     */
    public newGroup(use_active: boolean = false) {
        const category: any = use_active
            ? (this.shown_option || this.shown_option === 0
                ? this.category.items[this.shown_option]
                : this.category)
            : this.category;
        this.groupAction({ category });
    }

    /**
     * Edit the given group
     * @param item Group to edit
     */
    public editGroup(item: ICateringMenuCategory) {
        const category = this.category;
        this.groupAction({ category, group: item });
    }

    /**
     * Edit or Add new category based of the passed data
     * @param data
     */
    private groupAction(data: { category: ICateringMenuCategory, group?: ICateringMenuCategory }) {
        this.shown_option = null;
        this.service.Overlay.openModal('catering-category', {
            cmp: CateringGroupModalComponent,
            data
        }, (event) => {
            if (event.type === 'finish') {
                this.event.emit('update');
            }
            event.close();
        });
    }

    public deleteGroup(item: ICateringMenuCategory) {
        if (item.id) {
            this.shown_option = null;
            (item as any).deleting = true;
            this.service.CateringCategories.delete(item.id).then(() => {
                this.event.emit('removed');
                this.service.success('Succesfully deleted item group');
            }, (e) => {
                    (item as any).deleting = false;
                    if (e !== 'User cancelled'){
                        this.service.error('Failed to delete item group');
                    }
            });
        }
    }
}

