
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, Input, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../../../shared/globals/base.component';
import { ISpace, ICateringMenuCategory, ICateringMenuItem } from '../../../../../services/data/models/interfaces';
import { AppService } from '../../../../../services/app.service';

@Component({
    selector: 'catering-edit-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('200ms')),
        ])
    ]
})
export class CateringEditListItemComponent extends BaseComponent implements OnChanges {
    /** Simple display of details */
    @Input() simple: boolean;
    /** Space selected to add catering to */
    @Input() public space: ISpace;
    /** Catering parent group or category */
    @Input() public parent: ICateringMenuCategory;
    /** Catering item or item group */
    @Input() public item: any;
    /** Field for storing ordered catering items */
    @Input() field: ADynamicFormField;
    /** Whether the item is currently selected */
    @Input() public selected: boolean;
    /** Whether the item can be selected */
    @Input() public disabled: number;
    /** Emitter for changes to the selected amount of the item */
    @Output() public selectedChange = new EventEmitter<boolean>();
    /** Whether to show list of sub items */
    public show_list: boolean;
    /** Step value for adding catering items */
    public step: number = 1;
    /** State of modifier keys */
    public key_states: { shift: boolean, control: boolean } = { shift: false, control: false };


    constructor(private _service: AppService) {
        super();
    }

    /** Currency code for the active space */
    public get symbol(): string {
        const building = this._service.Spaces.building(this.space) || {}  as any;
        return building.currency || 'USD';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.field && this.field) {
            this.subscription('control', this.field.control.valueChanges.subscribe(() => this.orderUpdated()));
        }
        if (changes.space && this.space) {
            this.orderUpdated();
        }
    }

    /**
     * Add the given item from the catering order
     * @param item Item to add
     */
    public add(item: ICateringMenuItem) {
        if (this.field && this.space) {
            const item_map = this.field.control.value || {};
            if (!item_map[this.space.id]) {
                item_map[this.space.id] = { room_id: this.space.id, name: this.space.name };
            }
            const item_list = item_map[this.space.id].items || [];
            const itm = item_list.find(i => i.id === item.id || i.name === this.item.name);
            if (itm) {
                if (!itm.amount) { itm.amount = 0 }
                itm.amount += this.step;
                if (itm.amount && itm.amount < (itm.minimum || 1)) {
                    itm.amount = Math.max(itm.minimum || 1, this.step);
                }
                if (itm.amount > (itm.maximum || 100)) {
                    itm.amount = (itm.maximum || 100);
                }

            } else {
                (item as any).amount = Math.max(1, item.minimum || 1);
                if (item.amount > (item.maximum || 100)) {
                    item.amount = (item.maximum || 100);
                }
                item_list.push({ ...item });
            }
            item_map[this.space.id].items = item_list;
            this.field.setValue(item_map);
        }
    }

    /**
     * Remove the given item from the catering order
     * @param item Item to remove
     */
    public remove(item: ICateringMenuItem) {
        if (this.field && this.space) {
            const item_map = this.field.control.value || {};
            if (!item_map[this.space.id]) {
                item_map[this.space.id] = { room_id: this.space.id, name: this.space.name };
            }
            let item_list = item_map[this.space.id].items || [];
            const itm = item_list.find(i => i.id === item.id || i.name === item.name);
            if (itm) {
                itm.amount = (itm.amount || 0) - this.step;
                if (itm.amount < (itm.minimum || 0)) {
                    itm.amount = 0;
                    item_list = item_list.filter(i => i.id !== itm.id);
                }
            }
            item_map[this.space.id].items = item_list;
            this.field.setValue(item_map);
        }
    }

    /**
     * Toggle the selected state of the
     */
    public toggle() {
        if (this.parent && this.parent.must_select > 0) {
            if (this.parent.must_select === 1) {
                this.selected = true;
            } else {
                this.selected = !this.selected;
            }
            this.selectedChange.emit(this.selected);
        }
    }

    public handleSelect(item: ICateringMenuItem) {
        if (this.item.must_select === 1) {
            for (const i of this.item.items) {
                i.selected = (item.id === i.id || item.name === i.name);
            }
        } else if (this.item.must_select > 1 && item.selected) {
            const active = this.item.items.reduce((v, i) => v + (i.selected ? 1 : 0), 0);
            if (active > this.item.must_select) {
                let diff = active - this.item.must_select;
                for (const i of this.item.items) {
                    if (diff > 0 && i.selected && !(item.id === i.id || item.name === i.name)) {
                        i.selected = false;
                        diff--;
                    }
                }
            }
        }
    }

    /**
     * Update the order amounts for the local item display
     */
    private orderUpdated() {
        this.timeout('order_changed', () => {
            if (this.field && this.space && this.item) {
                const item_map = this.field.control.value || { };
                if (!item_map[this.space.id]) {
                    item_map[this.space.id] = { room_id: this.space.id, name: this.space.name };
                }
                const item_list = item_map[this.space.id].items || [];
                const found = item_list.find(i => i.id === this.item.id || i.name === this.item.name);
                if (found) {
                    this.item.amount = found.amount || 0;
                } else {
                    this.item.amount = 0;
                }
                if ((this.item as any).items) {
                    for (const item of (this.item as any).items) {
                        const found = item_list.find(i => i.id === item.id || i.name === item.name);
                        if (found) {
                            item.amount = found.amount || 0;
                        } else {
                            item.amount = 0;
                        }
                    }
                }
            }
        }, 100);
    }

    /** Enable a step modifier key */
    public enableModifer(mod: 'shift' | 'control') {
        this.key_states[mod] = true;
        this.updateStep();
    }

    /** Disable a step modifier key */
    public disableModifer(mod: 'shift' | 'control') {
        this.key_states[mod] = false;
        this.updateStep();
    }

    /** Update the increment and decrement step value */
    public updateStep() {
        this.step = this.key_states.shift ? 100 : (this.key_states.control ? 10 : 1);
    }
}
