
import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../../shared/globals/base.component';
import { AppService } from '../../../services/app.service';
import { IBuilding } from '../../../services/data/models/interfaces';

@Component({
    selector: 'sidebar-menu',
    templateUrl: './sidebar-menu.template.html',
    styleUrls: ['./sidebar-menu.styles.scss']
})
export class SidebarMenuComponent extends BaseComponent implements OnInit {
    /** Current building */
    public building: IBuilding;
    /** List of available buildings */
    public building_list: IBuilding[];
    /** Whether to show building list */
    public show_list: boolean;

    public model: any = {};
    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        this.init();
        this.subscription('show', this.service.listen('APP.show_sidebar', (value) => this.model.show = value));
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init);
        }
        this.model.menu = this.service.Settings.get('app.menu');
        this.subscription('notifications', this.service.Events.listen('notifications', (value) => this.model.unread = value));
        this.subscription('building', this.service.Buildings.listen((bld) => {
            this.building = bld;
            this.building_list = this.service.Buildings.list() || [];
        }));
        this.interval('animate', () => this.model.animated = !this.model.animated, 2000);
    }

    public goto(item: any) {
        if (item.id) {
            this.service.set('APP.show_sidebar', false);
            this.service.navigate(item.id, item.query || {});
        } else if (item.link || item.url) {
            window.open(item.link || item.url, 'blank_');
        }
    }

    public changeBuilding(bld: IBuilding) {
        this.service.Buildings.set(bld.id);
        this.show_list = false;
    }
}
