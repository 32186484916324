import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { IBookingFlowEvent } from '../booking-modal.component';
import { BaseComponent } from '../../../shared/globals/base.component';
import { AppService } from '../../../services/app.service';
import { ISpace, ISpaceAvailabilityRequest, IBuilding, IUser } from '../../../services/data/models/interfaces';
import { rulesForSpace } from '../../../shared/utilities/booking.utilities';

import * as dayjs from 'dayjs';
import { Utils } from '../../../shared/utility.class';

@Component({
    selector: 'booking-flow-find-space',
    templateUrl: './find-space.component.html',
    styleUrls: ['./find-space.component.scss']
})
export class BookingFlowFindSpaceComponent extends BaseComponent implements OnInit {
    /** Date time to search for free rooms */
    @Input('dateField') public date_field: ADynamicFormField;
    /** Available slot size to search for free rooms */
    @Input() public duration: number;
    /** Selected host for the booking being created */
    @Input() public user: IUser;
    /** Form field for selected spaces */
    @Input() public spaces: ADynamicFormField;
    /** Emitter for form events */
    @Output() public event = new EventEmitter<IBookingFlowEvent>();

    /** List of available buildings to filter available rooms on */
    public locations: IBuilding[] = [];
    /** Whether the space results are being loaded or not */
    public loading: boolean;
    /** Spaces available before local filtering */
    public available_spaces: ISpace[] = [];
    /** Spaces available with the selected filters */
    public shown_spaces: ISpace[] = [];
    /** Whether to show the location dropdown list */
    public show: boolean;
    /** Subject for changes filters for displaying available rooms */
    private filter$ = new Subject<any>();
    /** Last query string used for searching for rooms */
    private last_query = '';

    constructor(private _service: AppService) {
        super();
    }

    public ngOnInit(): void {
        if (!this._service.ready() || this._service.Spaces.list(true).length <= 0) {
            return this.timeout('init', () => this.ngOnInit());
        }
        this.last_query = '';
        this.subscription(
            'filter',
            this.filter$
                .pipe(
                    debounceTime(500),
                    switchMap(_ => this.search())
                )
                .subscribe((results: ISpace[]) => {
                    this.available_spaces = results;
                    this.shown_spaces = this.filter(results);
                    this.loading = false;
                })
        );
        this.subscription('building', this._service.Buildings.listen((bld) => {
            if (bld) {
                let active_locations = '';
                if (localStorage) {
                    active_locations = localStorage.getItem('CONCIERGE.booking.filters') || '';
                }
                this.locations = this._service.Buildings.list().map(i => ({
                    ...i,
                    selected: active_locations ? active_locations.indexOf(i.id) >= 0 : i.id === bld.id
                }));
                this.filter$.next(Math.floor(Math.random() * 99999));
            }
        }));
    }

    /** Timestamp of the currently selected date */
    public get date(): number {
        let date: number = this.date_field ? this.date_field.getValue() : dayjs().valueOf();
        if (this.duration > 480) {
            date = dayjs(date).startOf('d').valueOf();
        }
        return date;
    }

    /** Display string for the selected date */
    public get date_display(): string {
        return dayjs(this.date).format('ddd, DD MMM YYYY');
    }

    /** Whether the selected date is today */
    public get is_today(): boolean {
        const date = dayjs(this.date);
        return dayjs().isSame(date, 'd');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ((changes.date_field && this.date_field) || (changes.duration && this.duration)) {
            this.filter$.next(`${this.date}|${this.duration}`);
        }
    }

    /**
     * Search for available rooms matching the set filters
     */
    public search(): Promise<ISpace[]> {
        const query: ISpaceAvailabilityRequest = { date: this.date, duration: this.duration, hide_bookings: true };
        const locations = this.locations.reduce((v, i) => { (i as any).selected ? v.push(i.id) : ''; return v }, []);
        if (locations.length <= 0) {
            locations.push(this._service.Buildings.current().id);
        }
        const location_list = locations.join(',');
        this.timeout('save_filters', () => {
            if (localStorage) {
                localStorage.setItem('CONCIERGE.booking.filters', location_list);
            }
        });
        // Limit number of rooms retrieved
        query.zone_ids = location_list;
        const query_str = JSON.stringify(query);
        if (query_str !== this.last_query && this.locations && this.locations.length > 0) {
            this.last_query = query_str;
            this.loading = true;
        }
        return this._service.Spaces.available(query);
    }

    /**
     * Filter rooms based booking rules
     * @param list List of rooms
     */
    public filter(list: ISpace[]): ISpace[] {
        const selected = this.spaces.control.value || [];
        return list
            .map(i => ({ ...i, selected: !!selected.find(j => i.id === j.id) } as any));
    }

    /**
     * Update the list of spaces selected for the current booking
     * @param space Space with which it's state has changed
     * @param state New selected state of the space
     */
    public setState(space: ISpace, state: boolean) {
        const list = this.spaces.control.value || [];
        if (state) {
            if (list.findIndex(i => i.id === space.id) < 0) {
                this.spaces.setValue(list.concat([space]));
            }
        } else {
            this.spaces.setValue(list.filter(i => i.id !== space.id));
        }
    }

    /**
     * Update the selected date
     * @param value Value to change the date by. 1, 0 or -1 days
     */
    public changeDate(value: number) {
        if (this.date_field) {
            const date = dayjs(this.date);
            const new_date = date.add(value, 'd');
            if (!new_date.isBefore(dayjs().subtract(10, 'm'), 'm')) {
                this.date_field.setValue(new_date.valueOf());
                this.filter$.next(`${this.date}|${this.duration}`);
            }
        }
    }
}
