import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.template.html',
    styleUrls: ['./confirm-modal.styles.scss'],
})
export class ConfirmModalComponent extends OverlayContentComponent {
    public model: any = {};

    public event(type: string) {
        if (this.model.event) {
            this.model.event({ type, data: this.model, close: () => this.close() });
        } else {
            this.fn.event(type);
        }
    }
}
