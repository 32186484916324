
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { DynamicField, ICustomField } from '@acaprojects/ngx-widgets';

import { BaseComponent } from '../../../globals/base.component';
import { AppService } from '../../../../services/app.service';
import { IExtra } from '../../../../services/data/models/interfaces';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

@Component({
    selector: 'custom-preference-field',
    templateUrl: './preference-field.component.html',
    styleUrls: ['./preference-field.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('300ms ease-in')),
        ])
    ]
})
export class CustomPreferenceFieldComponent extends BaseComponent implements OnInit, OnDestroy {

    /** List of available extras for the selected building */
    public extras: IExtra[] = [];
    /** Whether to show the list of preferences */
    public show: boolean;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup, private service: AppService) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit(): void {
        this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        this.subscription('building', this.service.Buildings.listen((bld) => {
            if (bld && bld.extras) {
                const extras = [ ...bld.loan_items ];
                const old_extras = this.field.control.value || [];
                for (const extra of old_extras) {
                    const item = extras.find(i => i.id === extra.id);
                    if (item) { item.active = !!extra.active; }
                }
                this.setValue(extras);
            }
        }));
        this.setValue([]);
        this.updateDisplay();
    }

    public updateDisplay() {
        this.extras = this.field.control.value || [];
    }

    public setValue(value: IExtra[]) {
        (this.field.control as any).setValue(value);
    }
}

CUSTOM_FIELD_REGISTER.extras = CustomPreferenceFieldComponent;
CUSTOM_FIELD_REGISTER.preferences = CustomPreferenceFieldComponent;
