import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseComponent } from '../../../globals/base.component';
import { AppService } from '../../../../services/app.service';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

@Component({
    selector: 'custom-terms-field',
    templateUrl: './terms-field.component.html',
    styleUrls: ['./terms-field.component.scss']
})
export class CustomTermsFieldComponent extends BaseComponent implements OnDestroy {

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup, private service: AppService) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public viewTerms() {
        this.service.viewTerms();
    }

    public setValue(value: number) {
        (this.field.control as any).setValue(value);
    }
}

CUSTOM_FIELD_REGISTER.terms = CustomTermsFieldComponent;
