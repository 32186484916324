import { Validators } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { ISpace, IEvent, IUser } from '../../services/data/models/interfaces';

import { validateDate, buildValidateDuration, buildValidateAttendees } from './validation.utilities';
import { formatAttendeesWithHost, formatSpaces, formatRecurrence, formatDate, formatTime, formatTimestamp } from './formatting.utilities';
import { CUSTOM_FIELD_REGISTER } from '../globals/custom-field-register';

import * as dayjs from 'dayjs';
import { AppService } from '../../services/app.service';

export interface SpaceRules {
    auto_approve: boolean;
    hide: boolean;
    max_length?: number;
}

export interface IBookingRules {
    [type_zone: string]: IBookingRule[];
}

export interface IBookingRule {
    conditions: { [name: string]: string | string[] };
    rules: {
        book_ahead?: string;
        book_length?: string;
        auto_approve?: boolean;
    };
}

const MINUTE = 1;
const HOUR = 60;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

const DURATION_MAP: { [duration: string]: number } = {
    week: WEEK,
    weeks: WEEK,
    day: DAY,
    days: DAY,
    hour: HOUR,
    hours: HOUR,
    minute: MINUTE,
    minutes: MINUTE
};

export interface ISpaceRuleOptions {
    user: IUser;
    space: ISpace;
    time?: number;
    duration?: number;
    rules: IBookingRules;
}

export interface ISpaceCheckOptions {
    user: IUser;
    space: ISpace;
    time?: number;
    duration?: number;
    rules: { [name: string]: string | string[] };
}

/**
 * Get booking rules for the given user and space
 * @param user User to determine applicable rules
 * @param space Space to get rules for
 * @param time Booking start time in ms since UTC epoch
 * @param rules List of booking rules for the building
 */
export function rulesForSpace(options: ISpaceRuleOptions): SpaceRules {
    if (!options) {
        throw Error('Options are needed to check for rule matches');
    }
    const space_rules_for_user: SpaceRules = {
        auto_approve: true,
        hide: true
    };
    if (options.space) {
        for (const type in options.rules) {
            if (
                options.rules.hasOwnProperty(type) &&
                options.rules[type] instanceof Array &&
                (options.space.zones || []).find(i => i === type)
            ) {
                for (const rule_block of options.rules[type]) {
                    if (
                        checkRules({
                            user: options.user,
                            space: options.space,
                            time: options.time,
                            duration: options.duration,
                            rules: rule_block.conditions
                        })
                    ) {
                        const ruleset = rule_block.rules;
                        const conditions = rule_block.conditions;
                        space_rules_for_user.hide = false;
                        if (conditions.max_length) {
                            space_rules_for_user.max_length = stringToMinutes(conditions.max_length as string);
                        }
                        // NOTE: use max_length in conditions instead of book_length in rules
                        // if (ruleset.book_length) {
                        //     space_rules_for_user.max_length = stringToMinutes(ruleset.book_length as string);
                        // }
                        if (ruleset.auto_approve !== undefined) {
                            space_rules_for_user.auto_approve = ruleset.auto_approve;
                        }
                        break;
                    }
                }
            }
        }
    }
    return space_rules_for_user;
}

/**
 * Check if user matches the given ruleset
 * @param user User to check for condition matches
 * @param ruleset Map of conditions
 */
function checkRules(options: ISpaceCheckOptions): boolean {
    if (options.rules) {
        const time = dayjs(options.time);
        const count = Object.keys(options.rules).length;
        let matches = 0;
        for (const key in options.rules) {
            let counter = 0;
            const condition: string[] = options.rules[key] instanceof Array ? (options.rules[key] as []) : [options.rules[key] as string];
            switch (key) {
                // case 'group':
                // case 'groups':
                //     if (options.user && options.user.groups) {
                //         condition.forEach(i => (options.user.groups.find(j => j === i) ? counter++ : null));
                //         if (counter >= options.rules[key].length) {
                //             matches++;
                //         }
                //     }
                //     break;
                // case 'location':
                // case 'locations':
                //     if (options.user && options.user.location) {
                //         condition.forEach(i => ((options.user.location.name || '').indexOf(i) >= 0 ? counter++ : null));
                //         if (counter >= options.rules[key].length) {
                //             matches++;
                //         }
                //     }
                //     break;
                case 'is_before':
                    if (options.time) {
                        const duration = stringToMinutes(condition[0]);
                        const check = dayjs().add(duration, 'm');
                        time.isBefore(check, 'm') ? matches++ : '';
                    }
                    break;
                case 'is_after':
                    if (options.time) {
                        const duration = stringToMinutes(condition[0]);
                        const check = dayjs().add(duration, 'm');
                        time.isAfter(check, 'm') ? matches++ : '';
                    }
                    break;
                case 'min_length':
                    if (options.duration) {
                        durationGreaterThanOrEqual(options.duration, condition[0]) ? matches++ : '';
                    }
                    break;
                case 'max_length':
                    if (options.duration) {
                        durationGreaterThanOrEqual(condition[0], options.duration) ? matches++ : '';
                    }
                    break;
            }
        }
        return matches >= count;
    }
    return false;
}

/**
 * Whether the first input is greater than the last. Converts duration strings into minutes
 * @param duration_1 First input can be a number in minutes or a duration string e.g. `1 hour`
 * @param duration_2 Second input can be a number in minutes or a duration string e.g. `30 minutes`
 */
export function durationGreaterThanOrEqual(duration_1: string | number, duration_2) {
    const first: number = typeof duration_1 === 'string' ? stringToMinutes(duration_1) : duration_1;
    const second: number = typeof duration_2 === 'string' ? stringToMinutes(duration_2) : duration_2;
    return first >= second;
}

/**
 * Conver time string into minutes
 * @param str timestring e.g. `'1 day'`, `'15 minutes'`, `'2 weeks'`
 */
export function stringToMinutes(str: string): number {
    const parts = str.split(' ');
    return +parts[0] * DURATION_MAP[parts[1]];
}

/**
 * Generate form fields metadata for the given booking
 * @param booking
 * @param field_data
 */
export function generateBookingFormMetadata(
    booking: IEvent,
    field_data: { [name: string]: any }[],
    service?: AppService
): ADynamicFormField[] {
    field_data = field_data.map(i => ({
        ...i,
        children: i.children ? i.children.map(i => ({ ...i })) : []
    }));
    const validators = {
        date: [validateDate],
        attendees: [buildValidateAttendees(booking.host, service.Settings.get('min_attendees'))],
        terms: [Validators.requiredTrue]
    };
    const formatters = {
        attendees: formatAttendeesWithHost(booking.host),
        date: formatDate,
        start: formatTimestamp,
        space: formatSpaces,
        room: formatSpaces,
        recurrence: formatRecurrence
    };
    const custom_elements = CUSTOM_FIELD_REGISTER;
    let today = dayjs(booking.event_start).startOf('m');
    if (!booking.event_start && (today.minute() % 5) === 0) {
        today = today.minute(today.minute() + 1);
    }
    today = today.minute(Math.ceil(today.minute() / 5) * 5);
    const defaults = {
        date: today.valueOf(),
        start: today.format('HH:mm'),
        duration: 60,
        host: service ? service.Users.current() : null,
        booking_type: { id: 'internal' },
        needs_catering: false,
        needs_space: true,
        attendees: []
    };
    const process_field = field => {
        const f = {
            key: field.key,
            label: field.label,
            icon: field.icon,
            type: field.control_type || field.type,
            value: booking[field.key] || defaults[field.key],
            format: formatters[field.key],
            content: field.content || custom_elements[field.key],
            required: field.required,
            validators: validators[field.key],
            references: field.references || field.refs,
            hide: field.hide,
            settings: { ...field.settings, readonly: field.readonly },
            metadata: { ...field.metadata, service: service.Events },
            attributes: { flex: field.flex },
            children: field.children
                ? field.children.map(process_field)
                : null
        };
        return f;
    };
    if (booking && booking.id) {
        if (dayjs().isAfter(dayjs(booking.event_start), 'm')) {
            const period_grp = field_data.find(i => i.key === 'period_group') || { children: [] };
            const time_grp = field_data.find(i => i.key === 'time_group') || { children: [] };
            const date = period_grp.children.find(i => i.key === 'date') || {};
            date.readonly = true;
            const time = time_grp.children.find(i => i.key === 'start') || {};
            time.readonly = true;
        }
    }
    return field_data
        .filter(i => booking.id ? !i.no_edit : !i.edit_only)
        .map(process_field)
        .map(f => new ADynamicFormField(f));
}
