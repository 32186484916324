
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WidgetsModule, DynamicFormComponentModule } from '@acaprojects/ngx-widgets';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedComponentModule } from '../shared/components/shared.module';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AttendeeModalComponent } from './attendee-modal/attendee-modal.component';
import { CalendarModalComponent } from './calendar-modal/calendar-modal.component';
import { CateringDetailsOverlayComponent } from './catering-details-overlay/catering-details-overlay.component'
import { TimePeriodModalComponent } from './time-period-modal/time-period-modal.component';
import { UserSearchModalComponent } from './user-search-modal/user-search-modal.component';
import { MeetingDetailsOverlayComponent } from './meeting-details-overlay/meeting-details-overlay.component';
import { RoomSelectModalComponent } from './room-select-modal/room-select-modal.component';
import { VisitorDetailsOverlayComponent } from './visitor-details-overlay/visitor-details-overlay.component';
import { UserDetailsOverlayComponent } from './user-details-overlay/user-details-overlay.component';
import { HostModalComponent } from './host-modal/host-modal.component';
import { NewVisitorModalComponent } from './new-visitor-modal/new-visitor-modal.component';
import { AddVisitorModalComponent } from './add-visitor-modal/add-visitor-modal.component';
import { CATERING_ENTRY_COMPONENTS } from '../shell/catering/catering.module';
import { BOOKING_MODAL_COMPONENTS } from './booking-modal';
import { CateringModalComponent } from './catering-modal/catering-modal.component';
import { ViewRoomModalComponent } from './view-room-overlay/view-room-overlay.component';
import { AInteractiveMapModule } from '@acaprojects/ngx-interactive-map';
import { StartEditModalComponent } from './start-edit-modal/start-edit-modal.component';

const COMPONENTS: any[] = [
    ConfirmModalComponent,
    AttendeeModalComponent,
    CalendarModalComponent,
    CateringDetailsOverlayComponent,
    TimePeriodModalComponent,
    UserSearchModalComponent,
    MeetingDetailsOverlayComponent,
    RoomSelectModalComponent,
    VisitorDetailsOverlayComponent,
    UserDetailsOverlayComponent,
    HostModalComponent,
    NewVisitorModalComponent,
    AddVisitorModalComponent,
    CateringModalComponent,
    ViewRoomModalComponent,
    StartEditModalComponent,
    ...CATERING_ENTRY_COMPONENTS,
    ...BOOKING_MODAL_COMPONENTS
];

@NgModule({
    declarations: [
        ...COMPONENTS,
    ],
    imports: [
        CommonModule,
        FormsModule,
        WidgetsModule,
        SharedComponentModule,
        DynamicFormComponentModule,
        RouterModule.forChild([]),
        AInteractiveMapModule
    ],
    exports: [
        ...COMPONENTS,
        AInteractiveMapModule
    ],
    entryComponents: [
        ...COMPONENTS
    ]
})
export class OverlayComponentModule { }
