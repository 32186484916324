import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';

import { BaseMockBackend } from './base.mock';
import { MOCK_SYSTEMS } from './mock-data';

export class MockGuestsBackend extends BaseMockBackend {

    protected load() {
        this.registerHandlers();
    }

    private registerHandlers() {
        this.listAllGuests();
    }

    private listAllGuests() {
        MOCK_REQ_HANDLER.register(
            `/${this.model.api_route}/guests`,
            null,
            (e) => {
                let list = this.model.events;
                if (e.fragment.period_start) {
                    list = list.filter((f) => f.event_start >= +e.fragment.period_start);
                }
                if (e.fragment.period_end) {
                    list = list.filter((f) => f.event_end <= +e.fragment.period_end);
                }
                if (e.fragment.zone_ids) {
                    const sysIds = MOCK_SYSTEMS.filter(f => f.zones.indexOf(e.fragment.zone_ids) > -1).map(m => m.id);
                    list = list.filter((f) => sysIds.indexOf(f.system.id) > -1);
                }
                const guests = list
                    .map((m) => m.attendees.filter((f) => !!f.visit_expected))
                    .reduce((p, c) => p.concat(c))
                    .filter((el, i, r) => r.indexOf(el) === i);
                return guests || [];
            },
            'GET'
        );
    }
}
