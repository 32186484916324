import { Component, OnDestroy, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../globals/base.component';
import { AppService } from '../../../../services/app.service';
import { IUser } from '../../../../services/data/models/interfaces';
import { Utils } from '../../../utility.class';

import * as dayjs from 'dayjs';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

type FieldType = IUser;


const VALID_DOMAINS = ['@pwc-it.com', '@external.pwc-it.com', '@quantumblack.com'];

@Component({
    selector: 'custom-user-list-field',
    templateUrl: './user-list-field.component.html',
    styleUrls: ['./user-list-field.component.scss']
})
export class CustomUserListFieldComponent extends BaseComponent implements OnInit, OnDestroy, OnInit {
    /** List of colours to use for added users */
    public colours: string[] = [];
    /** Subject for changes to the search string */
    private filter$ = new Subject<string>();
    /** List of results from the last search */
    public results: IUser[] = [];
    /** Whether the search results are being loaded */
    public loading: boolean;
    /** Whether to show the results list */
    public show: boolean;
    /** Search string used for filtering results of user searches */
    public search_str: string;
    /** List of filtered users */
    public filtered: IUser[] = [];
    /** Whether there is an external user that needs to be greeted */
    public notify: boolean;
    /** List of selected users */
    public users: IUser[] = [];

    @ViewChild('file_input', { static: true }) private file_input: ElementRef;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup, private service: AppService) {
        super();
        this.colours = ['#00ADEF', 'rgb(216, 27, 96)'];
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit() {
        this.subscription('filter', this.filter$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(filter => {
                if (filter && filter.length >= 3) {
                    this.show = true;
                    this.loading = true;
                    return this.service.Users.query({ q: filter })
                }
            })
        ).subscribe(results => {
            this.loading = false;
            this.results = results;
            this.filterResult(this.search_str);
            this.show = true;
        }));
        this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        this.updateDisplay();
    }

    public search(filter: string) {
        this.filter$.next(filter);
    }

    public filterResult(filter) {
        const group = [...this.results, this.service.Contacts.list()];
        this.filtered = Utils.filter(filter, group, ['name', 'email']);
        this.filtered.forEach((i: any) => {
            if (i.match_name) {
                i.match_name = i.match_name.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                i.match_name = i.match_name.replace(/\`/g, '');
            }
            if (i.match_email) {
                i.match_email = i.match_email.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                i.match_email = i.match_email.replace(/\`/g, '');
            }
        });
    }

    public updateDisplay() {
        this.users = this.field.control.value || [];
        this.notify = !!this.group.controls.notify_users && !!this.users.find(i => i.external);
    }

    public get host(): IUser {
        return this.group.controls.host ? this.group.controls.host.value : this.service.Users.current()
    }

    public add(user: FieldType | FieldType[]) {
        if (!user || (user instanceof Array && user.length <= 0)) { return; }
        let users = this.field.control.value || [];
        user instanceof Array ? (users = users.length > 0 ? [...users, ...user] : [...user]) : users.push(user);
        const new_list = Utils.unique(users, 'email');
        (this.field.control as any).setValue(new_list.length <= 0 ? [] : [...new_list]);
        this.search_str = '';
        this.show = false;
    }

    public remove(user: FieldType) {
        const users = this.field.control.value || [];
        const exists = users.find(u => u.email === user.email);
        if (exists) {
            users.splice(users.indexOf(exists), 1);
            (this.field.control as any).setValue(users.length < 1 ? [] : [...users]);
        }
    }

    public upload(e) {
        if (this.file_input) {
            const file = this.file_input.nativeElement.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsText(file, 'UTF-8');

                reader.onload = (evt) => {
                    this.loadFromCSV((evt.srcElement as any).result);
                    this.file_input.nativeElement.value = '';
                };
                reader.onerror = (evt) => this.service.error('Error reading file.');
            }
        }
    }

    private loadFromCSV(data) {
        const list = Utils.loadCSV(data) || [];
        list.forEach(el => {
            el.name = el.name || `${el.first_name} ${el.last_name}`;
            const display = (el.name || `${Math.floor(Math.random() * 9999_9999)}`)
                .split(' ').join('_').toLowerCase();
            if (!el.email) { el.email = `${display}+${Math.floor(Math.random() * 9999_9999)}@guest.pwc-it.com` }
            el.external = VALID_DOMAINS.findIndex(i => el.email.indexOf(i) >= 0) < 0;
        });
        this.add(list);
    }

    public hideTooltip() {
        this.timeout('hide_tooltip', () => this.show = false);
    }

    public selectAvailability() {
        let now = dayjs();
        now = now.minute(Math.ceil(now.minute() / 5) * 5).startOf('m');
        const date = this.group.controls.date ? dayjs(this.group.controls.date.value) : dayjs();
        this.service.Overlay.openModal('availability', {
            data: {
                host: this.host,
                date: date.valueOf(),
                duration: this.group.controls.date ? this.group.controls.duration.value || 30 : 30,
                users: this.field.control.value
            }
        }, (event) => {
            if (event.type === 'Accept') {
                if (event.data && event.data.date) {
                    const new_date = dayjs(event.data.date);
                    if (this.group.controls.date) {
                        this.group.controls.date.setValue(new_date.valueOf());
                    }
                    if (this.group.controls.start) {
                        this.group.controls.start.setValue(new_date.format('HH:mm'));
                    }
                    if (this.group.controls.duration) {
                        this.group.controls.duration.setValue(event.data.duration);
                    }
                    this.field.setValue(event.data.users);
                }
                this.updateDisplay();
            }
            event.close();
        });
    }

    public selectGuestNotifyees() {
        if (!this.group.controls.notify_users) { return; }
        const notifyees = this.group.controls.notify_users.value || [];
        const host = this.group.controls.host.value || this.service.Users.current();
        if (host && notifyees && notifyees.find(i => host.email === i)) { host.notify = true; }
        const attendees = this.field.control.value;
        attendees.forEach(u => u.notify = (u && !!notifyees.find(i => u.email === i)));
        this.service.Overlay.openModal('select-users', { data: {
            users: [ { notify: true, ...host }, ...attendees.filter(i => !i.external).map(i => ({ ...i })) ]
        } }, (event) => {
            if (event.type !== 'Close') {
                this.group.controls.notify_users.setValue(event.data.users.reduce((a, i) => {
                    if (i.notify === true) { a.push(i.email); }
                    return a;
                }, []));
            }
            event.close();
        });
    }

    public downloadTemplate() {
        const template = `Organisation Name,First Name,Last Name,Email,Phone\nFake Org,John,Smith,john.smith@example.com,01234567898`;
        Utils.download('template.csv', template);
    }

    public addExternal() {
        this.service.Overlay.openModal('user-details', { data: {} }, (event) => {
            if (event.type === 'Success') {
                const form = event.data.form;
                const user = form.users ? form.users[0] || form : form;
                if (!user.email) {
                    const display = (user.name || `${user.first_name} ${user.last_name}` || `${Math.floor(Math.random() * 9999_9999)}`)
                        .split(' ').join('_').toLowerCase();
                    user.email = `${display}+${Math.floor(Math.random() * 9999_9999)}@guest.pwc-it.com`;
                }
                if (!user.first_name && !user.last_name) {
                    user.name = user.email;
                }
                user.external = VALID_DOMAINS.findIndex(i => user.email.indexOf(i) >= 0) < 0;
                this.add(user);
            } else {
                event.close();
            }
        });
    }
}

CUSTOM_FIELD_REGISTER.users = CustomUserListFieldComponent;
CUSTOM_FIELD_REGISTER.attendees = CustomUserListFieldComponent;
