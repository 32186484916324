

import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';

import { BaseMockBackend } from './base.mock';
import { MOCK_BOOKINGS, MOCK_SYSTEMS } from './mock-data';
import * as dayjs from 'dayjs';

export class MockEventsBackend extends BaseMockBackend {
    private static count = 0;
    private zones_map = {};

    protected load() {
        this.model.events = MOCK_BOOKINGS;
        this.registerHandlers();

    }

    private registerHandlers() {
        this.listEvents();
        this.getEventById();
        this.guestCheckin();
    }

    private listEvents() {
        // set up checked in guests
        const now = dayjs();
        this.model.events.forEach(evt => {
            const start = dayjs(evt.event_start * 1000);
                const setAccepted = now.isAfter(start.add(-5, 'm'));
                const visitorIndex = evt.attendees.findIndex(f => !!f.visit_expected);
                if (visitorIndex > -1 && setAccepted) {
                    evt.attendees[visitorIndex].checked_in = true;
                }
        });

        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/events`, null, (e) => {
            let list = this.model.events;
            if (e.fragment.period_start) {
                list = list.filter(f => f.event_start >= +e.fragment.period_start);
            }
            if (e.fragment.period_end) {
                list = list.filter(f => f.event_end <= +e.fragment.period_end);
            }
            if (e.fragment.zone_ids) {
                const systems = MOCK_SYSTEMS.filter(f => f.zones.indexOf(e.fragment.zone_ids) > -1).map(m => m.id);
                list = list.filter(f => systems.indexOf(f.system.id) > -1);
            }
            return list || [];
        }, 'GET');
    }

    private getEventById() {
        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/events/:id`, null, (e) => {
            return this.model.events.find(f => f.id === e.params.id);
        }, 'GET');
    }

    private guestCheckin() {
        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/events/:eventId/guests/:guestId/checkin`, null, (e) => {
            const events = this.model.events;
            const evtIdx = events.findIndex(f => f.id === e.params.eventId);
            if (evtIdx > -1) {
                const attendees = events[evtIdx].attendees;
                const guestIdx = attendees.findIndex(f => f.email === e.params.guestId);
                if (guestIdx > -1) {
                    const state = e.fragment.state === 'true';
                    attendees[guestIdx].checked_in = state;
                    events[evtIdx].attendees = [...attendees];
                    this.model.events = [...events];
                    return attendees;
                }
            }
        }, 'POST');
    }
}
