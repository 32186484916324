/*
 * @Author: alex.sorafumo
 * @Date:   2017-04-03 15:50:46
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-06-19 13:24:24
 */

import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseMockBackend } from './base.mock';

import * as faker from 'faker';
import * as moment from 'moment';
import { MOCK_SPACES, MOCK_SYSTEMS } from './mock-data';

export class MockSpacesBackend extends BaseMockBackend {
    private engine_api = `/api/engine/v2`;

    protected load() {
        if (!this.model) { this.model = {}; }

        this.registerEndpoints();
        this.loadSpaces();
    }

    private registerEndpoints() {
        this.systemsList();
    }

    private systemsList() {
        if (this.model.log) { this.model.log('MOCK', 'Loading request for room list'); }
        this.model.rooms = MOCK_SPACES;

        MOCK_REQ_HANDLER.register (`${this.engine_api}/systems`, null, (e) => {
            return MOCK_SYSTEMS;
        });
    }

    private loadSpaces() {

        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/rooms/:id`, this.model.rooms, (event) => {
            if (event.params.id) {
                if (event.fragment.period_start) {
                    const start = moment(event.fragment.period_start * 1000);
                    const end = event.fragment.period_end ? moment(event.fragment.period_end * 1000) : moment(start).add(60, 'm');
                    const available = this.getAvailableRooms(event.data, start.valueOf(), moment.duration(end.diff(start)).asMinutes());
                    for (const rm of available) {
                        if (rm.id === event.params.id) {
                            rm.available = true;
                            return rm;
                        }
                    }
                } else {
                    for (const rm of event.data) {
                        if (event.params.id === rm.id) {
                            return rm;
                        }
                    }
                }
            }
            return null;
        });

            // Get bookable rooms from room list
        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/rooms`, this.model.rooms, (event) => {
            if (event.fragment.period_start) {
                const start = moment(event.fragment.period_start * 1000);
                const end = event.fragment.period_end ? moment(event.fragment.period_end * 1000) : moment(start).add(60, 'm');
                return this.getAvailableRooms(event.data, start.valueOf(), moment.duration(end.diff(start)).asMinutes());
            } else {
                const results: any[] = [];
                for (const i of event.data) {
                    if (i.bookable) {
                        i.available = Math.floor(Math.random() * 2349873421) % 3 === 0;
                        results.push(i);
                    }
                }
                return results;
            }
        });
        this.state.next(true);
    }

    private getAvailableRooms(list: any[], start_time: number, duration: number = 60) {
        const results = [];
        const start = moment(start_time);
        const end = moment(start).add(duration, 'm');
        for (const rm of list) {
            let clash = false;
            for (const bkn of rm.bookings) {
                const bkn_start = moment(bkn.Start);
                const bkn_end = moment(bkn.End);
                if (start.isBetween(bkn_start, bkn_end, 'm', '[)') || end.isBetween(bkn_start, bkn_end, 'm', '(]')) {
                    clash = true;
                }
            }
            if (!clash) {
                rm.available = true;
                results.push(rm);
            }
        }
        return results;
    }
}
