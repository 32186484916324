import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { startWith } from 'rxjs/operators';

import { BaseComponent } from '../../../globals/base.component';
import { AppService } from '../../../../services/app.service';
import { Utils } from '../../../utility.class';
import { ISpace } from '../../../../services/data/models/interfaces';
import { rulesForSpace } from '../../../utilities/booking.utilities';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

import * as moment from 'moment';

@Component({
    selector: 'custom-duration-field',
    templateUrl: './duration-field.component.html',
    styleUrls: ['./duration-field.component.scss']
})
export class CustomDurationFieldComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Max length of a booking for the selected space */
    public max_length: number;
    /**  */
    public list_height: number;
    /** Allowed time blocks that can be selected by the user */
    public blocks: { id: number, name: string }[];
    /** Index of the active item */
    public index: number;
    /** Whether to show */
    public show: boolean;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup, private service: AppService) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group;
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.group.controls.room) {
                this.subscription(
                    'room',
                    this.group.controls.room.valueChanges.pipe(startWith(this.group.controls.room.value))
                    .subscribe((v) => {
                        this.handleBookingRules(v);
                }));
            }
            if (this.group.controls.date) {
                this.subscription('date', this.group.controls.date.valueChanges.subscribe(() => {
                    if (this.group.controls.room) {
                        this.handleBookingRules(this.group.controls.room.value);
                    }
                    this.updateDisplay();
                }));
            }
        }
        this.subscription('changes', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        this.updateDisplay();
    }

    public updateDisplay() {
        this.timeout('update', () => {
            const value = this.group.controls.date ? this.group.controls.date.value || '' : '';
            const date = (value ? moment(value) : moment());
            const times = this.generateBlocks(date.valueOf(), !!value);
            this.list_height = Math.min(times.values.length, 8);
            this.blocks = times.values;
            this.index = times.index;
        });
    }

    public generateBlocks(datestamp: number, ref: boolean = false) {
        const duration = {
            values: [],
            index: 0
        };
        const date = moment(datestamp);
        const max_duration = this.max_length || Math.min(480, (this.field.metadata ? this.field.metadata.max_duration || 480 : 480));
        const end = moment(datestamp).add(Math.max(30, max_duration) + 15, 'm');
        date.add(10, 'm');
        let dur = 10;
        this.addDuration(duration, dur, ref ? date.format('hh:mm A') : '');
        dur += 5;
        date.add(5, 'm');
        for (; date.isBefore(end, 'm'); date.add(15, 'm')) {
            this.addDuration(duration, dur, ref ? date.format('hh:mm A') : '');
            dur += 15;
        }
        // Add week duration option
        if (max_duration > 450) {
            const date = moment(datestamp).startOf('d').add(4, 'd');
            if ((this.field.control.value || 30) === 1 * 5 * 24 * 60) {
                duration.index = duration.values.length;
            }
            duration.values.push({ id: 1 * 5 * 24 * 60, name: `1 Week(${date.format('DD MMM')})` })
        }
        return duration;
    }

    /**
     * Add duration option for the given length
     * @param duration Mapping of durations
     * @param dur Duration length
     * @param time
     */
    private addDuration(duration: { values: any[], index: number }, dur: number, time?: string) {
        let value = (s?) => Utils.humaniseDuration(dur, s);
        if ((this.field.control.value || 30) === dur) {
            duration.index = duration.values.length;
        }
        duration.values.push({
            id: dur,
            name: time ? `${time} (${value(true)})` : value()
        });
    }

    /** Get the index of the given value i */
    public getIndex(value: number, values: { id: number, name: string }[], name?: string) {
        const index = values.findIndex(a => a.id === value);
        return index < 0 ? 0 : index;
    }

    public setValue(value: number) {
        this.field.setValue(value);
        this.show = false;
        this.index = this.getIndex(value, this.blocks);
    }

    /**
     * Update limitations on duration based off set spaces
     * @param spaces Room or list of rooms
     */
    private handleBookingRules(spaces: ISpace | ISpace[]) {
        if (spaces) {
            const host = (this.group.controls.host ? this.group.controls.host.value : null) || this.service.Users.current();
            const date = (this.group.controls.date ? this.group.controls.date.value : undefined);
            if (spaces instanceof Array) {
                for (const space of spaces) {
                    const rule_list = (this.service.Spaces.building(space) as any).booking_rules;
                    const rules = rulesForSpace({
                        user: host,
                        space,
                        time: date.valueOf(),
                        duration: 5,
                        rules: rule_list
                    });
                    this.max_length = Math.min(this.max_length || 999999, rules.max_length || 999999);
                }
            } else {
                const rule_list = (this.service.Spaces.building(spaces) as any).booking_rules;
                const rules = rulesForSpace({
                    user: host,
                    space: spaces,
                    time: date.valueOf(),
                    duration: 5,
                    rules: rule_list
                });
                this.max_length = rules.max_length || 999999;
            }
            if (this.max_length === 999999) {
                this.max_length = 0;
            }
            this.updateDisplay();
        }
    }
}

CUSTOM_FIELD_REGISTER.duration = CustomDurationFieldComponent;
