
import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';

import { BaseService } from '../base.service';
import { ICateringMenuItem } from '../models/interfaces';



@Injectable({
    providedIn: 'root'
})
export class CateringItemsService extends BaseService<ICateringMenuItem> {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'catering item';
        this.model.route = '/catering/item';
    }

    public query(): never {
        throw Error('No index endpoint for catering items. Use menu service.');
    }

    public show(): never {
        throw Error('No show endpoint for catering items. Use menu service.');
    }

    public format(raw: any) {
        const formatted = {
            ...raw,
            parent_categories: raw.parent_categories || raw.parent,
            minimum_quantity: raw.minimum || raw.minimum_quantity,
            maximum_quantity: raw.maximum || raw.maximum_quantity
        };
        delete formatted.minimum;
        delete formatted.maximum;
        return formatted;
    }
}