import { Component, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../globals/base.component';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';
import { Utils } from '../../../utility.class';

import * as dayjs from 'dayjs';
import { buildValidateStartTime } from '../../../utilities/validation.utilities';

@Component({
    selector: 'custom-time-field',
    templateUrl: './time-field.component.html',
    styleUrls: ['./time-field.component.scss']
})
export class CustomTimeFieldComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Current font size of the field */
    public font_size = 16;
    /** Height of individual item elements in the dom */
    public list_height: number;
    /** Index of the active item */
    public index: number;
    /** List of available time blocks for the user to select */
    public blocks: { id: string, name: string }[] = [];
    /** Whether to show the option list */
    public show_list: boolean;
    /** Previous value of the field */
    public old_value: string;

    /** Virtual scroll viewport in the  */
    @ViewChild(CdkVirtualScrollViewport, { static: true }) private viewport: CdkVirtualScrollViewport;
    @ViewChild('ref', { static: true }) private ref: ElementRef;
    @ViewChild('list', { static: true }) private list: ElementRef;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.group.controls.date) {
                this.subscription('date', this.group.controls.date.valueChanges.subscribe((date) => {
                    const validators: any[] = [buildValidateStartTime(date)];
                    if (this.field.required) {
                        validators.push(Validators.required);
                    }
                    const value = this.group.controls.date ? this.group.controls.date.value || '' : '';
                    this.generateTimes(value);
                    this.field.control.setValidators(validators);
                    this.updateDisplay();
                    this.field.control.updateValueAndValidity();
                }));
            }
        }
        this.old_value = this.field.control.value;
        this.subscription('control', this.field.control.valueChanges.subscribe((value) => {
            if (this.old_value !== value) {
                this.old_value = value;
                this.updateDisplay();
            }
        }));

        const parts = (this.field.control.value || '00:00').split(':');
        const value = this.group.controls.date ? this.group.controls.date.value || '' : '';
        let date = (value ? dayjs(value) : dayjs());
        date = date.minute(Math.ceil(date.minute() / 5) * 5);
        if (this.field.control.value) {
            date = date.hour(+parts[0]).minute(+parts[1]).startOf('m');
        }
        this.setValue(date.format('HH:mm'));
        this.updateDisplay();
        this.checkFontSize();
    }

    public updateDisplay() {
        this.timeout('update', () => {
            const parts = (this.field.control.value || '00:00').split(':');
            const value = this.group.controls.date ? this.group.controls.date.value || '' : '';
            const date = (value ? dayjs(value) : dayjs().add(1, 'd')).hour(+parts[0]).minute(+parts[1]);
            const times = this.generateTimes(date.valueOf());
            this.list_height = Math.min(times.values.length, 8);
            this.blocks = times.values;
            this.index = times.index;
            this.updateScroll();
        }, 50);
    }

    public generateTimes(datestamp: number) {
        // Clean up time
        const time = {
            values: [],
            index: 0
        };
        const date = dayjs(datestamp);
        let now = dayjs();
        if (!date.isSame(now, 'd')) {
            now = date.startOf('d');
        }
        now = now.minute(Math.ceil(now.minute() / 5) * 5);
        if (now.minute() % 15 !== 0) {
            time.values.push({ id: now.format('HH:mm'), name: now.format('hh:mm A') });
            now = now.minute(Math.ceil(now.minute() / 15) * 15);
        }
        if (date.isAfter(now, 'd')) { now.startOf('d'); }
        const end = dayjs(now).endOf('d');
        for (; now.isBefore(end, 'm'); now = now.add(15, 'm')) {
            time.values.push({ id: now.format('HH:mm'), name: now.format('hh:mm A') });
        }
        const ts = date.format('HH:mm');
        time.index = this.getIndex(ts, time.values, date.format('hh:mm A'));
        time.values = Utils.unique(time.values, 'id');
        return time;
    }

    public getIndex(timestamp: string, values: { id: string, name: string }[], name?: string) {
        values.sort((a, b) => a.id.localeCompare(b.id));
        const exists = values.find(a => a.id === timestamp);
        if (!exists) {
            values.unshift({ id: timestamp, name: name || timestamp });
            values.sort((a, b) => a.id.localeCompare(b.id));
        }
        return values.indexOf(values.find(a => a.id === timestamp));
    }

    public updateScroll() {
        if (!this.viewport) {
            return this.timeout('scroll', () => this.updateScroll(), 100);
        }
        this.viewport.scrollToIndex(this.index);
    }

    public setValue(value: string) {
        (this.field.control as any).setValue(value);
        this.show_list = false;
        if (this.group.controls.date) {
            const parts = (this.field.control.value || '00:00').split(':');
            const v = this.group.controls.date ? this.group.controls.date.value || '' : '';
            const date = (v ? dayjs(v) : dayjs()).hour(+parts[0]).minute(+parts[1]).startOf('m');
            // date.minutes(Math.round(date.minutes() / 5) * 5);
            this.group.controls.date.setValue(date.valueOf());
        }
    }

    public show() {
        this.show_list = !this.show_list;
        if (this.show_list) {
            this.updateScroll();
        }
    }

    public cleanTime() {
        const parts = (this.field.control.value || '00:00').split(':');
        const value = this.group.controls.date ? this.group.controls.date.value || '' : '';
        let date = (value ? dayjs(value) : dayjs()).hour(+parts[0]).minute(+parts[1]);
        date = date.minute(Math.round(date.minute() / 5) * 5);
        this.setValue(date.format('HH:mm'));
        this.index = this.getIndex(date.format('HH:mm'), this.blocks, date.format('hh:mm A'));
    }

    public checkFontSize(tries: number = 0) {
        if (tries > 5) { return; }
        if (this.ref && this.ref.nativeElement) {
            this.font_size = this.ref.nativeElement.offsetHeight;
        } else {
            this.timeout('up_size', () => this.checkFontSize(tries), 200 * ++tries);
        }
    }

    public post() {
        if (this.group.controls.date) {
            const parts = (this.field.control.value || '00:00').split(':');
            const v = this.group.controls.date ? this.group.controls.date.value || '' : '';
            const date = (v ? dayjs(v) : dayjs()).hour(+parts[0]).minute(+parts[1]);
            // date.minutes(Math.round(date.minutes() / 5) * 5);
            this.group.controls.date.setValue(date.valueOf());
        }
    }
}

CUSTOM_FIELD_REGISTER.time = CustomTimeFieldComponent;
CUSTOM_FIELD_REGISTER.start = CustomTimeFieldComponent;
