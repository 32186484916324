import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

/** 
 * Show Time in HH:mm
 * time is in unix milliseconds
*/
@Pipe({ name: 'showTime'})
export class ShowTimePipe implements PipeTransform {
    transform(time: number): string {
        return dayjs(time).format('h:mm a');
    }
}
/** 
 * Format Time
 * time is in unix milliseconds
 */
@Pipe({ name: 'formatTime'})
export class FormatTimePipe implements PipeTransform {
    transform(time: number, format: string): string {
        return dayjs(time).format(format);
    }
}

export const TIME_PIPES = [
    FormatTimePipe,
    ShowTimePipe,
];