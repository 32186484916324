import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'calendar-modal',
    templateUrl: './calendar-modal.template.html',
    styleUrls: ['./calendar-modal.styles.scss'],
})
export class CalendarModalComponent extends OverlayContentComponent {
    public model: any = { form: {} };

    public init() {
        if (!this.service || !this.service.Settings.setup) {
            return setTimeout(() => this.init(), 300);
        }
        this.model.form = {};
        this.model.today = moment().valueOf();
        this.model.limit = this.service.Settings.get('app.booking.limit') || 6;
        this.model.form.date = this.model.date || moment().valueOf();
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        setTimeout(() => this.fn.event(name), 300);
    }
}
