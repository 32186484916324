
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { ILevel } from '../../../services/data/models/interfaces';
import { BaseComponent } from '../../../shared/globals/base.component';
import { AppService } from '../../../services/app.service';

import * as dayjs from 'dayjs';

export interface ILegendKey {
    /** Identifier of the key */
    id: string;
    /** Display name of the legend key */
    name: string;
    /** Colour associated with the legend key */
    color: string;
    /** Whether the legend item is enabled */
    enabled: boolean;
}

@Component({
    selector: 'topbar-menu',
    templateUrl: './topbar-menu.template.html',
    styleUrls: ['./topbar-menu.styles.scss']
})
export class TopbarMenuComponent extends BaseComponent implements OnInit {
    public model: any = {};
    /** Timestamp for today */
    public today: number = dayjs().valueOf();
    /** List of the legend keys to display */
    public legend: ILegendKey[] = [
        { id: 'client', name: 'Client', color: '#1F40E6', enabled: true },
        { id: 'external', name: 'External', color: '#8C5AC8', enabled: true },
        { id: 'internal', name: 'Internal', color: '#e79fc7', enabled: true },
        { id: 'interview', name: 'Interview', color: '#348D02', enabled: true },
        { id: 'setup', name: 'Set-up', color: '#d32f2f', enabled: true },
        { id: 'training', name: 'Training', color: '#F0E700', enabled: true },
        { id: 'declined', name: 'Declined', color: '#CCC', enabled: true },
    ];

    constructor(private service: AppService, private router: Router) {
        super();
    }

    /** Number of pending meetings for the active month */
    public get request_count(): number {
        const start = dayjs(this.model.date).startOf('M');
        const end = start.endOf('M');
        let count = 0;
        const pending_list = this.event_counts;
        for (let date = start; date.isBefore(end, 'm'); date = date.add(1, 'd')) {
            count += (pending_list[date.format('YYYY-MM-DD')] || 0);
        }
        return count;
    }

    /** Mapping of dates to number of pending bookings */
    public get event_counts(): { [date: string]: number } {
        return this.service.get('CONCIERGE.pending_bookings') || {}
    }

    public ngOnInit() {
        this.model.date = dayjs().valueOf();
        this.today = dayjs().subtract(6, 'M').valueOf();
        this.model.color = [];
        this.service.set('APP.date', this.model.date);
        this.init();
        
        this.updateTime();
        this.interval('time', () => this.updateTime(), 10 * 1000);
        this.subscription('routing_events', this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.model.show_breakdown = event.url.indexOf('day-view') >= 0;
                this.model.show_level = event.url.indexOf('day-view') >= 0 || event.url.indexOf('week-view') >= 0 || event.url.indexOf('visitor-list') >= 0;
                this.model.show_add_user = event.url.indexOf('visitor-list') >= 0;
                this.model.show_title = event.url.indexOf('reports') >= 0;
                this.model.show_out = false;
                this.legend.forEach(i => i.enabled = true);
                this.service.set('CONCIERGE.legend', {});
            }
        }));
        this.subscription('show', this.service.listen('APP.show_sidebar', (value) => this.model.show_sidebar = value));
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.model.color = [
            { id: 'std', name: 'Standard', color: '#79b700' },
            { id: 'wkshp', name: 'Workshop', color: '#00AEFF' },
            { id: 'spev', name: 'Special Event', color: '#FBD350' },
            { id: 'nocat', name: 'Un-categorised', color: '#B4B4B4' }
        ];
        this.model.logo = this.service.Settings.get('app.logo');
        this.subscription('date', this.service.listen('APP.date', (date) => {
            this.model.date = date;
            this.model.display_date = dayjs(date || '').format('DD/MM/YYYY');
            const today = dayjs();
            // this.model.today = today.isSame(date, 'd');
        }));
        this.model.show_breakdown = this.router.url.indexOf('day-view') >= 0;
        this.model.show_level = this.router.url.indexOf('day-view') >= 0 || this.router.url.indexOf('week-view') >= 0 || this.router.url.indexOf('visitor-list') >= 0;
        this.subscription('level', this.service.listen('APP.level', (id) => this.setLevelByID(id)));
        this.subscription('title', this.service.listen('APP.title', (value) => this.model.title = value ));

        this.service.Buildings.listen((bld) => {
            if (bld && bld.levels && bld.levels.length) {
                this.model.level = {
                    list: [...bld.levels] || [],
                    active: 0
                };
                this.model.level.list.unshift({ id: -1, name: 'All Levels' });
                const level = this.service.Settings.get('app.default_level');
                if (level) {
                    const found = bld.levels.find(i => i.id === level);
                    this.service.set('APP.level', found ? found.id : this.model.level.list[1].id);
                } else {
                    this.service.set('APP.level', this.model.level.list[1].id);
                }
                const room_type_map = this.service.Buildings.organisation().space_types;
                this.model.room_types = Object.keys(room_type_map).map(i => ({ id: i, name: room_type_map[i] }));
                this.model.room_types.unshift({ id: -1, name: 'All Room Types' });
                this.model.active_room_type = 0;
            }
        });

        const building = this.service.Buildings.current();
        if (building) {
            this.model.level = {
                list: [...building.levels] || [],
                active: 1
            };
            this.model.level.list.unshift({ id: -1, name: 'All Levels' });
        }
    }

    /**
     * Toggle the enabled state of the given legend key
     * @param item Legend key to toggle
     */
    public toggleLegend(item: ILegendKey) {
        item.enabled = !item.enabled;
        this.service.set('CONCIERGE.legend', this.legend.reduce((m, i) => { 
            m[i.id] = i.enabled; 
            return m; 
        }, {}));
    }

    public offsetDate(value: number = 1) {
        const date = dayjs(this.model.date || '').add(value, 'd');
        this.model.date = date.valueOf();
        this.model.display_date = dayjs(date || '').format('DD/MM/YYYY');
        const today = dayjs();
        // this.model.today = today.isSame(date, 'd');
        this.service.set('APP.date', this.model.date);
    }

    public updateSetting(name: string) {
        this.service.set(`APP.${name}`, this.model[name]);
    }

    public updateTime() {
        this.model.time = dayjs().format('h:mm A');
    }

    public setLevelByID(id) {
        this.timeout('set_level', () => {
            if (this.model.level && this.model.level.list) {
                const found = this.model.level.list.find(i => i.id === id);
                return !found ? null : this.setLevel(found, false);
            }
        });
    }

    public changeDate() {
        this.service.set('APP.date', this.model.date);
        this.model.show_calendar = false;
    }

    public toggleSidebar() {
        this.model.show_sidebar = !this.model.show_sidebar;
        this.service.set('APP.show_sidebar', this.model.show_sidebar);
    }

    public setLevel(item: ILevel, update: boolean = true) {
        if (this.model.level) {
            this.model.level.active = this.model.level.list.findIndex(i => i.id === item.id);
            this.model.show_levels = false;
            this.service.Buildings.currentLevel = item;
            if (update) {
                this.service.set('APP.level', item.id);
            }
        }
    }

    public setRoomType(type: { id: string, name: string }) {
        this.model.active_room_type = this.model.room_types.findIndex(i => i.id === type.id);
        this.service.set('APP.room_type', type.id);
    }

    public newVisitor() {
        this.service.Guests.new().then((event: any) => {
            if (!event) { return; }
            if (event.type === 'Success') {
                this.service.Events.query({ complete: true });
            }
            event.close();
        });
    }

    public newMeeting() {
        this.service.Overlay.openModal('booking-form', { name: 'overlay', data: {} }, (e) => {
            console.log('Event:', e);
            if (e.type === 'cancel_close') {
                this.clearTimeout('close_booking_form');
            } else {
                this.timeout('close_booking_form', () => e.close());
            }
        });
    }
}
