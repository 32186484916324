import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../../shared/globals/base.component';
import { AppService } from '../../../../services/app.service';
import { IBookingFlowEvent } from '../../../../overlays/booking-modal';
import { ICateringOrder, ICateringMenuItem } from '../../../../services/data/models/interfaces';

@Component({
    selector: 'catering-edit-order',
    templateUrl: './catering-order.component.html',
    styleUrls: ['./catering-order.component.scss']
})
export class CateringEditOrderComponent extends BaseComponent {
    /** Whether catering is part of the booking flow */
    @Input() flow: boolean = true;
    /** Form field for storing catering data for the booking */
    @Input() catering: ADynamicFormField;
    /** Emitter for form events */
    @Output() public event = new EventEmitter<IBookingFlowEvent>();
    /** Step value for adding catering items */
    public step: number = 1;
    /** State of modifier keys */
    public key_states: { shift: boolean, control: boolean } = { shift: false, control: false };

    constructor(private _service: AppService) {
        super();
    }

    /** Number of total items in all room orders */
    public get count(): number {
        const list = this.order_list;
        return list.reduce((v, i) => {
            const count = (i.items || []).reduce((c, j) => c + j.amount, 0);
            return v + count;
        }, 0);
    }

    /** Total cost for all room orders */
    public get total() {
        const list = this.order_list;
        return list.reduce((v, i) => v + i.total, 0);
    }

    /** List of catering orders from the form field */
    public get order_list(): ICateringOrder[] {
        const orders = this.catering ? this.catering.control.value : {};
        const rooms = Object.keys(orders);
        const list = rooms.map(i => {
            if (orders[i]) {
                const room: any = (this._service.Spaces.item(orders[i].room_id) || { name: '', level: {} });
                orders[i].name = room.name || orders[i].name;
                orders[i].symbol = this.symbol(room.level.parent_id);
                orders[i].total = (orders[i].items || []).reduce((c, j) => c + j.amount * (j.unit_cost || j.price), 0);
            } else {
                orders[i] = {};
            }
            return orders[i];
        });
        return list || [];
    }

    public symbol(parent_id: string): string {
        const building = this._service.Buildings.get(parent_id) || {} as any;
        return building.currency || 'USD';
    }

    public change(item: ICateringMenuItem, amount: number) {
        item.amount += amount;
        // Check to make sure adding set the correct amount if adding to 0
        if (amount > 0 && item.minimum && item.amount < item.minimum) {
            item.amount = item.minimum;
        }
        // Check item quantity is within bounds
        if (item.amount < (item.minimum || 0)) {
            item.amount = 0;
        } else if (item.amount > (item.maximum || 100)) {
            item.amount = (item.maximum || 100)
        }
        this.catering.setValue(this.catering.getValue());
    }

    /** Enable a step modifier key */
    public enableModifer(mod: 'shift' | 'control') {
        this.key_states[mod] = true;
        this.updateStep();
    }

    /** Disable a step modifier key */
    public disableModifer(mod: 'shift' | 'control') {
        this.key_states[mod] = false;
        this.updateStep();
    }

    /** Update the increment and decrement step value */
    public updateStep() {
        this.step = this.key_states.shift ? 100 : (this.key_states.control ? 10 : 1);
    }
}
