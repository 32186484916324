
import { Component, OnInit } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

import { AppService } from '../../../../services/app.service';
import { ICateringMenuCategory, ICateringMenuItem } from '../../../../services/data/models/interfaces';

@Component({
    selector: 'catering-item-modal',
    templateUrl: './item-modal.component.html',
    styleUrls: ['./item-modal.component.scss']
})
export class CateringItemModalComponent extends OverlayContentComponent<AppService> {

    public model: { [name: string]: any } = {};
    /** Form data for catering category */
    public form: { [name: string]: any } = {};
    /** Whether a request is being processed */
    public loading: boolean;
    /** Whether the last request was successful */
    public success: boolean;

    public type_options: string[] = ['none', 'kitchen', 'pantry'];

    /** Parent category of the item */
    public get category(): ICateringMenuCategory {
        return this.model.category;
    }

    /** Parent group of the item */
    public get group(): ICateringMenuCategory {
        return this.model.group;
    }

    /** Catering Item to edit */
    public get item(): ICateringMenuItem {
        return this.model.item;
    }

    /** Whether the form fields are valid */
    public get valid(): boolean {
        return !!this.form.name && this.type >= 0;
    }

    public init(): void {
        if (this.item) {
            const group: any = this.group || {};
            const category: any = this.category || {};
            this.form = { catering_type: group.catering_type || category.catering_type, ...this.item };
        }
    }

    /** Index of the active catering type */
    public get type(): number {
        return this.type_options.indexOf(this.form.catering_type || this.type_options[0]);
    }

    /**
     * Save new category or changes to the existing category
     */
    public save() {
        const bld = this.service.Buildings.current();
        if (this.service && bld) {
            this.form.zones = [bld.id];
            this.form.categories = [this.category.id];
            this.loading = true;
            const request = this.form.id
                ? this.service.CateringItems.updateItem(this.form.id, this.form)
                : this.service.CateringItems.add(this.form)
            request.then(() => {
                this.success = true;
                this.timeout('close', () => this.event('finish'), 3000);
            }, () => {
                this.loading = false;
                this.service.error(`Failed to ${this.category ? 'update' : 'create'} category`);
            })
        }
    }
}

