
import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';

import * as isBetween from 'dayjs/plugin/isBetween';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjs from 'dayjs';
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

import { IEvent, ISpace, ISpaceAvailabilityRequest, ILevel, IBuilding } from './models/interfaces';
import { BaseService } from './base.service';
import { Utils } from '../../shared/utility.class';


@Injectable({
    providedIn: 'root'
})
export class SpacesService extends BaseService<ISpace> {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'space';
        this.model.route = '/systems';
        this.set('timelines', {});
        this.set('search', []);
        this.set('results', []);
        this.set('capacities', []);
        // Initialise state
        this.set('state', 'loading');
    }

    get endpoint() {
        return `${this.engine_endpoint}${this.model.route}`;
    }

    protected load() {
        if (
            !this.parent ||
            !this.parent.Settings.setup ||
            (this.parent.Settings.get('mock') && !(window as any).backend.is_loaded)
            || !this.parent.Buildings.current()
        ) {
            return setTimeout(() => this.init(), 500);
        }
        this.loadSystems();
    }

    protected loadSystems(zoneIds: string[] = []) {
        const query = { limit: 1000, zone_ids: zoneIds.length > 0 ? zoneIds.join(',') : '', update: true};
        this.query(query).then(systems => {
            this.set('list', systems);
            this.set('state', 'ready');
        });
    }

    public level(space: ISpace | string): ILevel {
        const system = typeof(space) === 'string' ? this.item(space) : space;
        const bld =  this.building(system);
        if (bld) {
            const lvl = this.parent.Buildings.levels(bld.id).find(l => system.zones.indexOf(l.id) > -1);
            if (lvl) {
                return lvl;
            }
        }
        return null;
    }

    public building(space: ISpace): IBuilding {
        return this.parent.Buildings.list().find(b => space.zones.indexOf(b.id) > -1);
    }

    public eventsForDate(space: ISpace, date: Date): IEvent[] {
        const theDate = dayjs(date);
        return space.events ? space.events.filter(event => theDate.isSame(dayjs(event.event_start), 'day')) : [];
    }

    /**
     * Get list of rooms
     * @param all All rooms for client
     * @param zone_ids List of zones that the rooms must be in
     */
    public list(all: boolean = false, zone_ids?: string | string[]) {
        const zones = zone_ids ? (zone_ids instanceof Array ? zone_ids || [] : [zone_ids]) : [];
        const list = this.get('list') || [];
        let rm_list = [];
        if (all) {
            rm_list = list;
        } else {
            const bld = this.parent && this.parent.Buildings.current() ? this.parent.Buildings.current() : null;
            const bld_list = list.filter((i) => i.level && i.level.parent_id === bld.id);
            rm_list = (bld ? bld_list : list);
        }
        return zones.length > 0 ? rm_list.filter((i) => {
            let cnt = 0;
            for (const id of zones) {
                if (i.zones.indexOf(id) >= 0) { cnt++; }
            }
            return cnt >= zones.length;
        }) : rm_list;
    }

    /**
     * Get list of available rooms
     * @param date Start time of availabilty block
     * @param duration Length of availabiliy block. Defaults to 60
     * @param id ID of room to check availability
     * @param bookable Only check bookable rooms. Defaults to true
     */
    public available(options: ISpaceAvailabilityRequest) {
        if (!this.parent || !this.parent.Buildings.current()) {
            return new Promise((rs, rj) =>
                setTimeout(() => this.available(options).then((v) => rs(v), (e) => rj(e)), 500)
            );
        }
        this.set('state', 'loading');
        const id = options.id;
        const start = dayjs(options.date);
        const end = dayjs(start).add(options.duration, 'm');
        const bld = this.parent.Buildings.current();
        const key = `availiable|${start.unix()}|${options.duration}${id ? '|' + id : ''}|${bld.id}|${options.ignore}|${options.zone_ids}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const query: any = {
                    bookable: true,
                    period_start: start.unix(),
                    period_end: end.unix(),
                    zone_ids: options.zone_ids /* || bld.id */,
                    room_ids: options.room_ids,
                    hide_bookings: options.hide_bookings,
                    ignore_rooms: options.ignore_rooms
                };
                if (options.ignore) { query.ignore = options.ignore; }
                if (options.rooms) { query.room_ids = options.rooms; }
                const response = (resp) => {
                    let raw_list = resp;
                    if (!(resp instanceof Array)) { raw_list = [resp]; }
                    if (options.clear) { this.clearTimeline(start.valueOf(), end.valueOf()); }
                    const list = raw_list instanceof Array
                        ? raw_list
                        /*.filter((rm: ISpace) => {
                            if (rm.linked_rooms) {
                                const unavailable = rm.linked_rooms.filter(rm_id => {
                                    const item = raw_list.find(i => i.id === rm_id);
                                    return !item || !item.available;
                                });
                                if (unavailable && unavailable.length > 0) { return false; }
                            }
                            return rm.available;
                        })*/
                        : resp;
                    raw_list.forEach(rm => this.updateTimeline(rm.id, rm.bookings));
                    resolve(list);
                    this.timeout(key, () => this.promises[key] = null, 1000);
                    this.set('state', 'idle');
                };
                const error = (err) => {
                    this.subjects.state.next('idle');
                    this.promises[key] = null;
                    reject(err);
                };
                if (id) {
                    this.show(id, query).then(response, error);
                } else {
                    this.query(query).then(response, error);
                }
            });
        }
        return this.promises[key];
    }

    /**
     * Check if room is available
     * @param id ID of room
     * @param date Start time of availabilty block
     * @param duration Length of availabiliy block. Defaults to 60
     */
    public isAvailable(id: string, date: number, duration: number = 60, ignore?: string, setup?: number, breakdown?: number): Promise<void> {
        const start = dayjs(date);
        const key = `available|${id}|${start.unix()}|${duration}|${ignore}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                // Get availability of room
                this.available({
                    date, duration, id,
                    bookable: false,
                    ignore,
                    setup,
                    breakdown
                }).then((result) => {
                    const item = (result instanceof Array ? result[0] : result) || {};
                    // Check availability status of room
                    if (item.available && item.linked_rooms && item.linked_rooms.length > 0) {
                        this.available({
                            date, duration,
                            bookable: false,
                            rooms: item.linked_rooms.join(',')
                        }).then((list) => {
                            list.length === item.linked_rooms.length ? resolve() : reject('Linked rooms are not free');
                        });
                    } else {
                        item.id === id && item.available ? resolve() : reject();
                    }
                    // Prevent new requests for 60 seconds
                    setTimeout(() => this.promises[key] = null, 1 * 1000);
                }, (err) => { this.promises[key] = null; reject(); });
            });
        }
        return this.promises[key];
    }

    /**
     * Update room parameters
     * @param room Room data
     */
    // public processRoom(room: ISpace) {
    //     if (room) {
    //         if (room.raw_bookings) {
    //             room.bookings = this.processRoomBookings(room.raw_bookings);
    //             room.next = this.nextBooking(room.bookings);
    //             room.current = this.currentBooking(room.bookings);
    //             room.in_use = this.checkState(room.bookings);
    //         }
    //     }
    // }

    protected processItem(raw_item) {
        return raw_item as ISpace;
        // if (!raw_item.settings) { raw_item.settings = {}; }
        // const settings = raw_item.settings;
        // const lvl = this.parent.Buildings.getLevel(raw_item.zones) || {};
        // if (!lvl.settings) { lvl.settings = {}; }
        // const out: ISpace = {
        //     id: raw_item.id,
        //     system: raw_item.system,
        //     name: settings.room_name || raw_item.name,
        //     display_name: settings.long_name || raw_item.long_name || raw_item.name,
        //     email: (raw_item.email || '').toLowerCase(),
        //     level: lvl,
        //     map_id: raw_item.settings ? raw_item.settings.map_id : raw_item.map_id,
        //     map_url: raw_item.settings ? raw_item.settings.map_url : raw_item.map_url,
        //     available: raw_item.available || raw_item.settings.available,
        //     bookable: raw_item.bookable,
        //     raw_bookings: raw_item.settings.bookings || raw_item.bookings,
        //     order: raw_item.settings.room_order || raw_item.room_order || 999,
        //     bookings: [],
        //     extras: this.parent.Buildings.getExtras(raw_item.settings.extra_features || raw_item.extra_features),
        //     support_url: settings.support_url || raw_item.support_url,
        //     capacity: raw_item.capacity,
        //     zones: raw_item.zones,
        //     book_type: settings.book_type || lvl.book_type || lvl.settings.book_type || '',
        //     controllable: settings.controllable,
        //     rate: settings.cost_hour || raw_item.cost_hour,
        //     linked_rooms: raw_item.linked_rooms || raw_item.settings.linked_rooms,
        //     in_use: false,
        //     next: null,
        //     type: this.parent.Buildings.spaceType(raw_item.zones),
        //     setup: (raw_item.settings.setup || 0) / 60,
        //     breakdown: (raw_item.settings.breakdown || 0) / 60,
        //     status: raw_item.status
        // };
        // if (out.controllable !== false) { out.controllable = lvl.settings.controllable; }
        // if (settings.bookable_by_request !== false && (settings.bookable_by_request || (lvl.settings.bookable_by_request))) {
        //     out.book_type = 'Request';
        // }
        // if (raw_item.capacity) {
        //     const cap_list = this.get('capacities') || [];
        //     let found = false;
        //     for (const item of cap_list) {
        //         if (item.value === raw_item.capacity) {
        //             found = true;
        //             break;
        //         }
        //     }
        //     if (!found) {
        //         cap_list.push({
        //             id: raw_item.capacity,
        //             value: raw_item.capacity,
        //             name: `Min. ${raw_item.capacity} ${raw_item.capacity === 1 ? 'Person' : 'People'}`
        //         });
        //         cap_list.sort((a, b) => a.value - b.value);
        //         this.set('capacities', cap_list);
        //     }
        // }
        // // Add getter for timelines
        // Object.defineProperty(out, 'timeline', {
        //     get: () => {
        //         const timeline = this.get('timelines') || {};
        //         return timeline[out.id] || {};
        //     }
        // });
        // if (!out.level.id) {
        //     // Add getter for level
        //     Object.defineProperty(out, 'level', {
        //         get: () => {
        //             const level = this.parent.Buildings.getLevel(raw_item.zones) || { settings: {} };
        //             if (!level.settings) { level.settings = {}; }
        //             if (settings.bookable_by_request !== false && (settings.bookable_by_request || (level.settings.bookable_by_request))) {
        //                 out.book_type = 'Request';
        //             }
        //             return level;
        //         }
        //     });
        // }
        // Object.defineProperty(out, 'today', {
        //     get: () => {
        //         const timelines = this.get('timelines') || {};
        //         const timeline = timelines[out.id] || {};
        //         const now = dayjs();
        //         return timeline[now.format('DD MMM YYYY')] || [];
        //     }
        // });
        // out.nextFree = (d) => this.nextFreeTimeForRoom(out, d);
        // this.processRoom(out);
        // return out;
    }

    /**
     * Convert booking data into local format
     * @param bookings Array of room bookings
     */
    private processRoomBookings(bookings: any[]): IEvent[] {
        if (!bookings) { return []; }
        return this.parent.Events.processList(bookings) || [];
    }

    /**
     * Get the next upcoming booking
     * @param bookings Array of bookings
     */
    public nextBooking(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return null;
        }
        const now = dayjs();
        let booking: any = null;
        let booking_start: any = null;
        for (const event of bookings) {
            const start = dayjs(event.event_start * 1000);
            if (now.isSame(start, 'day') && start.isAfter(now) && (!booking_start || booking_start.isAfter(start))) {
                booking = event;
                booking_start = start;
            }
        }
        return booking;
    }

    /**
     * Get booking in progress
     * @param bookings Array of bookings
     */
    public currentBooking(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return null;
        }
        const now = dayjs();
        for (const event of bookings) {
            const start = dayjs(event.event_start * 1000);
            const end = dayjs(event.event_end * 1000);
            if (now.isBetween(start, end, 'm', '[)')) {
                return event;
            }
        }
        return null;
    }

    /**
     * Get end time of the current booking
     * @param bookings Array of bookings
     */
    public checkState(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return false;
        }
        const now = dayjs();
        let time = dayjs();
        for (const event of bookings) {
            const start = dayjs(event.event_start * 1000);
            const end = dayjs(event.event_end * 1000);
            if (start.isSameOrBefore(time, 'm') && end.isAfter(time, 'm')) {
                time = end;
            }
        }
        return !time.isSame(now, 'm') ? time.format('h:mm A') : false;
    }

    /**
     * Get bookings on the given date
     * @param bookings Array of bookings
     * @param date Date to check
     */
    public bookingsForDate(bookings: any[], date: any = dayjs()) {
        const list: any[] = [];
        for (const event of bookings) {
            const start = dayjs(event.event_start);
            if (start.isSame(date, 'd')) {
                list.push(event);
            }
        }
        return list;
    }

    /**
     * Update booking with the given date
     * @param booking Updated booking data
     */
    public replaceBooking(booking: IEvent) {
        const timeline = this.get('timelines') || {};
        if (booking.extension_data.room && booking.extension_data.room.id) {
            if (timeline[booking.extension_data.room.id] && Object.keys(timeline[booking.extension_data.room.id]).length > 0) {
                let found = false;
                for (const date in timeline[booking.extension_data.room.id]) {
                    if (timeline[booking.extension_data.room.id].hasOwnProperty(date)) {
                        const list = timeline[booking.extension_data.room.id][date];
                        for (const bkn of list) {
                            if (bkn.id === booking.id) {
                                found = true;
                                list[list.indexOf(bkn)] = this.parent.Events.processItem(booking);
                                break;
                            }
                        }
                        list.sort((a, b) => a.date - b.date);
                        if (found) { break; }
                    }
                }
                if (!found) {
                    const bkn = this.parent.Events.processItem(booking);
                    const date = dayjs(bkn.event_start).format('DD MMM YYYY');
                    if (!timeline[booking.extension_data.room.id][date]) { timeline[booking.extension_data.room.id][date] = []; }
                    timeline[booking.extension_data.room.id][date].push(bkn);
                }
            } else if (timeline[booking.extension_data.room.id]) {
                this.updateTimeline(booking.extension_data.room.id, [this.parent.Events.processItem(booking)]);
            }
        }
    }

    public clear() {
        this.clearTimeline();
    }

    /**
     * Remove booking from room's timeline
     * @param room_id Room ID
     * @param bkn_id Booking ID
     * @param date Booking date
     */
    public removeFromTimeline(room_id: string, bkn_id: string, date: string = dayjs().format('DD MMM YYYY')) {
        const timeline = this.get('timelines') || {};
        if (timeline[room_id] && timeline[room_id][date]) {
            for (const bkn of timeline[room_id][date]) {
                if (bkn.id === bkn_id) {
                    timeline[room_id][date].splice(timeline[room_id][date].indexOf(bkn), 1);
                    break;
                }
            }
        }
        this.subjects.timelines.next(timeline);
    }

    public addToTimeline(event: IEvent) {
        if (!event) { return; }
        this.timeout('add_timeline', () => {
            if (!event.system) { return; }
            const timeline = this.get('timelines') || {};
            const {id} = event.system;
            if (!timeline[id]) { timeline[id] = {}; }
            const date = dayjs(event.event_start * 1000).format('DD MMM YYYY');
            if (!timeline[id][date]) { timeline[id][date] = []; }
            timeline[id][date].push(event);
            timeline[id][date] = Utils.unique(timeline[id][date], 'id');
            this.subjects.timelines.next(timeline);
        });
    }

    public nextFreeTimeForRoom(room: ISpace, duration: number = 30) {
        const now = dayjs();
        const events = room.events.filter(event => now.isSame(dayjs(event.event_start * 1000), 'day')) || [];
        const free_blocks = this.parent.Events.getFreeSlots(events);
        for (const block of free_blocks) {
            const start = dayjs(block.start > 0 ? block.start : '');
            const end = dayjs(block.end > 0 ? block.end : '');
            const time = start.isSameOrBefore(now, 'm') ? now : start;
            const d = Math.round(end.diff(time, 'm'));
            if (d >= duration) { return time.valueOf(); }
        }
        return now.valueOf();
    }

    /**
     * Create a list of bookings for each day
     * @param bookings Array of bookings
     */
    public updateTimeline(id: string, bookings: IEvent[], clear: boolean = false) {
        const timeline = this.get('timelines') || {};
        if (!timeline[id] || clear) { timeline[id] = {}; }
        const now = dayjs();
        // Add bookings to the timeline
        for (const bkn of bookings) {
            const start = dayjs(bkn.event_start * 1000);
            const end = dayjs(bkn.event_end * 1000);
            const duration = end.diff(start, 'minute');
            const date_list = [start.format('DD MMM YYYY')];
            if (duration > 24 * 60) {
                for (let i = 24 * 60; i < duration; i += 24 * 60) {
                    start.add(1, 'd');
                    date_list.push(start.format('DD MMM YYYY'));
                }
            }
            for (const date of date_list) {
                if (!timeline[id][date]) { timeline[id][date] = []; }
                // Remove matches
                for (const event of timeline[id][date]) {
                    if (event.id === bkn.id) {
                        timeline[id][date].splice(timeline[id][date].indexOf(event), 1);
                        break;
                    }
                }
                if ((bkn.title || '').indexOf('Cancelled:') !== 0) {
                    timeline[id][date].push(bkn);
                }
            }
        }
        // Sort timeline
        for (const i in timeline[id]) {
            if (timeline[id][i]) {
                timeline[id][i].sort((a, b) => a.date - b.date);
                this.parent.Events.processOverlaps(timeline[id][i]);
            }
        }
        this.subjects.timelines.next(timeline);
    }

    public clearTimeline(start_date?: number, end_date?: number, list?: string[]) {
        if (!start_date && !end_date) {
            this.set('timelines', {});
        } else {
            const start = dayjs(start_date).startOf('d');
            const end = dayjs(end_date).endOf('d');
            const timelines = this.get('timelines') || {};
            const sysIds = Object.keys(timelines);
            if (sysIds.length > 0) {
                for (let time = dayjs(start); time.isSameOrBefore(end, 'd'); time = time.add(1, 'd')) {
                    const startFormatted = start.format('DD MMM YYYY');
                    for (const id in sysIds) {
                        if (!!timelines[id] && (!list || list.indexOf(id) >= 0)) {
                            if (!!timelines[id][startFormatted]) {
                                delete timelines[id][startFormatted];
                            }
                        }
                    }
                }

            }
            this.set('timelines', timelines);
        }
    }

    public timelineForDay(id: string, date: number = dayjs().valueOf()) {
        const day = dayjs(date);
        const timelines = this.get('timelines') || {};
        const room_timeline = timelines[id] || {};
        const date_timeline = room_timeline[day.format('DD MMM YYYY')] || [];
        return date_timeline;
    }

    public timeline(id: string) {
        const timelines = this.get('timelines') || {};
        const room_timeline = timelines[id] || {};
        return room_timeline;
    }

    public getRoom(id: string): ISpace {
        return this.item(id);
    }
}
