/*
 * @Author: Alex Sorafumo
 * @Date: 2017-06-05 12:39:28
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-06-19 13:56:07
 */

import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';

import { ISpace } from './models/interfaces';
import { BaseService } from './base.service';
import { Utils } from '../../shared/utility.class';
import { IEvent, ICateringOrder } from './models/interfaces';

import * as moment from 'moment';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);


@Injectable({
    providedIn: 'root',
})
export class EventsService extends BaseService<IEvent> {
    constructor(protected http: CommsService) {
        super();
        this.model.name = 'event';
        this.model.route = '/events';
        this.set('timeline', {});
        this.set('new_booking', { state: 'idle' });
        this.set('update_booking', { state: 'idle' });
    }
    /**
     * Initialise service
     */
    public init() {
        if (
            !this.parent ||
            !this.parent.Spaces.list(true) ||
            this.parent.Spaces.list(true).length <= 0 ||
            !this.parent.ready()
        ) {
            return setTimeout(() => this.init(), 500);
        }
        this.load();
    }

    protected load(tries: number = 0) {
        // this.query({ complete: true });
        // this.interval('load', () => this.query({ complete: true }), 30 * 1000);
        if (this.parent.Settings.get('app.notifications.enabled')) {
            this.getNotificationsBookings();
            this.interval('load', () => this.getNotificationsBookings(), 30 * 1000);
        }
    }

    public eventsForZoneOnDate(zoneId: string, date: Date) {
        const period_start = dayjs(date).startOf('day').unix();
        const period_end = dayjs(date).endOf('day').unix();
        return this.query({zone_ids: zoneId, period_start, period_end}).then(events => this.processItem(event));
    }

    /**
     * Create overlay with the give booking details displayed
     * @param item Booking to view
     */
    public view(item: IEvent, space: ISpace, edit: boolean = false) {
        return new Promise((rs, rj) => {
            if (this.parent) {
                this.parent.Overlay.openModal(
                    'meeting-details',
                    {
                        name: 'overlay',
                        data: {
                            klass: 'overlay',
                            booking: item,
                            to_edit: edit,
                            room: space,
                        },
                    },
                    (e) => {
                        rs(e);
                        e.close();
                    }
                );
            }
        });
    }

    /**
     * Create modal with a booking form
     * @param room Room to book
     */
    public bookRoom(room: ISpace) {
        const today = moment();
        today
            .minutes(Math.ceil(today.minutes() / 5) * 5)
            .seconds(0)
            .milliseconds(0);
        const bookings = room.events.filter((b) => moment(b.event_start).isSame(today, 'd'));
        let block = this.getNextFreeBlock(bookings);
        if (block && moment.duration(moment(block.end).diff(today)).asMinutes() < 30) {
            block = this.getNextFreeBlock(bookings, 30, block.end);
        }
        this.parent.Overlay.openModal(
            'booking-form',
            {
                name: 'overlay',
                data: {
                    room,
                    date: !block || block.start < 0 ? today.valueOf() : Math.max(today.valueOf(), block.start),
                },
            },
            (e) => e.close()
        );
    }

    /**
     * Create confirm modal for booking the specified space with the given parameters
     * @param item Booking form hash map
     */
    public book(item: { [name: string]: any }) {
        return new Promise((resolve, reject) => {
            if (this.get('new_booking').state !== 'idle') {
                return reject('Another booking is in progress');
            }
            const date = moment(item.date);
            this.parent.confirm(this.confirmSettings('add', item), (event) => {
                if (event.type === 'Accept') {
                    this.subjects.new_booking.next({ state: 'processing' });
                    this.add(item).then(
                        (d) => {
                            this.set('new_booking', { state: 'success', value: d });
                            resolve(d);
                            if (localStorage) {
                                const booking = localStorage.getItem('CONCIERGE.booking_form');
                                localStorage.removeItem('CONCIERGE.booking_form');
                                localStorage.setItem('CONCIERGE.last_booking', booking || JSON.stringify(item));
                            }
                            this.timeout(`${moment().unix()}`, () => this.set('new_booking', { state: 'idle' }));
                        },
                        (e) => {
                            this.set('new_booking', { state: 'error', value: e });
                            this.timeout('new_error', () => this.set('new_booking', { state: 'idle' }));
                            reject(e);
                        }
                    );
                } else {
                    reject('Cancelled by user');
                }
                event.close();
            });
        });
    }

    /**
     * Make local changes based on changes made to item
     * @param data PUT data
     * @param item New booking
     */
    protected postUpdate(data: any, item: IEvent) {
        if (localStorage) {
            const booking = localStorage.getItem('CONCIERGE.booking_form');
            localStorage.removeItem('CONCIERGE.booking_form');
            localStorage.setItem('CONCIERGE.last_booking', booking || JSON.stringify(item));
        }
        // item.type = 'accepted';
        const old_room: any = this.parent.Spaces.item(data.from_room || data.room_id) || {};
        this.parent.Spaces.removeFromTimeline(old_room.id, data.id);
        this.parent.Spaces.addToTimeline(item);
        // this.parent.Visitors.removeFromList([data]);
        // this.parent.Visitors.addFromBookings([item]);
    }

    public accept(id: string, fields?: { [name: string]: any }) {
        return this.task(id, 'accept', fields);
    }

    public decline(id: string, fields?: { [name: string]: any }) {
        return this.task(id, 'concierge_decline', fields);
    }

    /**
     * Retrieve booking with the given ID
     * @param id ID of booking
     */
    public getWithID(id: string) {
        return this.item(id);
    }

    /**
     * Check for conflicts between two lists of bookings
     * @param list First list to check for conflicts
     * @param comparison Second list to check for conflicts. Defaults to the users bookings
     */
    public checkConflicts(list: IEvent[], comparison?: IEvent[]) {
        if (!comparison) {
            comparison = this.get('timeline') || [];
        }
        let conflict_count = 0;
        for (const bkn of list) {
            const bkn_start = moment(bkn.event_start);
            const bkn_end = moment(bkn.event_end);
            for (const cmp of comparison) {
                const cmp_start = moment(cmp.event_start);
                const cmp_end = moment(cmp.event_end);
                if (
                    bkn_start.isBetween(cmp_start, cmp_end, 'm', '[)') ||
                    bkn_end.isBetween(cmp_start, cmp_end, 'm', '(]')
                ) {
                    conflict_count++;
                    break;
                }
            }
        }
        return conflict_count;
    }

    /**
     * Get the free times between a group of booking
     * @param list List of bookings
     */
    public getFreeSlots(list: IEvent[]) {
        list.sort((a, b) => a.event_start - b.event_start);
        const block: { start: number; end: number }[] = [];
        let bkn_start: moment.Moment = null;
        let bkn_end: moment.Moment = null;
        for (const bkn of list) {
            bkn_start = moment(bkn.event_start).seconds(0).milliseconds(0);
            if (!bkn_end) {
                block.push({ start: moment(bkn_start).startOf('d').valueOf(), end: bkn_start.valueOf() });
            } else if (bkn_end.isBefore(bkn_start, 'm')) {
                block.push({ start: bkn_end.valueOf(), end: bkn_start.valueOf() });
            }
            bkn_end = moment(bkn.event_end);
        }
        if (bkn_end) {
            block.push({ start: bkn_end.valueOf(), end: bkn_end.endOf('d').valueOf() });
        } else if (block.length <= 0) {
            const today = moment();
            block.push({ start: today.startOf('d').valueOf(), end: today.endOf('d').valueOf() });
        }
        return block;
    }

    public getNextFreeBlock(list: IEvent[], gap: number = 30, time: number = moment().valueOf()) {
        const blocks = this.getFreeSlots(list);
        const now = moment(time);
        let block = null;
        for (const blk of blocks) {
            const start = blk.start < 0 ? moment().hours(0).minutes(0) : moment(blk.start);
            const end = blk.end < 0 ? moment().hours(23).minutes(59) : moment(blk.end);
            const dur = moment.duration(end.diff(start));
            const length = Math.floor(dur.asMinutes());
            if (!block && (now.isBetween(start, end, 'm', '[)') || now.isBefore(start, 'm')) && length >= gap) {
                block = blk;
                break;
            }
        }
        return block;
    }

    /**
     * Retrieve new bookings
     * @param days Number of days from now
     */
    public getNewBookings(days: number = 0) {
        const start = dayjs().startOf('day').subtract(days, 'day');
        const end = dayjs().endOf('day').subtract(days, 'day');

        return this.query({
            period_start: start.unix(),
            period_end: end.unix(),
            zone_ids: this.parent.Buildings.current().id,
        }).then((rooms: any) => {
            return rooms
                .map((room) => room.bookings)
                .flat()
                .sort((a, b) => moment(a.created_date).unix() - moment(b.created_date).unix());
        });
    }

    /**
     * Retrieve bookings that are coming
     * @param days Number of days from now
     */
    public getComingBookings(days: number) {
        const now = dayjs();
        const future = dayjs().add(days, 'day');
        return this.query({
            period_start: now.unix(),
            period_end: future.unix(),
            zone_ids: this.parent.Buildings.current().id,
        }).then((events: any) => {
            return events
                .sort((a, b) => a.event_start - b.event_start || a.event_end - b.event_end);
        });
    }

    /**
     * Retrieve bookings for notifications
     * Bookings with parking, bookings with catering and bookings with catering not confirmed
     */
    public getNotificationsBookings() {
        return Promise.all([this.getNewBookings(2), this.getComingBookings(2)]).then(
            ([newBookings, comingBookings]) => {
                const resp = {
                    parking: newBookings.filter((item) => item.parking),
                    catering: newBookings.filter((item) => item.catering),
                    not_confirmed: comingBookings.filter((item) => item.catering && !item.food_ordered),
                };
                const count = resp.parking.length + resp.catering.length + resp.not_confirmed.length;
                this.set('notifications', count);
                this.set('notification_list', resp);
                return resp;
            }
        );
    }

    public processItem(raw_item: { [name: string]: any }) {
        if (raw_item.system_id) {
            const space = this.parent.Spaces.item(raw_item.system_id);
            if (!!space) {
                raw_item.space = space;
            }
        }
        return raw_item as IEvent;
        // const now = moment();
        // const user = this.parent.Users.current();
        // const start = moment(raw_item.start_epoch * 1000 || raw_item.start || raw_item.Start || raw_item.date);
        // const end = moment(raw_item.end_epoch * 1000 || raw_item.end || raw_item.End);
        // const has_end = raw_item.end_epoch * 1000 || raw_item.end || raw_item.End;
        // const duration = Math.abs(start.diff(end, 'm'));
        // const states = this.parent.get('BOOKING.states') || {};
        // if (raw_item.all_day) {
        //     end.hours(23).minute(59);
        // }
        // const item: IBooking = {
        //     id: raw_item.id,
        //     icaluid: raw_item.icaluid,
        //     title: raw_item.title || raw_item.Subject || raw_item.subject,
        //     date: raw_item.all_day ? start.hours(6).minutes(30).valueOf() : start.valueOf(),
        //     start_hours: start.hours() + start.minutes() / 60,
        //     duration: has_end ? duration || raw_item.duration : raw_item.duration,
        //     description: raw_item.description,
        //     attendees: this.parent.Users.processList(raw_item.attendees || []),
        //     type: raw_item.catering ? 'catering' : raw_item.visitors ? 'external' : 'internal',
        //     is_organiser: false,
        //     organiser: { name: raw_item.owner } as IUser,
        //     room: { id: raw_item.room_id, name: raw_item.room_name } as IRoom,
        //     visitors: raw_item.visitors,
        //     parking: raw_item.parking,
        //     state: states[raw_item.id] || raw_item.show_as || raw_item.state,
        //     expired: now.isAfter(end),
        //     food_ordered: raw_item.food_ordered,
        //     notes: raw_item.notes || [],
        //     locations: raw_item.locations,
        //     notify_users: raw_item.notify_users,
        //     all_day: !!raw_item.all_day || duration > 20 * 60,
        //     location_name: raw_item.location_name,
        //     check_ins: raw_item.check_ins,
        //     booked_by: raw_item.booked_by,
        //     booking_type:
        //         !raw_item.booking_type || (raw_item.booking_type === 'internal' && raw_item.visitors)
        //             ? 'external'
        //             : raw_item.booking_type,
        //     setup: (raw_item.setup || 0) / 60,
        //     breakdown: (raw_item.breakdown || 0) / 60,
        //     equipment_notes: raw_item.equipment_notes || raw_item.equipment,
        //     catering: raw_item.catering
        //         ? raw_item.catering.items
        //             ? raw_item.catering
        //             : raw_item.catering[raw_item.room_id]
        //         : null,
        //     catering_notes: raw_item.catering_notes,
        //     equipment_code: raw_item.equipment_code,
        //     space_notes: raw_item.space_notes || {},
        //     catering_code: raw_item.catering_code || (raw_item.catering ? raw_item.catering.code : ''),
        //     expected_attendees: raw_item.expected_attendees || {},
        //     display: {
        //         date: start.format('DD MMM YYYY'),
        //         time: `${start.format('h:mma')} - ${end.format('h:mma')}`,
        //         start: start.format('h:mma'),
        //         start_date: start.format('DD MMM YYYY [at] h:mma'),
        //         end: has_end ? end.format('h:mma') : moment(start).add(raw_item.duration, 'm').format('h:mma'),
        //         end_date: has_end
        //             ? end.format('DD MMM YYYY [at] h:mma')
        //             : moment(start).add(raw_item.duration, 'm').format('DD MMM YYYY [at] h:mma'),
        //         duration: Utils.humaniseDuration(duration),
        //     },
        // };

        // /** Temporary solution to miscoloration with booking types not persisting. */
        // let old_booking = JSON.parse(localStorage.getItem('CONCIERGE.last_booking'));
        // if (old_booking && old_booking.icaluid === item.icaluid && item.booking_type !== old_booking.booking_type) {
        //     item.booking_type = old_booking.booking_type;
        // }
        // this.processRoom(item, raw_item);
        // if (item.expired) {
        //     item.type = 'expired';
        // }
        // if (raw_item.catering) {
        //     item.catering = raw_item.catering;
        // }
        // // Setup organiser for booking
        // if (raw_item.organiser || raw_item.organizer) {
        //     const email =
        //         typeof raw_item.organiser === 'string'
        //             ? raw_item.organiser
        //             : typeof raw_item.organizer === 'string'
        //             ? raw_item.organizer
        //             : (raw_item.organiser || raw_item.organizer || {}).email;
        //     // Get the organiser's details
        //     item.organiser =
        //         this.parent.Users.item(email) ||
        //         this.parent.Users.processItem(raw_item.organiser || raw_item.organizer);
        //     // Check if organiser is the current user
        //     item.is_organiser = item.organiser && user && (item.organiser.id === user.id || email === user.email);
        // }
        // // Get room associated with the booking
        // const id =
        //     raw_item.locations && raw_item.locations.length > 0 ? raw_item.locations[0].uniqueId : raw_item.room_id;
        // const rm = this.parent.Rooms.item(id);
        // if (rm) {
        //     item.display.room = rm.name || raw_item.room_name;
        //     item.display.level = rm.level ? rm.level.name : '';
        // }
        // return item;
    }

    public processRoom(item: IEvent, raw_item: any) {
        // Object.defineProperty(item, 'room', {
        //     get: () => {
        //         let rm = null;
        //         // Get room associated with the booking
        //         const id = raw_item.room_id;
        //         if (item.room_list && item.room_list.length > 0) {
        //             rm = item.room_list[0];
        //             item.display.room =
        //                 item.room_list.reduce((a, b) => (a += a ? ', ' + b.name : b.name), '') ||
        //                 raw_item.location_name;
        //             item.display.level = rm.level ? rm.level.name : '';
        //             return rm;
        //         }
        //         if (raw_item.status) {
        //             raw_item.status = Object.keys(raw_item.status).reduce((a, v) => {
        //                 a[v.toLowerCase()] = raw_item.status[v];
        //                 return a;
        //             }, {});
        //         }
        //         if (id instanceof Array) {
        //             const rm_list: IRoom[] = [];
        //             for (const rm_id of id) {
        //                 const room = this.parent.Rooms.item(rm_id) || { id: rm_id, name: rm_id, email: rm_id };
        //                 if (room) {
        //                     rm_list.push(room as any);
        //                 }
        //             }
        //             item.room_list = rm_list;
        //             if (item.room_list.length > 0) {
        //                 rm = item.room_list.length[0] || {};
        //                 const same_level =
        //                     item.room_list.filter((i) => i.level && rm.level && i.level.id === rm.level.id).length >=
        //                     item.room_list.length;
        //                 item.display.level = same_level ? rm.level.name : 'Multiple levels';
        //                 item.display.room =
        //                     item.room_list.reduce((a, b) => (a += a ? ', ' + b.name : b.name), '') ||
        //                     raw_item.location_name;
        //             }
        //         } else {
        //             rm = this.parent.Rooms.item(id);
        //             if (rm) {
        //                 item.display.room = rm.name || raw_item.room_name || raw_item.location_name;
        //                 item.display.level = rm.level ? rm.level.name : '';
        //                 item.room_list = [rm];
        //             }
        //         }
        //         return rm;
        //     },
        //     set: (v) => {
        //         if (v) {
        //             item.room_list = v instanceof Array ? v : [v];
        //         }
        //     },
        // });
    }

    protected format(item) {
        const form = item || {};
        if (!form.room) {
            form.room = {};
        }
        if (form.duration > 480) {
            form.date = moment(form.date).startOf('d').valueOf();
        }
        const date = moment(form.date).startOf('m');
        let room_id: string[] = [];
        let auto_approve = [item.state !== 'tentative'];
        if (form.room instanceof Array) {
            auto_approve = [];
            room_id = form.room.map((rm) => {
                const state = (item.approval_status ? item.approval_status[rm.email] : null) || '';
                auto_approve.push(rm.book_type !== 'Request' && state.indexOf('tentative') < 0);
                return rm.email || rm.id;
            });
        } else if (form.room && (form.room.email || form.room.id)) {
            room_id.push(form.room.email || form.room.id);
            const state = (item.approval_status ? item.approval_status[form.room.email] : null) || '';
            auto_approve = [
                form.room.book_type !== 'Request' && form.room.type !== 'Request' && state.indexOf('tentative') < 0,
            ];
        }
        const request: any = {
            start: date.unix(),
            end: date.add(form.duration, 'm').unix(),
            room_id,
            title: form.title,
            description: form.description,
            attendees: form.attendees,
            other: form.other,
            from_room: form.from_room || '',
            loan_items: form.loan_items || '',
            private: form.private || false,
            parking: form.parking || false,
            catering: form.catering,
            expected_attendees: form.expected_attendees || {},
            equipment_code: form.equipment_code,
            booking_type: (form.booking_type ? form.booking_type.id : null) || 'internal',
            notify_users: form.notify_users,
            auto_approve,
            setup: (form.setup || 0) * 60,
            breakdown: (form.breakdown || 0) * 60,
            delegate: form.delegate || false,
            location_name: form.location_name,
            locations: form.locations,
            notes: form.notes || [],
            all_day: form.all_day,
            concierge: true,
        };
        if (request.catering && item.catering_code && !request.catering.items) {
            Object.keys(request.catering).forEach((k) => {
                if (request.catering[k]) {
                    request.catering[k].code = item.catering_code;
                }
            });
        }
        if (request.catering && !request.catering.items) {
            for (const rm in request.catering) {
                if (request.catering[rm]) {
                    const order: ICateringOrder = request.catering[rm];
                    for (const group of order.items) {
                        if ((group as any).package && (group as any).must_select) {
                            // Remove unselected items from group
                            (group as any).items = (group as any).items.reduce((v, i) => {
                                if (i.selected || i.must_select > 0) {
                                    if (i.must_select) {
                                        // Remove unselected items from sub-group
                                        (i as any).items = (i as any).items.reduce((c, j) => {
                                            if (j.selected) {
                                                c.push(j);
                                            }
                                            return c;
                                        }, []);
                                    }
                                    v.push(i);
                                }
                                return v;
                            }, []);
                        }
                    }
                }
            }
        }
        const user = this.parent.Users.current();
        const now = moment().startOf('s');
        if (item.description) {
            request.notes.push({
                type: 'description',
                message: item.description,
                author: user.name,
                date: now.valueOf(),
            });
        }
        if (item.equipment) {
            request.space_notes = item.equipment;
        }
        if (item.catering_notes) {
            request.notes.push({
                type: 'catering',
                message: item.catering_notes,
                author: user.name,
                date: now.valueOf(),
            });
        }
        if (item.private_notes) {
            request.notes.push({
                type: 'private',
                message: item.private_notes,
                author: user.name,
                date: now.valueOf(),
            });
        }
        if (item.icaluid) {
            request.icaluid = item.icaluid;
            request.auto_approve = item.state !== 'tentative';
        }
        if (item.organiser) {
            request.organiser = item.organiser;
        }
        if (item.notify_user) {
            request.notify_users = [item.notify_user.email];
        }
        if (!item.id && item.host) {
            request.host = item.host;
            const exists = form.attendees.find((i) => i.email.toLowerCase() === item.host.email.toLowerCase());
            if (!request.notify_users) {
                request.notify_users = [item.host.email];
            }
            if (!exists) {
                request.attendees.unshift(item.host);
            }
        }
        if ((item.recurr && item.recurr.period) || (item.recurrence && item.recurrence.period)) {
            request.recurr = {
                period: ['none', 'daily', 'weekly', 'biweekly', 'monthly'][item.recurr || item.recurrence.period],
                end: Math.floor((item.recurr_end || item.recurrence.end) / 1000),
            };
        }
        return request;
    }

    public clear(fields?: { from?: number; to?: number }) {
        // if (!fields || !fields.from) {
        //     super.clear(fields);
        // } else {
        //     const start = moment(fields.from).startOf('d');
        //     const end = moment(fields.to || fields.from);
        //     // Clear period in list
        //     const list: IBooking[] = this.get('list') || [];
        //     this.set<IBooking[]>(
        //         'list',
        //         list.filter((i) => !moment(i.date).isBetween(start, end))
        //     );
        //     // Clear period in timeline
        //     const timeline = this.get('timeline') || {};
        //     for (; start.isBefore(end, 'm'); start.add(1, 'd')) {
        //         const date = start.format('YYYY/MM/DD');
        //         if (timeline[date]) {
        //             delete timeline[date];
        //         }
        //     }
        //     this.set('timeline', timeline);
        // }
    }

    protected updateList(list: IEvent[]) {
        super.updateList(list);
        // const timeline = this.get('timeline') || {};
        // // Add bookings to timeline
        // for (const item of list) {
        //     const date = moment(item.date);
        //     const day = timeline[date.format('YYYY/MM/DD')] || [];
        //     for (const event of day) {
        //         if ((item.id && event.id === item.id) || item.date === event.date) {
        //             day.splice(day.indexOf(event), 1);
        //             break;
        //         }
        //     }
        //     day.push(item);
        //     timeline[date.format('YYYY/MM/DD')] = day;
        // }
        // // Sort events in the timeline
        // for (const key in timeline) {
        //     if (timeline.hasOwnProperty(key)) {
        //         timeline[key].sort((a, b) => a.date - b.date);
        //         this.processOverlaps(timeline[key]);
        //     }
        // }
        // this.set('timeline', timeline);
    }

    public processOverlaps(list: IEvent[]) {
        // for (const bkn of list) {
        //     const bkn_start = moment(bkn.date);
        //     const bkn_end = moment(bkn_start).add(bkn.duration, 'm');
        //     let count = 0;
        //     let index = 0;
        //     const collisions: IBooking[] = [];
        //     for (const cmp of list) {
        //         if (bkn.id !== cmp.id) {
        //             const cmp_start = moment(cmp.date);
        //             const cmp_end = moment(cmp_start).add(cmp.duration, 'm');
        //             if (
        //                 (bkn_start.isBetween(cmp_start, cmp_end, 'm', '[)') ||
        //                     bkn_end.isBetween(cmp_start, cmp_end, 'm', '(]')) &&
        //                 bkn.id !== cmp.id
        //             ) {
        //                 count++;
        //                 collisions.push(bkn);
        //                 if (list.indexOf(bkn) > list.indexOf(cmp)) {
        //                     index++;
        //                 }
        //             }
        //         }
        //     }
        //     collisions.forEach((i) => (i.overlap_count = count > i.overlap_count ? count : i.overlap_count));
        //     const indexes = collisions.reduce((a, v) => {
        //         if (v.overlap_index || v.overlap_index === 0) {
        //             a.push(v.overlap_index);
        //         }
        //         return a;
        //     }, []);
        //     if (indexes.length > 0) {
        //         for (let n = 0; n < count; n++) {
        //             if (!indexes.find((i) => i === n)) {
        //                 index = n;
        //             }
        //         }
        //     }
        //     bkn.overlap_count = count;
        //     bkn.overlap_index = Math.max(0, index);
        // }
    }

    public removeFromList(list: IEvent[]) {
        super.removeFromList(list);
        // Also remove booking from timeline
        // const timeline = this.get('timeline') || {};
        // for (const item of list) {
        //     const date = moment(item.date);
        //     const day = timeline[date.format('YYYY/MM/DD')] || [];
        //     for (const event of day) {
        //         if (item.id && event.id === item.id) {
        //             day.splice(day.indexOf(event), 1);
        //             break;
        //         }
        //     }
        // }
        // // this.parent.Visitors.removeFromList(list);
        // this.set('timeline', timeline);
    }

    protected confirmSettings(key: string, fields: { [name: string]: any } = {}) {
        const settings = super.confirmSettings(key, fields);
        const start = dayjs(fields.event_start * 1000 || '');
        const end = dayjs(fields.event_end * 1000 || '');
        const duration = end.diff(start, 'm');
        const user = this.parent.Users.current();
        const space = fields.space ? (fields.space instanceof Array ? fields.space[0] : fields.space) : null || { name: fields.space_name };
        const bkn_type: string = space ? space.book_type || null : null;
        const lvl = space ? space.level || null : null;
        switch (key) {
            case 'add':
                settings.title = `${bkn_type || (!lvl ? 'Schedule' : 'Book')} ${lvl ? 'space' : 'meeting'}`;
                if (lvl) {
                    settings.message = `${bkn_type || 'Book'} space "${space.name}"<br>`;
                } else {
                    settings.message = 'Meeting ';
                }
                settings.message += `
                    on the ${start.format('Do of MMMM, YYYY')} at ${start.format('h:mma')}<br>
                    for ${Utils.humaniseDuration(fields.duration)}
                `;
                break;
            case 'delete':
                if (fields.decline) {
                    settings.title = `Decline meeting`;
                    settings.message = `Are you sure you wish to decline attendance to meeting '${
                        fields.name || fields.title || ''
                    }'?`;
                    settings.icon = 'event_busy';
                }
                break;
            case 'update':
                settings.message = `Change booking ${space.name ? 'for "' + space.name + '"' : ''}<br>
                                    to the ${start.format('Do of MMMM, YYYY')} at ${start.format('h:mma')}<br>
                                    for ${Utils.humaniseDuration(duration)}`;
                break;
        }
        return settings;
    }
}
