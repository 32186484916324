
import { Component, OnInit, Input } from '@angular/core';

import { BaseComponent } from '../../../shared/globals/base.component';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

@Component({
    selector: 'booking-flow-catering-details',
    templateUrl: './catering-details.component.html',
    styleUrls: ['./catering-details.component.scss']
})
export class BookingCateringDetailsModalComponent extends BaseComponent {
    @Input('notes') public _notes: ADynamicFormField;
    @Input('costCode') public _cost_code: ADynamicFormField;

    /** Equipment notes for the meeting */
    public get notes(): string {
        const empty = { value: '' };
        return ((this._notes ? this._notes.control : null) || empty).value || '';
    }

    public set notes(value: string) {
        if (this._notes && this._notes.control) {
            this._notes.setValue(value);
        }
    }

    /** Equipment cost code for the meeting */
    public get cost_code(): string {
        const empty = { value: '' };
        return ((this._cost_code ? this._cost_code.control : null) || empty).value || '';
    }

    public set cost_code(value: string) {
        if (this._cost_code && this._cost_code.control) {
            this._cost_code.setValue(value);
        }
    }
}

