import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IEvent } from './models/interfaces';
import { CommsService } from '@acaprojects/ngx-composer';

@Injectable({
  providedIn: 'root'
})
export class SpaceAvailabilityService  extends BaseService<IEvent> {

  constructor(protected http: CommsService) {
      super();
      this.model.name = 'calendar';
      this.model.route = '/calendar/availability';
      this.set('list', []);
  }
  /**
   * Initialise service
   */
  public init() {
      if (
          !this.parent ||
          !this.parent.Spaces.list(true) ||
          this.parent.Spaces.list(true).length <= 0 ||
          !this.parent.ready()
      ) {
          return setTimeout(() => this.init(), 500);
      }
      this.load();
  }

}
