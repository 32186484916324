
import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { BaseComponent } from '../../globals/base.component';
import { IUser } from '../../../services/data/models/interfaces';

@Component({
    selector: 'user-form',
    templateUrl: './user-form.template.html',
    styleUrls: ['./user-form.styles.scss']
})
export class UserFormComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public form: any = {};
    @Input() public select = false;
    @Input() public clear = false;
    @Input() public search = true;
    @Input() public visitors = false;
    @Input() public edit = false;
    @Output() public formChange = new EventEmitter();

    public model: any = {};

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        if (!this.model.form) { this.model.form = {}; }
        // this.subscription('bld', this.service.Buildings.listen((bld) => {
        //     if (bld) { this.processOrganisations(); }
        // }));
        this.init();
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.external = this.service.Settings.get('app.users.external');
        this.model.can_add_org = this.service.Settings.get('app.users.custom_orgs');
        this.model.min_char = Math.max(this.service.Settings.get('app.users.people_min_char') || 0, 3);
        this.model.selected_user = false;
        // this.processOrganisations();
    }

    public ngOnChanges(changes: any) {
        if (changes.form && this.form) {
            this.timeout('form-change', () => {
                if (!this.model.form) { this.model.form = {}; }
                for (const k in this.form) {
                    if (this.form.hasOwnProperty(k)) {
                        this.model.form[k] = this.form[k];
                    }
                }
                if ((this.model.form.organisation || this.model.form.organisation_id) && this.model.org && this.model.org.list) {
                    for (const org of this.model.org.list) {
                        if ((this.model.form.organisation && org.name === this.model.form.organisation.name) ||
                            (this.model.form.organisation_id && org.id === this.model.form.organisation_id)) {
                            this.model.org.active = this.model.org.list.indexOf(org);
                            break;
                        }
                    }
                }
            }, 50);
        }
        if (changes.clear) {
            this.model.search = '';
            this.model.form = {};
            if (this.model.org) {
                this.model.org.active = -1;
            }
            this.find(false);
        }
    }

    public find(post: boolean = true) {
        if (this.model.searching) {
            return this.timeout('finding', () => this.find(post));
        }
        this.timeout('find', () => {
            this.model.results = [];
            this.model.form.users = [];
            this.model.selected_user = null;
            if (post) { this.post(); }
            if (this.model.search && this.model.search.length >= this.model.min_char) {
                this.model.searching = true;
                if (!this.visitors) {
                    this.service.Users.query({ q: this.model.search }).then((list) => {
                        this.model.results = list;
                        this.model.searching = false;
                    }, (e) => this.model.searching = false);
                } else {
                    const host = this.model.form.host ? this.model.form.host.email : undefined;
                    this.service.Contacts.query({ q: this.model.search, host, limit: 50 }).then((list) => {
                        this.model.results = list;
                        this.model.searching = false;
                    }, (e) => this.model.searching = false);
                }
            }
        }, 500);
    }

    public post() {
        this.validate();
        if (this.model.valid) {
            const form = JSON.parse(JSON.stringify(this.model.form || {}));
            this.formChange.emit(form);
        } else {
            this.formChange.emit({});
        }
    }

    public selectUser(item: any) {
        const user_list = this.model.results;
        this.model.form.users = [];
        for (const i of user_list) {
            if (i === item) {
                i.selected = !i.selected;
            } else if (!this.select) {
                i.selected = false;
            }
            if (i.selected) { this.model.form.users.push(i); }
        }
        if (item) {
            this.model.selected_user = true;
        }
        this.post();
    }

    public validate() {
        this.model.error = {};
        this.model.valid = true;
        if (!this.model.form.users || this.model.form.users.length <= 0 || !this.model.search) {
            const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            if (this.model.form.email && !regex.test(this.model.form.email)) {
                this.model.error.email = { msg: 'A valid email is required' };
                this.model.valid = false;
            }
            if (this.model.org && this.model.org.list) {
                if (this.model.org.active < 0) {
                    this.model.error.org = { msg: 'Organisation is required' };
                    this.model.valid = false;
                } else if (this.model.org.active === this.model.org.list.length && !this.model.form.organisation_name) {
                    this.model.error.org_name = { msg: 'A valid organisation name is required' };
                    this.model.valid = false;
                }
            }
        }
    }

    public checkEmail() {
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (this.model.form && this.model.form.email && regex.test(this.model.form.email) && !this.model.checking_email) {
            this.timeout('check-email', () => {
                this.model.checking_email = true;
                if (!this.visitors) {
                    this.service.Users.query({ q: this.model.form.email }).then((list) => {
                        const user = list ? list[0] || null : null;
                        if (user && user.email === this.model.form.email) { this.setUser(user); }
                        this.model.checking_email = false;
                    }, (e) => this.model.checking_email = false);
                } else {
                    this.service.Contacts.query({ q: this.model.form.email, limit: 50 }).then((list) => {
                        const user = list ? list[0] || null : null;
                        if (user && user.email === this.model.form.email) { this.setUser(user); }
                        this.model.checking_email = false;
                    }, (e) => this.model.checking_email = false);
                }
            }, 500);
        }
    }

    private setUser(user: IUser) {
        if (user) {
            this.service.info(`Found user with matching email address`);
            for (const k in user) {
                if (user.hasOwnProperty(k) && user[k]) {
                    this.model.form[k] = user[k];
                }
            }
            if (this.model.organisation_name || this.model.form.organisation_id) {
                for (const org of this.model.org.list) {
                    if (org.name === this.model.form.organisation_name || org.id === this.model.form.organisation_id) {
                        this.model.org.active = this.model.org.list.indexOf(org);
                        break;
                    }
                }
            }
            this.post();
        }
    }

    // public updateOrg() {
    //     this.validate();
    //     if (this.model.org && this.model.org.list) {
    //         const org = this.model.org.list[this.model.org.active];
    //         if (org) {
    //             this.model.form.organisation_id = org.id;
    //             this.model.form.organisation_name = org.name;
    //         } else {
    //             this.model.form.organisation_id = null;
    //             this.model.form.organisation_name = '';
    //         }
    //         this.post();
    //     }
    // }

    // private processOrganisations(tries: number = 0) {
    //     if (tries > 5) { return; }
    //     const bld = this.service.Buildings.current();
    //     const org = this.service.Buildings.organisation();
    //     if (!bld || !org) {
    //         return setTimeout(() => this.processOrganisations(tries), 300 * ++tries);
    //     }
    //     this.model.org = {
    //         list: JSON.parse(JSON.stringify(bld || org.orgs || [])),
    //         name: []
    //     };
    //     if (this.model.org.list.length <= 0) {
    //         return setTimeout(() => this.processOrganisations(tries), 300 * ++tries);
    //     }
    //     this.model.org.name = [];
    //     for (const o of this.model.org.list) {
    //         this.model.org.name.push(o.name || o);
    //     }
    //     if ((!this.model.org.active && this.model.org.active !== 0) || this.model.org.active >= this.model.org.list.length) {
    //         this.model.org.active = -1;
    //     }
    // }
}
