import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../globals/base.component';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

declare global {
    interface Window {
        tinymce: any;
    }
}

@Component({
    selector: 'custom-editor-field',
    templateUrl: './editor-field.component.html',
    styleUrls: ['./editor-field.component.scss']
})
export class CustomEditorFieldComponent extends BaseComponent implements OnDestroy {
    constructor(protected _field: ADynamicFormField, protected _group: FormGroup) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }
}

CUSTOM_FIELD_REGISTER.description = CustomEditorFieldComponent;
CUSTOM_FIELD_REGISTER.notes = CustomEditorFieldComponent;
CUSTOM_FIELD_REGISTER.private_notes = CustomEditorFieldComponent;
