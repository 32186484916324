
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../globals/base.component';

import * as dayjs from 'dayjs';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

export interface IRecurrence {
    period: number;
    end?: number;
}

type FieldType = IRecurrence;

@Component({
    selector: 'custom-recurrence-field',
    templateUrl: './recurrence-field.component.html',
    styleUrls: ['./recurrence-field.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('300ms ease-in')),
        ])
    ]
})
export class CustomRecurrenceFieldComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Recurrence period options */
    public period_options = [
        { id: 'none', name: 'None' },
        { id: 'daily', name: 'Daily' },
        { id: 'weekly', name: 'Weekly' },
        { id: 'monthly', name: 'Monthly' },
        { id: 'yearly', name: 'Yearly' }
    ];
    /** Current value of the recurrence field */
    public details: FieldType;
    /** Current selected recurrence end date */
    public date: string;
    /** Recurrence start booking date */
    public before: number = dayjs().valueOf();
    /** Whether to show recurrence information */
    public show: boolean;
    /** Whether to show the date picker for recurrence end */
    public show_tooltip: boolean;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.group.controls.date) {
                this.subscription('date', this.group.controls.date.valueChanges.subscribe((value) => {
                    this.before = value;
                    this.updateDisplay();
                }))
            }
        }
        this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        if (!this.field.control.value) {
            this.setValue({ period: 0 });
        }
    }

    public updateDisplay() {
        this.details = this.field.control.value || { period: 0 };
        this.date = dayjs(this.details.end).format('DD MMM YYYY');
    }

    public setValue(value: FieldType) {
        (this.field.control as any).setValue(value);
    }
}

CUSTOM_FIELD_REGISTER.recurrence = CustomRecurrenceFieldComponent;
CUSTOM_FIELD_REGISTER.recurr = CustomRecurrenceFieldComponent;
