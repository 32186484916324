
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BaseComponent } from '../../../../shared/globals/base.component';
import { ISpace, ICateringMenuCategory } from '../../../../services/data/models/interfaces';

@Component({
    selector: 'catering-edit-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss']
})
export class CateringEditCategoryListComponent extends BaseComponent {
    /** Space selected to add catering to */
    @Input() public space: ISpace;
    /** List of available catering item categories */
    @Input() public types: ICateringMenuCategory[];
    /** Emitter for the selected category */
    @Output() public selected = new EventEmitter<ICateringMenuCategory>();

    public packages: ICateringMenuCategory;

    public selectItem(item: ICateringMenuCategory) {
        this.selected.emit(item);
    }
}
