import * as faker from 'faker';
import * as dayjs from 'dayjs';
import { IEvent, ISystem } from '../../../services/data/models/interfaces';

const DOMAIN = 'acaprojects.com';
export const FEATURES = [
    { extra_id: 'VidConf', extra_name: 'Video Conference' },
    { extra_id: 'AV', extra_name: 'AV' },
    { extra_id: 'presentation', extra_name: 'Wireless Presentation' },
    { extra_id: 'phone', extra_name: 'Conference Phone' },
];

export const ROOM_CONFIGS = [
    { id: 'boardroom', name: 'Boardroom', description: 'A boardroom setting' },
    { id: 'cocktail', name: 'Cocktail', description: 'A cocktail setting' },
    { id: 'theatre', name: 'Theatre', description: 'A theatre setting' },
    { id: 'other', name: 'Other', description: 'Another setting' },
];

export const MOCK_ORG = [
    {
        id: 'zone-org1',
        name: 'Ashrae',
        description: '',
        tags: 'org',
        parent_id: '',
        triggers: [],
        created_at: 0,
        updated_at: 0,
    },
];

const buildingNames = ['Building 1', 'Building 2', 'Building 3'];
const mockBuilding = (name: string, idx: number): any => {
    return {
        id: `zone_bld-0${idx}`,
        name,
        description: '',
        tags: 'building',
        parent_id: MOCK_ORG[0].id,
        triggers: [],
        created_at: 0,
        updated_at: 0,
        extras: [],
        levels: [],
        settings: [],
    };
};
const blds = buildingNames.map((name, idx, arr) => mockBuilding(name, idx));
export const MOCK_BUILDINGS = blds;

export const ROOMS_PER_LEVEL = {
    'zone_lvl-00': [
        {
            id: '00.09',
            name: 'Classroom',
            bookable: false,
        },
        {
            id: '00.10',
            name: 'Classroom',
            bookable: false,
        },
        {
            id: '00.11',
            name: 'Classroom',
            bookable: false,
        },
        {
            id: '00.21',
            name: 'Classroom',
            bookable: false,
        },
        {
            id: '00.22',
            name: 'Classroom',
            bookable: false,
        },
        {
            id: '00.23',
            name: 'Classroom',
            bookable: false,
        },
        {
            id: '00.25',
            name: 'Classroom',
            bookable: false,
        },
    ],
    'zone_lvl-01': [
        {
            id: '01.07',
            name: 'Meeting Room',
            bookable: true,
        },
        {
            id: '01.14',
            name: 'Collaboration Room',
            bookable: true,
        },
        {
            id: '01.15',
            name: 'Collaboration Room',
            bookable: true,
        },
        {
            id: '01.33',
            name: 'Induction Room',
            bookable: true,
        },
        {
            id: '01.34',
            name: 'Induction Room',
            bookable: true,
        },
    ],
    'zone_lvl-02': [
        {
            id: '02.03',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.06',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.07',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.08',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.09',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.14',
            name: 'Forensic Lab',
            bookable: true,
        },
        {
            id: '02.16',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.17',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.18',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.20',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.21',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.22',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.24',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.25',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '02.26',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '02.27',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '02.31',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '02.32',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.33',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.34',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.37',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '02.38',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
    ],
    'zone_lvl-03': [
        {
            id: '03.03',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.06',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.07',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.08',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.09',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.14',
            name: 'Forensic Lab',
            bookable: true,
        },
        {
            id: '03.16',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.17',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.18',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.20',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.21',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.22',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.24',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.25',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '03.26',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '03.27',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '03.31',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '03.32',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.33',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.34',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.37',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '03.38',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
    ],
    'zone_lvl-04': [
        {
            id: '04.03',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.06',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.07',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.08',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.09',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.14',
            name: 'Forensic Lab',
            bookable: true,
        },
        {
            id: '04.16',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.17',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.18',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.20',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.21',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.22',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.24',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.25',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '04.26',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '04.27',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '04.31',
            name: 'Multi-Purpose Area',
            bookable: true,
        },
        {
            id: '04.32',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.33',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.34',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.37',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
        {
            id: '04.38',
            name: 'Multi-Purpose Room',
            bookable: true,
        },
    ],
    'zone_lvl-05': [
        {
            id: '05.06',
            name: 'Leadership',
            bookable: false,
        },
        {
            id: '05.07',
            name: 'Leadership',
            bookable: false,
        },
        {
            id: '05.08',
            name: 'Leadership',
            bookable: false,
        },
        {
            id: '05.09',
            name: 'Leadership',
            bookable: false,
        },
    ],
};

const roomConfigIds = ROOM_CONFIGS.map((m) => m.id);
const identityFeatures = FEATURES.map((m) => ({ id: m.extra_id, name: m.extra_name }));

const shuffle = (a: any[]): any[] => {
    let i: number;
    let j: number;
    let x: any;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
};

const randomConfigs = (): any[] => {
    const shuffled = shuffle([...roomConfigIds]) as string[];
    const rand = Math.floor(Math.random() * shuffled.length) + 1;
    return shuffled
        .slice(0, rand)
        .map((m) => ({ id: m, capacity: 0 }))
        .sort((a, b) => a.id.localeCompare(b.id));
};

const randomFeatures = (): any[] => {
    const shuffled = shuffle([...identityFeatures]) as any[];
    const rand = Math.floor(Math.random() * shuffled.length) + 1;
    return shuffled
        .slice(0, rand)
        .map((m) => m)
        .sort((a, b) => a.name.localeCompare(b.name));
};

const levels = ['zone_lvl-00', 'zone_lvl-01', 'zone_lvl-02', 'zone_lvl-03', 'zone_lvl-04', 'zone_lvl-05'];
const mockLevel = (id: string, idx: number, building: any, buildingIndex: number): any => {
    return {
        id: `${buildingIndex}_${id}`,
        parent_id: building.id,
        name: `Level ${id.endsWith('0') ? 'G' : idx}`,
        description: '',
        tags: 'level',
        created_at: 0,
        updated_at: 0,
        triggers: [],
        number: idx,
        map_url: '',
        settings: {},
        defaults: {},
    };
};

const mockLvls = MOCK_BUILDINGS.map((bld, bIdx, bArr) =>
    levels.map((level, idx, arr) => mockLevel(level, idx, bld, bIdx))
).reduce((prev, curr) => prev.concat(curr), []);
export const MOCK_LEVELS = mockLvls;

const spaces = [];

const systems = [];

mockLvls.forEach((level) => {
    const lvlsubstr = level.id.substring(2);
    const rooms = ROOMS_PER_LEVEL[lvlsubstr];
    let tmp = {};
    let spaceCount = 0;
    rooms.forEach((room) => {
        const id = `sys-rm_${level.parent_id}_${level.number}_${spaceCount++}`;
        tmp = {
            created_at: dayjs().unix(),
            updated_at: dayjs().unix(),
            name: `${room.name}-${room.id}`,
            description: `space ${id}`,
            email: `${id}@mailinator.com`,
            features: '',
            bookable: Math.random() > 0.2,
            capacity: Math.floor(Math.random() * 50),
            installed_ui_devices: 0,
            zones: [level.parent_id, level.id],
            modules: [],
            version: 1,
            id,
        } as ISystem;
        systems.push({ ...tmp });
    });
});

export const MOCK_SYSTEMS = systems;

export const MOCK_SPACES = spaces.map((space_data: any, idx) => {
    const space = {
        ...space_data,
        map_id: `area-${space_data.id}-status`,
        system_id: space_data.id,
        bookings: [],
    };
    if (space.configurations && space.configurations.length) {
        space.configurations.forEach((config) => (config.capacity = Math.floor(Math.random() * space.capacity) + 1));
        space.configurations[0].capacity = space.capacity;
    }
    return space;
});

export const MOCK_USERS = Array(100)
    .fill(0)
    .concat([
        'Alex Sorafumo',
        'Ben Hoad',
        'Jonathan McFarlane',
        'Candy Russo',
        'Cameron Reeves',
        'Kim Burgess',
        'William Le',
        'Steph Georgiadis',
        'Shane Boseley',
        'Matt Mathers',
    ])
    .map((_) => {
        const firstName = !!_ ? _.split(' ')[0] : faker.name.firstName();
        const lastName = !!_ ? _.split(' ')[1] : faker.name.lastName();
        return {
            department: `${faker.company.companyName()}`,
            phone: Math.floor(Math.random() * 8999 + 1000).toString(),
            email: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@${DOMAIN}`,
            name: `${firstName} ${lastName}`,
            id: `user-${Math.floor(Math.random() * 899999 + 100000).toString()}`,
            avatar_url: faker.image.avatar(),
        };
    });

export const MOCK_VISITORS = Array(100)
    .fill(0)
    .map((_) => {
        const firstName = faker.name.firstName();
        const lastName = faker.name.lastName();
        return {
            organisation: `${faker.company.companyName()}`,
            phone: Math.floor(Math.random() * 8999 + 1000).toString(),
            email: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@${DOMAIN}`,
            name: `${firstName} ${lastName}`,
            notes: '',
            checked_in: false,
            visit_expected: true,
            photo: faker.image.avatar(),
        };
    });

const randomUser = (users: any[]): any => {
    const idx = Math.floor(Math.random() * users.length);
    return users[idx];
};

const randomUsers = (users: any[], count: number): any[] => {
    const arr = [];
    while (count > arr.length) {
        const user = randomUser(users);
        if (arr.findIndex((f) => f.email === user.email) === -1) {
            arr.push(user);
        }
    }
    return arr;
};

export const MOCK_BOOKINGS = [];

const randomDuration = () => Math.floor(Math.random() * 5 + 2) * 15;
const numDays = () => Math.floor(Math.random() * 5) + 3;
const numBookings = () => Math.floor(Math.random() * 3) + 3;

for (const system of MOCK_SYSTEMS) {
    const days = numDays();
    for (let day = 0; day < days; day++) {
        const numBkns = numBookings();
        let currentStart = dayjs().startOf('day').add(6, 'hour').add(day, 'day');
        for (let bknIdx = 0; bknIdx < numBkns; bknIdx++) {
            currentStart = currentStart.add(randomDuration(), 'minute');
            const event_start = currentStart.unix();
            currentStart = currentStart.add(randomDuration(), 'minute');
            const event_end = currentStart.unix();
            const randomHost = randomUser(MOCK_USERS);
            let users = randomUsers(MOCK_USERS, 5).map((m) => {
                return { name: m.name, email: m.email };
            });
            users.push({ name: randomHost.name, email: randomHost.email });
            users = users.filter((el, i, r) => r.indexOf(el) === i);
            const visitors = randomUsers(MOCK_VISITORS, 2).map((n) => ({
                name: n.name,
                email: n.email,
                visit_expected: n.visit_expected,
                checked_in: n.checked_in,
            }));

            const attendees = users.concat(visitors);
            const id = `booking-${MOCK_BOOKINGS.length}`;
            const bkn = {
                id,
                host: randomHost.email,
                calendar: system.email,
                creator: 'optional@secretary',
                attendees,
                title: faker.company.companyName(),
                body: `event details here`,
                event_start,
                event_end,
                recurring: false,
                recurrence: null,
                timezone: 'Sydney',
                location: [
                    {
                        text: 'clear text location',
                        address: '23 Daly Ave, Wahroonga',
                        coordinates: { lat: '1234,231', long: '12312,232' },
                    },
                ],
                system: {...system},
                extension_data: {
                    whatever: 'you want',
                    catering_order: [
                        {
                            item: 'Choc Cake',
                            qty: 1,
                        },
                    ],
                },
            } as IEvent;
            MOCK_BOOKINGS.push(bkn);
        }
    }
}
