
import { Component, ViewContainerRef } from '@angular/core';
import { OverlayService } from '@acaprojects/ngx-widgets';
import { ViewEncapsulation } from '@angular/core';
import { UploadManager, Amazon, Md5Workers } from '@acaprojects/ngx-uploads';
import { OAuthService } from '@acaprojects/ngx-composer';

import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    template: `
    <div class="app">
        <router-outlet></router-outlet>
    </div>
    `,
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    constructor(private view: ViewContainerRef, private overlay: OverlayService, private uploads: UploadManager, private _md5_workers: Md5Workers, private auth: OAuthService) {
        this.overlay.view = view;
        (window as any).app_loaded = true;
        if (environment.production && 'serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration()
                .then(active => !active && navigator.serviceWorker.register('__base__ngsw-worker.js'))
                .catch(console.error);
        }
        this._md5_workers.setup('assets/md5_worker.js');
        this.initUploads();
    }

    private initUploads() {
        this.auth.getAccessToken().then((token) => {
            if (!token) {
                return setTimeout(() => this.initUploads(), 300);
            }
            this.uploads.token = token;
            this.uploads.autoStart = true;
            this.uploads.endpoint = '/api/staff/uploads';
            UploadManager.addProvider(Amazon);
        });
    }
}
