
import { CustomDateFieldComponent } from './date-field/date-field.component';
import { CustomTimeFieldComponent } from './time-field/time-field.component';
import { CustomDurationFieldComponent } from './duration-field/duration-field.component';
import { CustomUserListFieldComponent } from './user-list-field/user-list-field.component';
import { CustomWeekFieldComponent } from './week-field/week-field.component';
import { CustomTermsFieldComponent } from './terms-field/terms-field.component';
import { CustomPreferenceFieldComponent } from './preference-field/preference-field.component';
import { CustomCostFieldComponent } from './cost-field/cost-field.component';
import { CustomRecurrenceFieldComponent } from './recurrence-field/recurrence-field.component';
import { CustomUserSearchFieldComponent } from './user-search-field/user-search-field.component';
import { CustomEditorFieldComponent } from './editor-field/editor-field.component';
import { CustomGuestSettingsFieldComponent } from './guest-settings-field/guest-settings-field.component';
import { CustomDropdownFieldComponent } from './dropdown-field/dropdown-field.component';

export const CUSTOM_FORM_FIELDS: any[] = [
    CustomDateFieldComponent,
    CustomTimeFieldComponent,
    CustomDurationFieldComponent,
    CustomUserListFieldComponent,
    CustomWeekFieldComponent,
    CustomTermsFieldComponent,
    CustomPreferenceFieldComponent,
    CustomCostFieldComponent,
    CustomRecurrenceFieldComponent,
    CustomUserSearchFieldComponent,
    CustomEditorFieldComponent,
    CustomGuestSettingsFieldComponent,
    CustomDropdownFieldComponent
];
