
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { BaseComponent } from '../../globals/base.component';
import { AppService } from '../../../services/app.service';
import { IAttendee } from '../../../services/data/models/interfaces';

import * as moment from 'moment';
import { Utils } from '../../utility.class';

@Component({
    selector: 'visitor-form',
    templateUrl: './visitor-form.template.html',
    styleUrls: ['./visitor-form.styles.scss']
})
export class VisitorFormComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() visitor: IAttendee;
    @Output() group = new EventEmitter();
    @Output() close = new EventEmitter();

    public model: { visitor?: IAttendee, [name: string]: any } = {};

    @ViewChild('file_input', { static: true }) private file_input: ElementRef;

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        // this.init();
    }

    // public init() {
    //     if (!this.service.ready()) {
    //         return this.timeout('init', () => this.init());
    //     }
    //     this.model.form = {};
    //     if (!this.model.form.date) {
    //         const date = moment().seconds(0).milliseconds(0);
    //         date.minutes(Math.ceil(date.minutes() / 5) * 5);
    //         this.model.form.date = date.valueOf();
    //     }
    //     this.model.group = 0;
    //     if (this.model.visitor) {
    //         this.model.form.date = this.model.visitor.start;
    //     }
    //     this.model.editable = this.service.Settings.get('app.schedule.edit_visitors') !== false;
    //     this.model.settings = this.service.Settings.get('app.booking') || {};
    //     this.model.contacts = this.service.Settings.get('app.schedule.edit_visitors');
    //     this.model.csv = this.service.Settings.get('app.users.from_csv');
    //     this.updateDisplay();
    // }

    public ngOnChanges(changes: any) {
        // if (changes.visitor) {
        //     this.model.visitor = this.visitor;
        // }
    }

    // public addToGroup() {
    //     if (!this.model.visitor_list) { this.model.visitor_list = []; }
    //     if (this.model.form.users && this.model.form.users.length > 0) {
    //         this.model.visitor_list = this.model.visitor_list.concat(this.model.form.users);
    //         this.model.form = { date: this.model.form.date };
    //     } else {
    //         const user = {
    //             name: (this.model.form.first_name || '') + ' ' + (this.model.form.last_name || ''),
    //             first_name: this.model.form.first_name,
    //             last_name: this.model.form.last_name,
    //             organisation_id: this.model.form.organisation_id,
    //             organisation_name: this.model.form.organisation_name,
    //             organisation: this.model.form.organisation_name,
    //             email: this.model.form.email,
    //             phone: this.model.form.mobile
    //         };
    //         this.model.visitor_list.push(user);
    //         this.model.form = { date: this.model.form.date };
    //     }
    //     this.model.clear = !this.model.clear;
    // }

    // public removeFromGroup(item: any) {
    //     if (this.model.visitor_list) {
    //         for (const i of this.model.visitor_list) {
    //             if (item === i || item.email === i.email) {
    //                 this.model.visitor_list.splice(this.model.visitor_list.indexOf(i), 1);
    //                 break;
    //             }
    //         }
    //     }
    // }

    // public add(finish: boolean = true) {
    //     if (this.service) {
    //         this.model.loading = true;
    //         this.model.processing = true;
    //         if (!this.model.group) {
    //             this.addToGroup();
    //         }
    //         this.service.Visitors.add({
    //             visitors: this.model.visitor_list,
    //             date: this.model.form.date,
    //             name: this.model.group_name,
    //             host: this.model.host
    //         }).then(() => {
    //             this.model.processing = false;
    //             this.model.visitor_list = [];
    //             this.model.finish = finish;
    //             if (finish) {
    //                 this.close.emit();
    //             } else {
    //                 setTimeout(() => {
    //                     this.model.form = {};
    //                     this.model.loading = false;
    //                 }, 3000);
    //             }
    //         }, () => this.model.loading = false);
    //     }
    // }

    // public update() {
    //     if (this.service) {
    //         this.model.loading = true;
    //         this.model.processing = true;
    //         if (!this.model.group) {
    //             this.addToGroup();
    //         }
    //         const vs = this.model.visitor;
    //         this.service.Visitors.update(vs.id, {
    //             visitors: this.model.visitor_list,
    //             date: this.model.form.date,
    //             name: this.model.group_name
    //         }).then(() => {
    //             this.model.processing = false;
    //             this.model.visitor_list = [];
    //             setTimeout(() => this.home(), 3000);
    //         }, () => this.model.loading = false);
    //     }
    // }

    // public edit() {
    //     this.model.edit = true;
    //     this.model.group = this.model.visitor.group ? 1 : 0;
    //     if (this.model.group) {
    //         this.model.visitor_list = this.model.visitor.visitors;
    //     } else {
    //         this.model.form = {
    //             first_name: this.model.visitor.visitors[0].name.split(' ')[0],
    //             last_name: this.model.visitor.visitors[0].name.split(' ')[1],
    //             organisation: this.model.visitor.visitors[0].organisation,
    //             email: this.model.visitor.visitors[0].email,
    //             phone: this.model.visitor.visitors[0].phone,
    //             host: this.model.visitor.host
    //         };
    //     }
    // }

    // public delete() {
    //     if (this.service) {
    //         this.model.loading = true;
    //         this.model.processing = true;
    //         this.model.deleting = true;
    //         this.service.Visitors.delete(this.model.visitor.id).then(() => {
    //             this.model.processing = false;
    //             this.model.visitor_list = [];
    //             setTimeout(() => this.home(), 3000);
    //         }, () => {
    //             this.model.loading = false;
    //             this.model.deleting = false;
    //         });
    //     }
    // }

    // public upload(e) {
    //     if (this.file_input) {
    //         const file = this.file_input.nativeElement.files[0];
    //         if (file) {
    //             const reader = new FileReader();
    //             reader.readAsText(file, 'UTF-8');
    //             reader.onload = (evt) => this.loadVisitorsFromCSV((evt.srcElement as any).result);
    //             reader.onerror = (evt) => this.service.error('Error reading file.');
    //         }
    //     }
    // }

    // private loadVisitorsFromCSV(data) {
    //     const list = Utils.loadCSV(data) || [];
    //     this.model.form.users = list;
    //     for (const u of list) {
    //         u.name = u.name || `${u.first_name} ${u.last_name}`;
    //     }
    //     if (!this.model.group) { this.model.group = true; }
    //     this.addToGroup();
    // }

    // public updateDisplay() {
    //     if (!this.model.form) { this.model.form = {}; }
    //     if (!this.model.form.date) { this.model.form.date = moment().seconds(0).milliseconds(0).valueOf(); }
    //     this.model.display = {};
    //     const date = moment(this.model.form.date);
    //     this.model.display.date = date.format('dddd, Do MMMM YYYY');
    //     this.model.display.time = date.format('h:mm a');
    // }

    // public home() {
    //     this.service.navigate('');
    //     this.close.emit();
    // }

    // public selectDate(field: string = 'date') {
    //     const now = moment();
    //     now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
    //     if (!this.model.form[field]) {
    //         this.model.form[field] = now.valueOf();
    //     }
    //     const date = moment(this.model.form[field]);
    //     this.service.Overlay.openModal('calendar', { data: { date: this.model.form[field] } })
    //         .then((inst: any) => inst.subscribe((event) => {
    //             if (event.type === 'Accept') {
    //                 if (event.data.form && event.data.form[field]) {
    //                     const new_date = moment(event.data.form[field]).hours(date.hours()).minutes(date.minutes())
    //                         .seconds(0).milliseconds(0);
    //                     this.model.form[field] = new_date.valueOf();
    //                 }
    //                 this.updateDisplay();
    //             }
    //             event.close();
    //         }));
    // }

    // public selectTime() {
    //     if (!this.model.form.date) {
    //         const now = moment();
    //         now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
    //         this.model.form.date = now.valueOf();
    //     }
    //     if (!this.model.form.duration) {
    //         this.model.form.duration = 60;
    //     }
    //     const bkn_date = moment(this.model.form.date);
    //     const start_time = bkn_date.format('HH:mm');
    //     this.service.Overlay.openModal('time-period', { data: { start: start_time} })
    //         .then((inst: any) => inst.subscribe((event) => {
    //             if (event.type === 'Accept') {
    //                 const form = event.data.form;
    //                 const new_start = form.start.split(':');
    //                 const start = moment(bkn_date);
    //                 start.hours(new_start[0]).minutes(new_start[1]);
    //                 this.model.form.date = start.valueOf();
    //                 this.updateDisplay();
    //             }
    //             event.close();
    //         }));
    // }

    // public setHost() {
    //     if (this.model.settings.search_host) {
    //         this.service.Overlay.openModal('user-search', { data: { host: this.model.form.host } }, (event) => {
    //             if (event.type === 'Accept') {
    //                 this.model.form.host = event.data.selected;
    //                 this.model.host = this.model.form.host;
    //                 this.model.form = { ...this.model.form };
    //                 this.updateDisplay();
    //             }
    //             event.close();
    //         });
    //     } else {
    //         this.service.Overlay.openModal('set-host', { data: { host: this.model.form.host } }, (event) => {
    //             if (event.type === 'Accept') {
    //                 this.model.form.host = event.data.form;
    //                 this.model.host = this.model.form.host;
    //                 this.model.form = { ...this.model.form };
    //                 this.updateDisplay();
    //             }
    //             event.close();
    //         });
    //     }
    // }

}
