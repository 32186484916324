
import { Routes } from '@angular/router';

import { AppShellComponent } from './shell/shell.component';
import { DayViewComponent } from './shell/day-view/day-view.component';
import { WeekViewComponent } from './shell/week-view/week-view.component';
import { AppNotificationsComponent } from './shell/notifications/notifications.component';
import { AppReportsComponent } from './shell/reports/reports.component';

export const ROUTES: Routes = [
    { path: '', component: AppShellComponent, children: [
        { path: 'day-view', loadChildren: './shell/day-view/day-view.module#DayViewModule' },
        { path: 'week-view', component: WeekViewComponent },
        { path: 'visitor-list', loadChildren: () => import('./shell/visitor-list/visitor-list.module').then(m => m.VisitorListModule) },
        { path: 'catering', loadChildren: () => import('./shell/catering/catering.module').then(m => m.ConciergeCateringModule) },
        { path: 'reports', loadChildren: () => import('./shell/reports/reports.module').then(m => m.ReportsModule) },
        { path: 'notifications', component: AppNotificationsComponent },
        { path: '**',      redirectTo: 'day-view' }
    ] },
    { path: '**',      redirectTo: 'day-view' }
];
