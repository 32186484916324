


import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';

import { BaseService } from '../base.service';
import { ICateringMenuCategory } from '../models/interfaces';


@Injectable({
    providedIn: 'root'
})
export class CateringMenuService extends BaseService<ICateringMenuCategory> {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'catering menu';
        this.model.route = '/menu';
    }

    /**
     * Convert user data to local format
     * @param user User data
     */
    protected processItem(item: { [name: string]: any }) {
        const category: ICateringMenuCategory = {
            id: item.id,
            name: item.name,
            description: item.description,
            zones: item.zones,
            items: this.process(item.items),
            package: item.package === 'true' || item.package === true,
            src: item.image_path
        }
        return category;
    }

    private process(list: (any)[]) {
        return (list || []).map(i => ({
            ...i,
            items: i.items ? this.process(i.items) : null,
            from: i.available_from,
            to: i.available_to,
            price: i.price || i.cost,
            maximum: i.maximum_quantity,
            minimum: i.minimum_quantity,
            notice: i.notify_host,
            package: i.package === 'true' || i.package === true,
            src: i.image_path
        }))
    }

}
