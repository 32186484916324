
import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

@Component({
    selector: 'user-details-overlay',
    templateUrl: './user-details-overlay.template.html',
    styleUrls: ['./user-details-overlay.styles.scss']
})
export class UserDetailsOverlayComponent extends OverlayContentComponent {

    public model: { [name: string]: any } = {};

    public add(finish: boolean = true) {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            this.model.form.name = (this.model.form.first_name || '') + ' ' + (this.model.form.last_name || '');
            this.model.processing = false;
            this.fn.event('Success');
            if (finish) {
                setTimeout(() => this.fn.close(), 3000);
            } else {
                setTimeout(() => {
                    this.model.form = {};
                    this.model.loading = false;
                }, 3000);
            }
        }
    }

    public update() {
        if (this.service) {
            this.model.loading = true;
            this.model.processing = true;
            this.model.form.name = (this.model.form.first_name || '') + ' ' + (this.model.form.last_name || '');
            this.service.Users.update(this.model.form.users ? (this.model.form.users[0] || this.model.form) : this.model.form).then(() => {
                this.model.processing = false;
                this.fn.event('Success');
                setTimeout(() => this.fn.close(), 3000);
            }, () => this.model.loading = false);
        }
    }

    public resize() {
        super.resize();
        this.model.vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }
}
