
import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { ISpace } from '../../services/data/models/interfaces';
import { IMapFeature, MapPinComponent } from '@acaprojects/ngx-interactive-map';

@Component({
    selector: 'view-room',
    templateUrl: './view-room-overlay.template.html',
    styleUrls: ['./view-room-overlay.styles.scss']
})
export class ViewRoomModalComponent extends OverlayContentComponent {
    public model: { [name: string]: any } = {};

    constructor(protected _cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    public get room(): ISpace {
        return this.model.room || {};
    }

    public get features(): IMapFeature[] {
        return [this.focus];
    }

    public get focus(): IMapFeature {
        if (!this.room) {
            return null;
        }
        
        return {
            id: `area-${(this.room as any).map_id}-status`,
            content: MapPinComponent,
            data: {
                back: '#fff',
                fore: '#d31145'
            },
            zoom: 100
        };
    }
}