
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseComponent } from '../../../globals/base.component';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { ISpace } from '../../../../services/data/models/interfaces';

@Component({
    selector: 'custom-cost-field',
    templateUrl: './cost-field.component.html',
    styleUrls: ['./cost-field.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('300ms ease-in')),
        ])
    ]
})
export class CustomCostFieldComponent extends BaseComponent implements OnDestroy {

    public rate = 0;
    public gst = 0;
    public total = 0;
    public duration = 60;
    public show: boolean;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit(): void {
        if (this.group) {
            // Check for changes to selected rooms
            if (this.group.controls.room && this.group.controls.room.value) {
                const control = this.group.controls.room;
                if (control) {
                    this.subscription('rooms', control.valueChanges.subscribe((room: ISpace | ISpace[]) => {
                        if (room instanceof Array && room.length > 0) {
                            this.rate = (room[0] as any).rate || 0;
                        } else if (room) {
                            this.rate = (room as any).rate || 0;
                        }
                        this.updateDisplay();
                    }));
                }
            }
            // Check for changes to the selected duration
            if (this.group.controls.duration && this.group.controls.duration.value) {
                const control = this.group.controls.duration;
                if (control) {
                    this.subscription('reference', control.valueChanges.subscribe((value) => {
                        this.duration = value || 60;
                        this.updateDisplay();
                    }));
                }
            }
            this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
            this.updateDisplay();
        }
    }

    public updateDisplay() {
        this.total = this.rate * (this.duration / 60);
        this.gst = this.total / 11;
    }

    public setValue(value: number) {
        (this.field.control as any).setValue(value);
    }
}

CUSTOM_FIELD_REGISTER.cost = CustomCostFieldComponent;
