import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { IBookingFlowEvent } from '../booking-modal.component';
import { BaseComponent } from '../../../shared/globals/base.component';
import { AppService } from '../../../services/app.service';

@Component({
    selector: 'booking-flow-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class BookingFlowFormComponent extends BaseComponent implements OnChanges {
    /** List of fields to display on the form */
    @Input() fields: ADynamicFormField[] = [];
    /** Emitter for form events */
    @Output() public event = new EventEmitter<IBookingFlowEvent>();

    constructor(private _service: AppService) {
        super();
    }

    /** Whether the form is valid */
    public get valid(): boolean {
        return this.fields.reduce((a, v) => {
            const valid = v.children && v.children.length > 0
                ? v.children.reduce((r, i) => r && (i.isValid() || !i.required), true)
                : (v.isValid() || !v.required);
            return a && valid;
        }, true);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.fields && this.fields) {
            const find = this.fields.find(i => i.key === 'recurrence' || !!(i.children && i.children.find(j => j.key === 'recurrence')));
            const field = find ? (find.key === 'recurrence' ? find : find.children.find(j => j.key === 'recurrence')) : null;
            if (field) {
                this.subscription('recurrence', field.action.subscribe(() => this.setRecurrence(field)));
            }
            const find_space = this.fields.find(i => i.key === 'room' || !!(i.children && i.children.find(j => j.key === 'room')));
            const spaces = find_space ? (find_space.key === 'room' ? find_space : find_space.children.find(j => j.key === 'room')) : null;
            if (spaces && !spaces.hide) {
                this.subscription('spaces', spaces.action.subscribe(() => this.setSpaces(spaces)));
            }
        }
    }

    /**
     * Open modal to change the selected spaces for the booking
     * @param field Form field associated with spaces
     */
    public setSpaces(field: ADynamicFormField) {
        this._service.Overlay.openModal('select-room', {
            data: {
                spaces: field.getValue() || [],
                multi: true
            }
        }, event => {
            if (event.type === 'Select') {
                field.setValue(event.data.select_list || []);
            }
            event.close();
        });
    }

    /**
     * Open modal to change the recurrence options for the booking
     * @param field Form field associated with recurrence
     */
    public setRecurrence(field: ADynamicFormField) {
        this._service.Overlay.openModal('recurrence-form', { data: { form: field.getValue() } }, event => {
            if (event.type === 'Accept') {
                field.setValue({
                    period: event.data.form.recurr_period,
                    end: event.data.form.recurr_end
                });
            }
            event.close();
        });
    }
}
