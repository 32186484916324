import { Component, OnDestroy } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

import { AppService } from '../../services/app.service';
import { IEvent, IBookingNote, ISpace, IUser, IAttendee } from '../../services/data/models/interfaces';

import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as dayjs from 'dayjs';
dayjs.extend(advancedFormat);

import { ADynamicFormField, IFormFieldOptions } from '@acaprojects/ngx-dynamic-forms';
import { formatDate, formatTimestamp, formatDuration, formatSpaces } from '../../shared/utilities/formatting.utilities';
import { CUSTOM_FIELD_REGISTER } from '../../shared/globals/custom-field-register';

@Component({
    selector: 'meeting-details-overlay',
    templateUrl: './meeting-details-overlay.template.html',
    styleUrls: ['./meeting-details-overlay.styles.scss'],
    animations: [
        trigger('show', [
            transition(':enter', [style({ opacity: 0, height: 0 }), animate(200, style({ opacity: 1, height: '*' }))]),
            transition(':leave', [style({ opacity: 1, height: '*' }), animate(200, style({ opacity: 0, height: 0 }))])
        ])
    ]
})
export class MeetingDetailsOverlayComponent extends OverlayContentComponent<AppService> implements OnDestroy {
    public model: { [name: string]: any } = {};

    /** Type of loading state to show */
    public loading: 'delete' | 'approve' | 'decline' | 'update' | 'delete' | 'available' | 'success';
    /** Whether form is initialising */
    public pre_use: boolean;
    /** Whether to show the booking attendees */
    public show_attendees: boolean;
    /** Whether to show the booking organiser notes */
    public show_description: boolean;
    /** Whether to show the booking private notes */
    public show_notes: boolean;
    /** Whether to show the booking catering notes */
    public show_cnotes: boolean;
    /** Whether to show the booking equipment notes */
    public show_enotes: boolean;
    /** Whether to show the booking catering details */
    public show_catering: boolean;
    /** Whether to show the overflow(setup/breakdown) options tooltip */
    public show_tooltip: boolean;
    /** List of setup and breakdown lengths available */
    public overflow_options: string[] = Array(13)
        .fill(0)
        .map((v, i) => `${i * 5} minutes`);
    /** Active setup overflow option */
    public setup_option = 0;
    /** Active setup overflow option */
    public breakdown_option = 0;
    /**  */
    public selecting_space = false;

    /** Edit form fields */
    public form_fields: ADynamicFormField[];

    private _host: any;
    private _creator: any;

    /** Whether the edit form is valid */
    public get valid(): boolean {
        const space_field = this.form_fields.find(i => i.key === 'room');
        const notes = this.form_fields.find(i => i.key === 'private_notes');
        return (space_field && space_field.getValue().length > 0);
    }

    /** Currrency symbol for the active building */
    public get symbol(): string {
        const bld = this.service.Buildings.current() || ({} as any);
        return bld.currency || 'USD';
    }

    /** Booking to display details for */
    public get booking(): IEvent {
        return this.model.booking || {};
    }

    private timeFormat(unix: number): string {
        return dayjs(unix * 1000).format('h:mm a');
    }

    private dateTimeFormat(unix: number): string {
        return dayjs(unix * 1000).format('Do MMM YYYY h:mm a');
    }

    public get startDateFormatted() {
        return this.booking ? this.dateTimeFormat(this.booking.event_start) : '';
    }
    
    public get endDateFormatted() {
        return this.booking ? this.dateTimeFormat(this.booking.event_end) : '';
    }

    public get headingTime() {
        return this.booking ? `${this.timeFormat(this.booking.event_start)} - ${this.timeFormat(this.booking.event_end)}` : '';
    }

    /** Primary room associated with the booking */
    public get room(): ISpace {
        // return this.model.room || this.booking.room || {};
        return {} as any;
    }

    /** List of attendees in the active booking */
    public get attendees(): IAttendee[] {
        return this.booking.attendees || [];
    }

    /** Booking host notes */
    public get description(): string {
        return this.booking.body || (this.notes.find(i => i.type === 'description') || { message: '' }).message;
    }

    /** Booking private notes */
    public get notes(): IBookingNote[] {
        return this.booking.extension_data.notes || [];
    }

    /** Booking private notes */
    public get private_notes(): string {
        const private_notes = this.notes.filter(i => i.type === 'private');
        private_notes.sort((a, b) => b.date - a.date);
        return (private_notes[0] || { message: '' }).message || '';
    }

    /** Status of the active booking */
    public get status(): string {
        return this.booking.extension_data.state || '';
    }

    /** Catering details for the booking */
    public get catering(): any {
        return this.booking.extension_data.catering || null;
    }

    /** Catering notes for the booking */
    public get catering_notes(): string[] {
        return (this.notes.filter(i => i.type === 'catering') || []).map(i => i.message);
    }

    /** Equipment details for the booking */
    public get equipment_notes(): string {
        const space_notes = this.booking.extension_data.space_notes || {};
        return this.room ? space_notes[this.room.id] || 'None' : 'None';
    }

    /** Number attendees expected to turn up to the event */
    public get expected(): number {
        // const expected = this.booking.expected_attendees || {};
        // return this.room ? expected[this.room.id] || 0 : 0;
        return 0;
    }

    /** Catering details for the booking */
    public get charge_codes(): any {
        const equipment_code = this.booking.extension_data.equipment_code || {};
        const code = this.room ? equipment_code[this.room.id] : '';
        const catering_code = this.booking.extension_data.catering_code || '';
        return `${code || ''}${code && catering_code ? ' | ' : ''}${catering_code}`;
    }

    /** Whether the meeting has finished */
    public get expired(): boolean {
        const now = dayjs().startOf('m');
        const end = dayjs(this.model.booking.date).add(this.model.booking.duration, 'm');
        return now.isAfter(end);
    }

    /** Host of the meeting */
    public get organiser(): IUser {
        return this._host;
    }

    /** User that the meeting was booked by */
    public get booker(): IUser {
        return this._creator;
    }

    /** Comma seperated list of room names */
    public get space_list(): string {
        // const room_list = this.booking.room_list || [this.room];
        // return room_list.map(i => i.name).join(', ');
        return '';
    }

    public init() {
        this.pre_use = true;
        if (!this.service || !this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.form = {};
        this.model.group = 0;
        this.model.settings = this.service.Settings.get('app.booking') || {};
        this.model.user = this.service.Users.current();
        if (this.model.booking) {
            this.checkSoon();
            if (this.model.booking.organiser) {
                this.model.organiser =
                    typeof this.model.booking.organiser === 'string' ? this.model.booking.organiser : this.model.booking.organiser.email;
                this.model.is_organiser = this.model.booking.organiser && this.model.organiser === this.model.user.email;
            }
            this.model.check_soon = setInterval(() => this.checkSoon(), 12 * 1000);

            if (!this._host) {
                setTimeout(async () => {
                    try {
                        this._host = await this.service.Users.show(this.booking.host);
                    } catch (e) {
                        console.error(e);
                    }

                    if (!this._host) {
                        try {
                            this._host = await this.service.Users.show(this.booking.creator);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                });
            }
            if (!this._creator) {
                setTimeout(async () => {
                    try {
                        this._creator = await this.service.Users.show(this.booking.creator);
                    } catch (e) {
                        console.error(e);
                    }

                    if (!this._creator) {
                        try {
                            this._creator = { email: this.booking.creator, name: this.booking.creator || 'unknown' } as IUser;
                        } catch (e) {
                            console.error(e);
                        }
                    }
                });
            }
        }
        if (this.model.to_edit) {
            this.edit();
        }
        if (this.model.booking && this.model.booking.setup) {
            this.setup_option = Math.floor(this.model.booking.setup / 5);
        }
        if (this.model.booking && this.model.booking.breakdown) {
            this.breakdown_option = Math.floor(this.model.booking.breakdown / 5);
        }
        this.generateForm();
        this.updateDisplay();
        this.resize();
    }

    public ngOnDestroy() {
        if (this.model.check_soon) {
            clearInterval(this.model.check_soon);
            this.model.check_soon = null;
        }
    }

    public contact() {
        if (this.model.booking && this.model.booking.organiser && this.model.booking.organiser.email) {
            const email = this.model.booking.organiser.email;
            const date = dayjs(this.model.booking.date);
            const day = date.format('DD MMM YYYY');
            const start = date.format('h:mma');
            window.open(
                `mailto:${email}?subject=${encodeURIComponent(
                    `Regarding meeting in ${this.room.name} on the ${day} at ${start}`
                )}`
            );
        }
    }

    /**
     {{ Send }}pproval request to server for booking
     */
    public approve() {
        if (this.model.booking && this.model.booking.id) {
            const state = this.model.booking.state;
            const host = (this.model.booking.organiser || {}).name || this.model.booking.organiser || '';
            const display = this.model.booking.display || {};
            this.service.confirm(
                {
                    title: 'Approve Meeting',
                    message: `Accept ${host}'s meeting in "${(this.room || ({} as any)).name}" at ${display.start} for ${display.duration} on ${display.date}`,
                    icon: 'event_available',
                    accept: 'Ok',
                    cancel: true
                },
                event => {
                    if (event.type === 'Accept') {
                        this.loading = 'approve';
                        this.model.booking.state = 'loading';
                        this.service.Events.accept(this.model.booking.id, {
                            comment: '',
                            room_id: this.room ? this.room.id : (this.model.booking as any).room_id
                        }).then(
                            () => {
                                this.service.success('Meeting approved.');
                                this.model.booking.state = 'busy';
                                this.service.Spaces.removeFromTimeline(this.room.id, this.model.booking.id);
                                this.service.Spaces.addToTimeline(this.model.booking);
                                const states = this.service.get('BOOKING.states') || {};
                                states[this.model.booking.id] = 'busy';
                                this.service.set('BOOKING.states', states);
                                this.loading = null;
                            },
                            () => {
                                this.service.error('Error approving meeting.');
                                this.model.booking.state = state;
                                this.loading = null;
                            }
                        );
                    }
                    event.close();
                }
            );
        }
    }

    /**
     * Send decline request to service for booking
     */
    public decline() {
        if (this.model.booking && this.model.booking.id) {
            const state = this.model.booking.state;
            if (!this.model.booking.organiser) {
                this.model.booking.organiser = {} as any;
            }
            if (!this.model.booking.display) {
                this.model.booking.display = {};
            }
            this.service.confirm(
                {
                    title: 'Decline Meeting',
                    message: `Decline ${this.model.booking.organiser.name || this.model.booking.organiser}'s meeting in "${
                        (this.room || ({} as any)).name
                    }" at ${this.model.booking.display.start} for ${this.model.booking.display.duration} on ${
                        this.model.booking.display.date
                    }`,
                    icon: 'event_busy',
                    accept: 'Ok',
                    cancel: true
                },
                event => {
                    this.loading = null;
                    if (event.type === 'Accept') {
                        const booking: IEvent = this.model.booking;
                        this.loading = 'decline';
                        booking.extension_data.state = 'loading';
                        this.service.Events.decline(booking.id, {
                            id: booking.id,
                            creator: booking.creator,
                            start: booking.event_start,
                            end: booking.event_end,
                            room_id: this.room ? this.room.id : (booking as any).room_id
                        }).then(
                            () => {
                                this.service.success('Meeting declined.');
                                this.model.booking.state = 'cancelled';
                                this.service.Spaces.removeFromTimeline(this.room.id, this.model.booking.id);
                                this.service.Spaces.addToTimeline(this.model.booking);
                                const states = this.service.get('BOOKING.states') || {};
                                states[this.model.booking.id] = 'cancelled';
                                this.service.set('BOOKING.states', states);
                                this.loading = null;
                            },
                            () => {
                                this.service.error('Error declining meeting.');
                                this.model.booking.state = state;
                                this.loading = null;
                            }
                        );
                    }
                    event.close();
                }
            );
        }
    }

    /**
     * Update booking
     */
    public update() {
        if (this.service) {
            this.model.is_booking = true;
            this.model.form_checked = true;
            const booking: IEvent = this.model.booking;
            const form = this.model.form;
            form.setup = (this.setup_option * 5) || booking.setup;
            form.breakdown = (this.breakdown_option * 5) || booking.breakdown;
            if (booking.event_start !== form.event_start || booking.event_end !== form.event_end) {
                this.loading = 'available';
                const setup = (form.setup || 0) * 60;
                const breakdown = (form.breakdown || 0) * 60;
                // Start and/or end time is different check if room is available at new times
                this.service.Spaces.isAvailable(form.room.id, form.date, form.duration, booking.id, setup, breakdown).then(
                    () => this.updateMeeting(),
                    () => {
                        this.loading = null;
                        this.model.is_booking = false;
                        const start = dayjs(form.event_start);
                        const date = start.format('MMM Do, YYYY');
                        const end = dayjs(form.event_end);
                        if (!this.model.error) {
                            this.model.error = {};
                        }
                        this.model.error.other = {
                            message: `The selected space is not free from ${start.format('h:mma')} to ${end.format('h:mma')} on ${date}`
                        };
                    }
                );
            } else {
                this.updateMeeting();
            }
        }
    }

    public updateMeeting() {
        this.model.is_booking = false;
        this.model.loading = true;
        this.model.processing = true;
        this.model.form = { ...this.booking, ...this.model.form, ...this.formToBooking() };
        if (this.model.settings.title_prefix) {
            this.model.form.title = this.model.settings.title_prefix + this.model.form.title;
        }
        if (this.room.id !== this.model.form.room.id) {
            this.model.form.from_room = this.room.id;
        }
        this.model.form.notes = this.notes;
        this.model.form.catering = this.catering;
        this.model.form.icaluid = this.model.booking.icaluid;
        this.loading = 'update';
        this.service.Events.update(this.model.booking.id, this.model.form).then(
            (item: IEvent) => {
                item.extension_data.state = this.model.booking.state;
                this.model.processing = false;
                this.model.visitor_list = [];
                this.service.Spaces.removeFromTimeline(this.room.id, this.model.booking.id);
                const room_list = item.extension_data.room_list || [item.extension_data.room];
                for (const room of room_list) {
                    this.service.Spaces.updateTimeline(room.id, [item]);
                }
                this.loading = 'success';
                setTimeout(() => this.fn.event('Updated'), 3000);
            },
            () => (this.loading = null)
        );
    }

    public delete() {
        if (this.service) {
            const item = this.model.booking;
            this.model.loading = true;
            this.model.processing = true;
            this.model.deleting = true;
            this.service.confirm(
                {
                    title: 'Decline Meeting',
                    message: `Decline ${item.organiser.name || item.organiser}'s meeting in "${(item.room || {}).name}" at ${
                        item.display.start
                    } for ${item.display.duration} on ${item.display.date}`,
                    icon: 'event_busy',
                    accept: 'Ok',
                    cancel: true
                },
                event => {
                    if (event.type === 'Accept') {
                        item.loading = true;
                        this.loading = 'delete';
                        this.service.Events.decline(item.id, {
                            comment: '',
                            room_id: item.room ? item.room.id : item.room_id
                        }).then(
                            () => {
                                this.service.success('Meeting declined.');
                                this.service.Spaces.removeFromTimeline(
                                    item.room ? item.room.id : item.room_id,
                                    item.id,
                                    dayjs(item.date).format('DD MMM YYYY')
                                );
                                item.state = 'busy';
                                item.loading = false;
                                this.model.deleting = false;
                                this.loading = 'success';
                                setTimeout(() => this.fn.close(), 2000);
                            },
                            () => {
                                this.service.error('Error declining meeting.');
                                item.loading = false;
                                this.model.deleting = false;
                                this.model.loading = false;
                                this.model.processing = false;
                                this.loading = null;
                            }
                        );
                    }
                    event.close();
                }
            );
        }
    }

    public edit() {
        this.model.edit = true;
        this.model.form = { ...this.model.booking };
        if (this.model.form.organiser) {
            this.model.form.host =
                typeof this.model.form.organiser === 'string' ? { name: this.model.form.organiser } : this.model.form.organiser;
        }
        this.model.form.notes = this.notes;
        this.model.form.private_notes = this.private_notes;
        // Reset changes to setup and breakdown
        if (this.model.booking && this.model.booking.setup) {
            this.setup_option = Math.floor(this.model.booking.setup / 5);
        }
        if (this.model.booking && this.model.booking.breakdown) {
            this.breakdown_option = Math.floor(this.model.booking.breakdown / 5);
        }
        this.pre_use = true;
        this.interval('check-editor', () => {
            if (document.querySelector('.tox-editor-container')) {
                this.pre_use = false;
                this.clearInterval('check-editor');
            }
        });
    }

    public control() {
        if (this.model.booking && this.room && (this.room as any).support_url) {
            window.open((this.room as any).support_url, 'blank_');
        }
    }

    public checkSoon() {
        const now = dayjs();
        const start = dayjs(this.model.booking.date);
        this.model.is_past = now.isAfter(start, 'm');
        if (this.model.is_past) {
            // this.model.can_edit = false;
        }
        const end = start.add(this.model.booking.duration, 'm');
        // this.model.soon = now.isBetween(start.add(-(this.model.settings.pre_control || 15), 'm'), end, 'm', '[]');
        const cancel = start.add(-(this.service.Settings.get('app.booking.lock_cancel') || 0), 'm');
        this.model.can_cancel = now.isBefore(cancel, 'm');
    }

    /**
     * Save changes to the setup and breakdown times for the active meeting
     */
    public saveTimes() {
        this.model.form = { ...this.model.booking };
        if (this.model.form.organiser) {
            this.model.form.host =
                typeof this.model.form.organiser === 'string' ? { name: this.model.form.organiser } : this.model.form.organiser;
        }
        this.model.form.setup = this.setup_option * 5;
        this.model.form.breakdown = this.breakdown_option * 5;
        this.updateMeeting();
    }

    public locate() {
        if (this.model.booking && this.room && (this.room as any).map_id) {
            this.service.Overlay.openModal('view-room', {
                data: {
                    room: this.room
                }
            }).then((inst: any) => inst.subscribe(event => event.close()));
        }
    }

    public updateDisplay() {
        if (!this.model.form) {
            this.model.form = {};
        }
        if (!this.model.form.date) {
            this.model.form.date = dayjs()
                .startOf('m')
                .valueOf();
        }
        this.model.display = {};
        const date = dayjs(this.model.form.date);
        this.model.display.date = date.format('dddd, Do MMMM YYYY');
        this.model.display.time = date.format('h:mm a');
    }

    public resize() {
        super.resize();
        this.model.vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }

    public keepOverflowOptionsOpen() {
        this.cancelClose();
        this.timeout('overflow-open', () => {
            this.show_tooltip = true;
        });
    }

    public timedClose() {
        if (this.model.edit) {
            return;
        }
        if (this.loading) {
            return;
        }
        this.timeout('close', () => {
            this.close();
        });
    }

    public cancelClose() {
        this.timeout(
            'cancel-close',
            () => {
                this.clearTimer('close');
            },
            10
        );
    }

    /**
     * View catering order details
     */
    public viewCatering() {
        this.storeDetails();
        this.service.navigate(['/catering', 'orders'], { id: this.booking.id });
        this.close();
    }

    /**
     * Convert form field data to a Booking object
     */
    private formToBooking(): IEvent {
        return this.form_fields.reduce((v, i) => {
            if (i.children && i.children.length) {
                i.children.forEach(j => (v[j.key] = j.control.value));
            } else {
                v[i.key] = i.control.value;
            }
            return v;
        }, {}) as IEvent;
    }

    private generateForm() {
        const now = dayjs().endOf('m');
        const date = dayjs(this.booking.event_start).startOf('m');
        const end = dayjs(this.booking.event_end).startOf('m');
        const duration = end.diff(date, 'minute');
        const fields: IFormFieldOptions[] = [
            {
                key: 'date',
                label: 'Date',
                format: formatDate,
                settings: { readonly: true },
                type: 'custom',
                value: this.booking.event_start
            },
            {
                key: 'start',
                label: 'Start time',
                format: formatTimestamp,
                type: 'action',
                settings: { readonly: false },
                value: date.format('HH:mm')
            },
            {
                key: 'duration',
                label: 'Duration',
                format: formatDuration,
                type: 'custom',
                settings: { readonly: true },
                value: duration
            },
            {
                key: 'room',
                label: 'Spaces',
                format: formatSpaces,
                settings: { readonly: now.isAfter(date, 'm') },
                type: 'action',
                value: this.booking.extension_data.room_list ? this.booking.extension_data.room_list : [this.room]
            },
            {
                label: 'Private Notes',
                key: 'private_notes',
                type: 'custom',
                content: CUSTOM_FIELD_REGISTER.description,
                value: this.private_notes
            }
        ]
        this.form_fields = fields.map(i => new ADynamicFormField(i));
        const space_field = this.form_fields.find(i => i.key === 'room');
        const start_field = this.form_fields.find(i => i.key === 'start');
        if (space_field && !space_field.readonly) {
            this.subs.obs.space_change = space_field.action.subscribe(() => this.setSpaces(space_field));
        }

        if (start_field && !start_field.readonly) {
            this.subs.obs.start_change = start_field.action.subscribe(() => this.setStart(start_field));
        }
    }

    /**
     * Open modal to change the selected spaces for the booking
     * @param field Form field associated with spaces
     */
    public setSpaces(field: ADynamicFormField) {
        this.service.Overlay.openModal('select-room', {
            data: {
                start: this.booking.event_start,
                end: this.booking.event_end,
                spaces: field.getValue() || [],
                multi: true,
                id: this.booking.id
            }
        }, event => {
            if (event.type === 'Select') {
                field.setValue(event.data.select_list || []);
            }
            event.close();
        });
    }

    public setStart(field: ADynamicFormField) {
        const start = dayjs(this.booking.event_start);
        const end = dayjs(this.booking.event_end);
        const duration = end.diff(start, 'minute');
        this.service.Overlay.openModal('set-start', {
            data: {
                date: this.booking.event_start,
                booking: this.booking,
                duration: duration
            }
        }, event => {
            event.close();
        });
    }

    /**
     * Store booking to read at the catering module
     */
    public storeDetails() {
        sessionStorage.setItem('CONCIERGE.view_order', JSON.stringify({
            ...this.model.booking,
            room: { ...this.room, bookings: [] }
        }));
    }

    public addCatering() {
        this.timeout('catering', () => {
            if (localStorage) {
                localStorage.setItem('CONCIERGE.booking_form', JSON.stringify({
                    ...this.model.booking,
                    room: { ...this.room, bookings: [] }
                }));
            }
            this.service.Overlay.openModal('catering', { data: {} }, (event) => {
                if (event.type === 'finished') {
                    const booking = event.data.booking;
                    this.model.booking = booking;
                }
                event.close();
            });
        });
    }
}
