
import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { AppService } from '../../services/app.service';
import * as dayjs from 'dayjs';
import { ICateringOrderDisplay, ISpace, IEvent, IBookingNote } from '../../services/data/models/interfaces';


@Component({
    selector: 'catering-details-overlay',
    templateUrl: './catering-details-overlay.component.html',
    styleUrls: ['./catering-details-overlay.component.scss']
})
export class CateringDetailsOverlayComponent extends OverlayContentComponent<AppService> {

    public model: { [name: string]: any } = {};
    /** New note to add to private notes */
    public new_note: string;
    /** Whether the order is being edited */
    public edit: boolean;
    /** Whether changes to order are being processed */
    public loading: boolean;
    /** Whether note additions are being processed */
    public loading_notes: boolean;
    /** List of form fields */
    public form_field: ADynamicFormField;
    /** List of available status */
    public orders_statuses: string[] = ['accepted', 'preparing', 'ready', 'delivered', 'cancelled'];

    /** Order to display on the modal */
    public get order(): ICateringOrderDisplay {
        return this.model.order || {} as any;
    }

    /** Room for the current order */
    public get space(): ISpace {
        const booking = this.order.booking || {} as any;
        return booking.room || {};
    }

    /** Booking associated with the order */
    public get booking(): IEvent {
        return this.order.booking || {} as any;
    }

    /** Private notes for the current order */
    public get private_notes(): IBookingNote[] {
        const booking: IEvent = this.order.booking || {} as any;
        const notes = (booking.extension_data.notes || []);
        return (notes instanceof Array ? notes.filter(i => i.type === 'catering') : null) || [];
    }

    public init() {
        this.model.form = {};
        this.model.group = 0;

        const meeting_date = dayjs(this.model.order.date);
        this.model.meeting_time = meeting_date.format('(DD MMMM YYYY) h:mma');
        // Grab the catering details for the order
        const value: any = {};
        value[this.space.id] = this.order.booking.extension_data.catering || {};
        // Create field for editing order details
        this.form_field = new ADynamicFormField({
            key: 'catering',
            type: 'custom',
            value
        });
    }

    /** Update the status of an order */
    public statusUpdate(status, order) {
        const old_status = order.status;
        order.status = status;
        this.model.show_dropdown = false;
        const booking = order.booking;
        booking.catering = { ...booking.catering, order_status: status };
        this.model.order = this.order;
        this.event('updated');
        this.service.Events.updateItem(booking.id, booking).then(() => {
            this.model.order = this.order;
            this.event('updated');
        }, () => {
            this.service.error('Failed to update status of meeting order');
            order.status = old_status;
            this.model.order = this.order;
            this.event('updated');
        });
    }

    /**
     * Navigate to the day view page for the booking associated with the order
     */
    public toDayView() {
        const room = this.order.room;
        if (room && room.id) {
            const level = this.service.Spaces.level(room);
            if (level) {
                this.service.set('APP.level', level.id);
            }
            this.service.navigate('day-view', { id: this.order.booking.id, room_id: room.id });
            this.close();
        }
    }

    public saveNote() {
        if (this.new_note) {
            const user = this.service.Users.current();
            const booking = this.order.booking;
            const notes = booking.extension_data.notes || [];
            const date = dayjs().startOf('m');
            booking.extension_data.notes = [
                ...notes,
                {
                    type: 'catering',
                    date: date.valueOf(),
                    author: user.name,
                    message: this.new_note,
                    time: date.format('h:mm A')
                }
            ];
            this.new_note = '';
            this.loading_notes = true;
            this.service.Events.updateItem(booking.id, booking).then((bkn) => {
                this.model.order = { ...this.order, bkn };
                this.event('updated');
                this.loading_notes = false;
            }, () => {
                this.service.error('Failed to add note to meeting associated with order');
                booking.extension_data.notes = notes;
                this.loading_notes = false;
            });
        }
    }

    public saveOrder() {
        this.loading = true;
        const booking = this.order.booking;
        const old_order = booking.extension_data.catering;
        const catering_map = this.form_field.getValue();
        const order = catering_map[this.space.id];
        if (order) {
            booking.extension_data.catering = order;
            this.service.Events.updateItem(booking.id, booking).then(() => {
                this.loading = false;
                this.edit = false;
                this.service.success('Successfully updated catering order');
                this.model.order = this.order;
                this.event('updated');
            }, () => {
                this.loading = false;
                this.service.error('Failed to update catering order');
                booking.extension_data.catering = old_order;
            });
        }
    }

    public resize() {
        super.resize();
        this.model.vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }
}
