import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Utils } from '../../shared/utility.class';
import { timeout } from 'q';

@Component({
    selector: 'user-search-modal',
    templateUrl: './user-search-modal.template.html',
    styleUrls: [ './user-search-modal.styles.scss' ]
})
export class UserSearchModalComponent extends OverlayContentComponent {

    public model: { [name: string]: any } = {};

    public search() {
        if (this.model.loading) {
            return this.timeout('loading', () => this.search(), 300);
        }
        this.timeout('search', () => {
            this.model.filtered_users = [];
            if (this.model.search && this.model.search.length >= (this.model.min_char || 2)) {
                this.model.loading = true;
                this.service.Users.query({ q: this.model.search, limit: 50 }).then((users) => {
                    this.model.filtered_users = Utils.filter(this.model.search, users, ['name', 'email']);
                    for (const user of this.model.filtered_users) {
                        if (user.match_name) {
                                // Format match name
                            user.match_name = user.match_name.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                            user.match_name = user.match_name.replace(/\`/g, '');
                        }
                        if (user.match_email) {
                                // Format match email
                            user.match_email = user.match_email.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                            user.match_email = user.match_email.replace(/\`/g, '');
                        }
                    }
                    this.model.loading = false;
                });
            }
        });
    }


    public select(user: any) {
        this.model.selected = user;
        this.fn.event('Accept');
    }
}
