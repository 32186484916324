
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../../shared/globals/base.component';
import { ISpace, ICateringMenuCategory } from '../../../../services/data/models/interfaces';

@Component({
    selector: 'catering-edit-item-list',
    templateUrl: './item-list.component.html',
    styleUrls: ['./item-list.component.scss']
})
export class CateringEditItemListComponent extends BaseComponent {
    /** Space selected to add catering to */
    @Input() public space: ISpace;
    /** Selected category of items to display */
    @Input() category: ICateringMenuCategory;
    /** Field for storing ordered catering items */
    @Input() field: ADynamicFormField;
    /** Event emitter for item listings */
    @Output() public event = new EventEmitter();

}

