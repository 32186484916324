
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

import { BaseComponent } from '../../../shared/globals/base.component';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { ISpace } from '../../../services/data/models/interfaces';

@Component({
    selector: 'booking-flow-equipment-details',
    templateUrl: './equipment-details.component.html',
    styleUrls: ['./equipment-details.component.scss']
})
export class BookingEquipmentDetailsComponent extends BaseComponent implements OnChanges {
    @Input('spaces') public spaces: ISpace[];
    @Input('notes') public _notes: ADynamicFormField;
    @Input('costCode') public _cost_code: ADynamicFormField;
    @Input('expected') public _expected: ADynamicFormField;
    /** ID of the active space */
    public active_space: string = 'default';

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.spaces && this.spaces && this.spaces.length > 0) {
            const space = this.spaces.find(i => i.id === this.active_space);
            if (!space) {
                this.active_space = this.spaces[0].id;
            }
        }
    }

    /** Equipment notes for the meeting */
    public get notes(): { [space_id: string]: string } {
        if (this._notes && this._notes.control) {
            const value = this._notes.control.value;
            if (!value) {
                this._notes.setValue({});
            }
            return this._notes.control.value;
        }
        return {};
    }

    /** Equipment cost code for the meeting */
    public get cost_code(): { [space_id: string]: string } {
        if (this._cost_code && this._cost_code.control) {
            const value = this._cost_code.control.value;
            if (!value) {
                this._cost_code.setValue({});
            }
            return this._cost_code.control.value;
        }
        return {};
    }

    /** Expected number of attendees for spaces */
    public get expected_attendees(): { [space_id: string]: number } {
        if (this._expected && this._expected.control) {
            const value = this._expected.control.value;
            if (!value) {
                this._expected.setValue({});
            }
            return this._expected.control.value;
        }
        return {};
    }
}

