import { Type } from '@angular/core';

import { BookingModalComponent } from './booking-modal.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { BookingFlowFindSpaceComponent } from './find-space/find-space.component';
import { BookingFlowSpaceItemComponent } from './find-space/space-item/space-item.component';
import { BookingCateringDetailsModalComponent } from './catering-details/catering-details.component';
import { BookingEquipmentDetailsComponent } from './equipment-details/equipment-details.component';
import { BookingFlowFormComponent } from './form/form.component';

export * from './booking-modal.component';

export const BOOKING_MODAL_COMPONENTS: Type<any>[] = [
    BookingModalComponent,
    BookingDetailsComponent,
    BookingFlowFormComponent,
    BookingFlowFindSpaceComponent,
    BookingFlowSpaceItemComponent,
    BookingCateringDetailsModalComponent,
    BookingEquipmentDetailsComponent
];
