/*
 * @Author: Alex Sorafumo
 * @Date:   2017-05-25 16:00:37
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-07-02 16:45:34
 */

import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Utils } from '../../shared/utility.class';
import { IOrganisation, IBuilding, IExtra, ILevel } from './models/interfaces';
import { map } from 'rxjs/operators';

interface IMeta { id: string; meta: any; }

@Injectable({
    providedIn: 'root',
})
export class BuildingsService {
    public parent: any = null;
    public defaultBld = '';
    public data: any = {};

    private model: any = {};
    private active = '';
    private org: IOrganisation;
    private organisations: IOrganisation[];
    private buildings: IBuilding[];
    private subjects: any = {};
    private observers: any = {};
    private base_route = `/api/engine/v2`;

    constructor(private http: CommsService) {
        this.subjects.active = new BehaviorSubject<IBuilding>(null);
        this.observers.active = this.subjects.active.asObservable();

        this.subjects.active_level = new BehaviorSubject<ILevel>(null);
        this.observers.active_level = this.subjects.active_level.asObservable();
    }
    /**
     * Initialise service
     */
    public init() {
        if (
            !this.parent ||
            !this.parent.Settings.setup ||
            (this.parent.Settings.get('mock') && !(window as any).backend.is_loaded)
        ) {
            return setTimeout(() => this.init(), 500);
        }
        this.defaultBld = this.parent.Settings.get('building.default');
        this.model.has_orgs = this.parent.Settings.get('app.orgs');
        if (localStorage) {
            this.model.user_set_building = localStorage.getItem('CONCIERGE.building');
        }
        const sub = this.parent.Users.listen('state', (state) => {
            if (state === 'available') {
                this.load();
                sub.unsubscribe();
            }
        });
    }

    /**
     * Load API data
     * @param tries Retry count. DON'T USE
     */
    public load(tries: number = 0) {
        if (!this.parent) {
            return setTimeout(() => this.load(), 500);
        }
        this.loadOrganisations();
        this.loadBuildings();
        this.loadLevels();
    }
    /**
     * Load Organisation Data
     */
    public async loadOrganisations() {
        try {
            const url = `${this.base_route}/zones?tags=org&limit=1000`;
            const response = await this.http.get(url).toPromise();
            this.organisations = (response as any[]).map((o) => {
                const settings = o.settings ? o.settings.discovery_info || {} : {};
                return {
                    id: o.id,
                    name: o.name,
                    parent_id: o.parent_id || '',
                    description: o.description || '',
                    tags: o.tags || 'org',
                    settings,
                    created_at: o.created_at || 0,
                    updated_at: o.updated_at || 0,
                    triggers: o.triggers || [],
                    buildings: [],
                    organisations: [],
                    space_types: {},
                };
            });
            this.org = this.organisations[0];
        } catch (err) {
            this.parent.log('BLD(S)', 'Error loading orgs:', err, 'error');
        }
    }

    public async loadBuildings() {
        try {
            const url = `${this.base_route}/zones?tags=building&limit=1000`;
            const response = await this.http.get(url).toPromise();
            this.buildings = (response as any[]).map((o) => {
                const settings = o.settings ? o.settings.discovery_info || {} : {};
                return {
                    id: o.id,
                    name: o.name,
                    parent_id: o.parent_id || '',
                    description: o.description || '',
                    tags: o.tags || 'building',
                    settings,
                    created_at: o.created_at || 0,
                    updated_at: o.updated_at || 0,
                    triggers: o.triggers || [],
                    levels: [],
                    extras: [],
                } as IBuilding;
            });

            this.subjects.active.next(this.getUserStoredBuildingOrDefault());

            this.organisations.forEach((org) => (org.buildings = this.buildings.filter((f) => f.parent_id === org.id)));
            this.loadBulkZoneMeta(this.buildings);
        } catch (err) {
            this.parent.log('BLD(S)', 'Error loading buildings:', err, 'error');
        }
    }

    private getUserStoredBuildingOrDefault(): IBuilding {
        const defaultBld = this.buildings[0];
        if (localStorage && localStorage.getItem('CONCIERGE.building')) {
            const found = this.buildings.find(f => f.id === localStorage.getItem('CONCIERGE.building'));
            return found || defaultBld;
        }
        return defaultBld;
    }

    public async loadLevels() {
        try {
            const url = `${this.base_route}/zones?tags=level&limit=1000`;
            const response = await this.http.get(url).toPromise();
            const levels = (response as any[]).map((o) => {
                const settings = o.settings ? o.settings.discovery_info || {} : {};
                return {
                    id: o.id,
                    name: o.name,
                    parent_id: o.parent_id || '',
                    description: o.description || '',
                    tags: o.tags || 'level',
                    settings,
                    created_at: o.created_at || 0,
                    updated_at: o.updated_at || 0,
                    triggers: o.triggers || [],
                    number: o.number || '',
                    book_type: o.book_type || '',
                    map_url: o.map_url || '',
                    map: o.map || {
                        features: [],
                        poi: [],
                    },
                    type: o.type || '',
                    defaults: {},
                } as ILevel;
            });
            this.buildings.forEach((building) => (building.levels = levels.filter((f) => f.parent_id === building.id)));
            this.buildings.forEach(bld => this.loadBulkZoneMeta(bld.levels));

            const savedLevelId = localStorage.getItem('CONCIERGE.level');
            if (!!savedLevelId) {
                const level = levels.find(f => f.id === savedLevelId);
                if (!!level) {
                    this.currentLevel = level;
                }
            }
        } catch (err) {
            this.parent.log('BLD(S)', 'Error loading levels:', err, 'error');
        }
    }

    public loadBulkZoneMeta(array: any[]) {
        const obs$ = forkJoin(array.map((el) => this.loadZoneMetadata(el.id))).pipe(map((m) => m));
        obs$.subscribe((meta: IMeta[]) => {
            meta.forEach((m) => {
                const idx = array.findIndex(el => m.id === el.id);
                if (idx > -1) {
                    array[idx] = { ...array[idx], ...m };
                }
            });
        });
    }

    public loadZoneMetadata(id: string) {
        const url = `${this.base_route}/zones/${id}/metadata`;
        return this.http.get(url).pipe(map((meta) => ({ id, meta } as IMeta)));
    }

    private loadSpaceTypes() {
        if (!this.parent) {
            return setTimeout(() => this.loadSpaceTypes(), 500);
        }
        const url = '/control/api/zones?tags=room&limit=1000';
        this.http.get(url).subscribe((resp: { total: number; results: any[] }) => {
            const list = resp.results;
            for (const type of list) {
                this.org.space_types[type.id] = type.name;
            }
        });
    }

    public spaceType(id_list: string | string[]): string {
        if (this.org) {
            const list = id_list instanceof Array ? id_list : [id_list];
            for (const id of list) {
                if (this.org.space_types[id]) {
                    return this.org.space_types[id];
                }
            }
        }
        return 'none';
    }

    /**
     * Get a list of buildings
     */
    public list() {
        const blds: IBuilding[] = [];
        for (const b in this.data) {
            if (this.data.hasOwnProperty(b) && this.data[b]) {
                blds.push(this.data[b]);
            }
        }
        return blds;
    }

    /**
     * Gets the currently active building
     */
    public current(): IBuilding {
        return this.subjects.active.getValue();
    }

    /**
     * Observable for current building
     */
    public listen(next: (data: any) => void) {
        return this.observers.active.subscribe(next);
    }

    public listenLevel(next: (data: any) => void) {
        return this.observers.active_level.subscribe(next);
    }

    /**
     * Get observable for property
     * @param name Name of the property. Possible values active
     */
    public observer(name: string = 'active') {
        return this.subjects[name] ? this.observers[name] : null;
    }

    /**
     * Get Buidling with the given ID
     * @param id Building ID
     */
    public get(id: string) {
        if (this.data[id]) {
            return this.data[id];
        }
        return null;
    }

    /**
     * Set the active building
     * @param id ID of Building
     * @param save Store active building
     */
    public set(id: string, save: boolean = true) {
        if (this.data[id]) {
            this.active = id;
            this.subjects.active.next(this.data[id]);
            if (localStorage && save) {
                localStorage.setItem('CONCIERGE.building', id);
            }
        }
    }

    public get currentLevel() {
        if (!this.subjects.active_level.value) {
            return this.levels(this.current().id)[0];
        }
        return this.subjects.active_level.value;
    }

    public set currentLevel(val: ILevel) {
        localStorage.setItem('CONCIERGE.level', val.id);
        this.subjects.active_level.next(val);
    }

    /**
     * Gets the organisation
     */
    public organisation() {
        return this.org;
    }

    /**
     * Gets the levels of the current building or building with the given ID
     * @param {string} id Building ID
     */
    public levels(id?: string) {
        const bld = this.current();
        if (bld) {
            if (id) {
                for (const lvl of bld.levels) {
                    if (lvl.id === id) {
                        return [lvl];
                    }
                }
            } else {
                return bld.levels || [];
            }
        }
        return [];
    }
    /**
     * Get first level that matches the given ID/IDs
     * @param id_list An ID or array of IDs
     */
    public getLevel(id_list: string | string[]) {
        const list = id_list instanceof Array ? id_list : [id_list];
        for (const id in this.data) {
            if (this.data.hasOwnProperty(id) && this.data[id]) {
                const bld = this.data[id];
                for (const lvl of bld.levels) {
                    if (list.indexOf(lvl.id) >= 0) {
                        return lvl;
                    }
                }
            }
        }
        return null;
    }

    /**
     * Get a list of extras that IDs match
     * @param id String with the IDs
     */
    public getExtras(id: string) {
        const bld = this.current();
        const list: IExtra[] = [];
        if (bld && id) {
            for (const e of bld.extras) {
                if (id.indexOf(e.id) >= 0) {
                    list.push(e);
                }
            }
        }
        return list;
    }

    public getSetting(name: string) {
        const bld = this.current();
        let item = null;
        if (bld) {
            const settings = bld.settings;
            const parts = name.split('.');
            if (parts[0] === 'app' && parts.length > 1) {
                parts.splice(0, 1);
            }
            if (parts.length <= 0) {
                return null;
            }
            item = this.getItemFromKeys(parts, settings);
        }
        if (!item && this.organisation()) {
            const settings = this.organisation().settings;
            const parts = name.split('.');
            if (parts[0] === 'app' && parts.length > 1) {
                parts.splice(0, 1);
            }
            if (parts.length <= 0) {
                return null;
            }
            item = this.getItemFromKeys(parts, settings);
        }
        return item;
    }

    /**
     * Gets nested setting value
     * @param keys List of keys to iterate down the object
     * @param root Root element of the search
     * @return Returns the value a the end of the iteration or null
     */
    private getItemFromKeys(keys: string[], root: any) {
        if (keys.length <= 0) {
            return root;
        }
        if (typeof root !== 'object') {
            return null;
        }
        let item = root;
        // Iterate through keys to traverse object tree
        for (const k of keys) {
            // Make sure key has a value
            if (k !== '') {
                if (item !== undefined && item !== null && item.hasOwnProperty(k)) {
                    item = item[k];
                } else {
                    return null;
                }
            }
        }
        return item;
    }

    // /**
    //  * Load building data from the API
    //  * @param tries Retry count. DON'T USE
    //  */
    // private loadBuildings(tries: number = 0) {
    //     const url = `${this.parent.endpoint}/control/api/zones?tags=building&limit=1000`;
    //     this.http.get(url).subscribe((response: any) => {
    //         const blds = response instanceof Array ? response : response.results;
    //         for (const bld of blds) {
    //             for (const b of this.org.blds) {
    //                 if (bld.id === b.zone_id) {
    //                     const info = bld.settings.discovery_info || {};
    //                     this.data[bld.id] = {
    //                         id: bld.id,
    //                         name: bld.name,
    //                         catering: info.catering,
    //                         phone: info.phone,
    //                         systems: {
    //                             desks: info.desk_tracking,
    //                             messaging: info.messaging
    //                         },
    //                         lockers: info.locker_structure,
    //                         holding_bay: info.holding_bay,
    //                         roles: info.roles || {},
    //                         extras: this.processExtras(info.extras),
    //                         levels: this.processLevels(info.levels, bld.id),
    //                         loan_items: this.processExtras(info.loan_items),
    //                         map: {
    //                             zones: info.map_zones || [],
    //                             searchables: info.neighbourhoods || {}
    //                         },
    //                         code: info.code,
    //                         address: info.address,
    //                         terms: info.terms,
    //                         settings: info.settings || {},
    //                         orgs: info.organisations,
    //                         booking_rules: info.booking_rules,
    //                         visitor_space: info.visitor_space,
    //                         reception_email: info.reception_email || bld.settings.reception_email,
    //                         currency: info.currency,
    //                     };
    //                     Object.defineProperty(this.data[bld.id], 'orgs', {
    //                         get: () => {
    //                             const contact_orgs = this.parent.Contacts.get('orgs') || [];
    //                             const orgs = info.organisations && info.organisations.length > 1 ? [ ...info.organisations ] : [];
    //                             contact_orgs.forEach(i => !orgs.find(o => i === o.name) ? orgs.push({
    //                                 id: i.split('').reduce((a = 0, c: string) => a += c.charCodeAt(0)),
    //                                 name: i
    //                             }) : '');
    //                             orgs.sort((a, n) => a.name.localeCompare(n.name));
    //                             return orgs;
    //                         }
    //                     });
    //                     if (this.data[bld.id] && this.data[bld.id].roles) {
    //                         for (const grp in this.data[bld.id].roles) {
    //                             if (this.data[bld.id].roles.hasOwnProperty(grp)) {
    //                                 const group = this.data[bld.id].roles[grp];
    //                                 for (const user of group) {
    //                                     user.type = 'role';
    //                                 }
    //                             }
    //                         }
    //                     }
    //                         // Set coordinates
    //                     if (info.latitude || info.longitude || info.lat || info.long) {
    //                         this.data[bld.id].coords = {
    //                             longitude: info.longitude || info.long || 0,
    //                             latitude: info.latitude || info.lat || 0
    //                         };
    //                     }
    //                 }
    //             }
    //         }
    //     }, (err) => {
    //         this.parent.log('BLD(S)', 'Error loading buildings:', err, 'error');
    //         setTimeout(() => this.loadBuildings(tries), 500 * ++tries);
    //     }, () => {
    //         const keys = Object.keys(this.data);
    //         if ((!this.default || this.default === '' || !this.data[this.default]) && keys.length > 0) {
    //             this.default = keys[0];
    //         }
    //         if (keys.indexOf(this.model.user_set_building) < 0) {
    //             this.model.user_set_building = null;
    //             if (localStorage) {
    //                 localStorage.removeItem('CONCIERGE.building');
    //             }
    //         }
    //             // Check user's geolocation
    //         if (keys.length > 1 && 'geolocation' in navigator) {
    //             navigator.geolocation.getCurrentPosition((loc) => {
    //                 let bld = null;
    //                 let dist = 999;
    //                 for (const id in this.data) {
    //                     if (this.data.hasOwnProperty(id) && this.data[id].coords) {
    //                         const coords = this.data[id].coords;
    //                         const i_dist = Utils.geodistance(coords.latitude, coords.longitude, loc.coords.latitude, loc.coords.longitude);
    //                         if (i_dist < dist) {
    //                             bld = this.data[id];
    //                             dist = i_dist;
    //                         }
    //                     }
    //                 }
    //                 if (bld) {
    //                     this.parent.log('BLD][S', `Building set to "${bld.name}" based of geolocation`);
    //                     this.set(bld.id, false);
    //                 }
    //             });
    //         }
    //         this.set(this.model.user_set_building || this.default, false);
    //         this.parent.log('BLD(S)', 'Loaded building data');
    //         setTimeout(() => this.loadLevels(), 300);
    //     });
    // }

    // /**
    //  * Load level data from the API
    //  * @param tries Retry count. DON'T USE
    //  */
    // private loadLevels(tries: number = 0) {
    //     if (tries > 10) { return; }
    //     const url = `${this.parent.endpoint}/control/api/zones?tags=level&limit=1000`;
    //     let data = null;
    //     this.http.get(url).subscribe(
    //         (levels: any) => data = levels.results || levels,
    //         (err) => {
    //             this.parent.log('BLD(S)', 'Error loading levels:', err, 'error');
    //             setTimeout(() => this.loadLevels(tries), 500 * ++tries);
    //         },
    //         () => {
    //             if (data instanceof Array) {
    //                 this.parent.log('BLD(S)', 'Loaded level data');
    //                 for (const lvl of (data || [])) {
    //                     const level = this.getLevel(lvl.id || lvl.level_id);
    //                     if (level) {
    //                         level.settings = lvl.settings || {};
    //                         level.map.features = level.settings.map_features || [];
    //                         level.map.poi = level.settings.map_poi || [];
    //                     }
    //                 }
    //             }
    //         });
    // }

    // /**
    //  * Covert building level data to local format
    //  * @param list Array of Levels
    //  * @param id ID of the associated building
    //  */
    // private processLevels(list: any[], id: string = ''): ILevel[] {
    //     const levels: ILevel[] = [];
    //     if (list) {
    //         for (const item of list) {
    //             levels.push({
    //                 id: item.level_id,
    //                 parent_id: id,
    //                 name: item.level_name,
    //                 map_url: item.map_url,
    //                 number:
    //                     item.level_name.indexOf('Level') >= 0
    //                         ? item.level_name.replace('Level ', '')
    //                         : item.level_name[0],
    //                 type: item.floor_type || 'staff',
    //                 book_type: item.book_type,
    //                 map: {},
    //                 defaults: item.defaults || {},
    //             });
    //         }
    //     }
    //     return levels;
    // }

    /**
     * Covert building extras data to local format
     * @param list Array of Extras
     */
    private processExtras(list: any[]): IExtra[] {
        const extras: IExtra[] = [];
        if (list) {
            for (const item of list) {
                extras.push({
                    id: item.extra_id,
                    name: item.extra_name,
                });
            }
        }
        return extras;
    }
}
