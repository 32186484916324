import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../globals/base.component';

import * as dayjs from 'dayjs';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

interface IWeekday {
    id: number;
    value: number;
    month: string;
    day: string;
    past: boolean;
}

@Component({
    selector: 'custom-week-field',
    templateUrl: './week-field.component.html',
    styleUrls: ['./week-field.component.scss']
})
export class CustomWeekFieldComponent extends BaseComponent implements OnInit, OnDestroy {

    /** Days to select for the current week */
    public week: IWeekday[] = [];
    /** Week offset from the current week */
    public offset = 0;
    /** Allow selecting weeks in the past */
    public past = true;
    /** Index of the currently active day of the week */
    public active = -1;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.group.controls.date) {
                this.subscription('date', this.group.controls.date.valueChanges.subscribe(() => {
                    this.updateDisplay();
                }))
            }
        }
        this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        const value = this.group.controls.date ? this.group.controls.date.value || '' : '';
        const date = (value ? dayjs(value) : dayjs());
        this.setValue(date.valueOf());
        this.updateDisplay();
    }

    public updateDisplay() {
        this.week = this.generateWeek(this.field.control.value, this.offset);
        this.active = -1;
        const active_day = dayjs(this.field.control.value);
        for (const day of this.week) {
            if (active_day.isSame(dayjs(day.id), 'd')) {
                this.active = this.week.indexOf(day);
                break;
            }
        }
    }

    public changeWeek(value: number = 1) {
        this.offset += value;
        this.updateDisplay();
    }

    public generateWeek(datestamp: number, offset: number = 0): IWeekday[] {
        // Clean up time
        const week = [];
        const now = dayjs().startOf('d');
        const date = dayjs(datestamp).startOf('w').add(offset, 'w');
        for (let i = 0; i < 7; i++) {
            week.push({
                id: date.valueOf(),
                value: date.date(),
                month: date.format('MMM'),
                day: date.isSame(now, 'd') ? 'Today' : date.format('ddd'),
                past: date.isBefore(now, 'd')
            });
            date.add(1, 'd');
        }
        const previous_week = date.startOf('w').add(-8, 'd');
        this.past = previous_week.isBefore(now, 'd');
        return week;
    }

    public setValue(value: number) {
        (this.field.control as any).setValue(value);
        if (this.group.controls.date) {
            const v = this.group.controls.date ? this.group.controls.date.value || '' : '';
            const ref_date = (v ? dayjs(v) : dayjs());
            const date = dayjs(value).startOf('d').hour(ref_date.hour()).minute(ref_date.minute());
            this.group.controls.date.setValue(date.valueOf());
            this.offset = 0;
        }
    }
}

CUSTOM_FIELD_REGISTER.week = CustomWeekFieldComponent;
