
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule, DynamicFormComponentModule } from '@acaprojects/ngx-widgets';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ADatePickerModule } from '@acaprojects/ngx-date-picker';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ACheckboxModule } from '@acaprojects/ngx-checkbox';
import { CdkTableModule } from '@angular/cdk/table';
import { ADynamicFormsModule } from '@acaprojects/ngx-dynamic-forms';
import { AButtonsModule} from '@acaprojects/ngx-buttons';

import { LOGIN_COMPONENTS } from './login';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { BaseComponent } from '../globals/base.component';
import { USER_SEARCH_COMPONENTS } from './user-search';
import { AvatarComponent } from './avatar/avatar.component';
import { UserTagComponent } from './user-tag/user-tag.component';
import { VisitorFormComponent } from './visitor-form/visitor-form.component';
import { UserFormComponent } from './user-form/user-form.component';
import { CUSTOM_FORM_FIELDS } from './custom-fields';

import { TIME_PIPES } from '../pipes/time.pipe';
import { CateringEditComponent } from './catering/catering.component';
import { CateringEditCategoryListComponent } from './catering/category-list/category-list.component';
import { CateringEditItemListComponent } from './catering/item-list/item-list.component';
import { CateringEditListItemComponent } from './catering/item-list/list-item/list-item.component';
import { CateringEditOrderComponent } from './catering/catering-order/catering-order.component';

const COMPONENTS: any[] = [
    BaseComponent,
    AvatarComponent,
    SearchbarComponent,
    ...LOGIN_COMPONENTS,
    ...USER_SEARCH_COMPONENTS,
    UserTagComponent,
    VisitorFormComponent,
    UserFormComponent,

    CateringEditComponent,
    CateringEditCategoryListComponent,
    CateringEditItemListComponent,
    CateringEditListItemComponent,
    CateringEditOrderComponent
];

const ENTRY_COMPONENTS: any[] = [
    ...CUSTOM_FORM_FIELDS
];

const PIPES: any[] = [
    ...TIME_PIPES
];

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...ENTRY_COMPONENTS,
        ...PIPES
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EditorModule,
        WidgetsModule,
        ScrollingModule,
        ADatePickerModule,
        DynamicFormComponentModule,
        ACheckboxModule,
        ADynamicFormsModule,
        AButtonsModule,
        CdkTableModule
    ],
    exports: [
        ...COMPONENTS,
        ...ENTRY_COMPONENTS,
        ...PIPES,
        ADatePickerModule,
        ACheckboxModule,
        ADynamicFormsModule,
        ScrollingModule,
        AButtonsModule,
        CdkTableModule
    ],
    entryComponents: [
        ...ENTRY_COMPONENTS
    ]
})
export class SharedComponentModule {}
