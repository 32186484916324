import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { ISpace, IBuilding, ICateringMenuCategory } from '../../../services/data/models/interfaces';
import { BaseComponent } from '../../../shared/globals/base.component';
import { AppService } from '../../../services/app.service';
import { IBookingFlowEvent } from '../../../overlays/booking-modal';

@Component({
    selector: 'catering-edit',
    templateUrl: './catering.component.html',
    styleUrls: ['./catering.component.scss']
})
export class CateringEditComponent extends BaseComponent implements OnChanges {
    /** Booking ID */
    @Input() id: string;
    /** Whether catering is part of the booking flow */
    @Input() flow: boolean = true;
    /** List of rooms selected for the booking */
    @Input() spaces: ISpace[];
    /** Form field for storing catering data for the booking */
    @Input() catering: ADynamicFormField;
    /** Emitter for form events */
    @Output() public event = new EventEmitter<IBookingFlowEvent>();

    /** Mapping of rooms to loading states */
    public loading: { [id: string]: boolean } = {};
    /** Mapping of room ids to available menu categories */
    public menu_map: { [room_id: string]: ICateringMenuCategory[] } = {};
    /** Selected menu category */
    public category: ICateringMenuCategory;
    /** Value to filter menu items on */
    public search_str: string;
    /** Active room to add catering items to */
    public active_space: ISpace;

    public show_order: boolean;

    constructor(private _service: AppService) {
        super();
    }

    public get active_building(): IBuilding {
        if (this.active_space) {
            const building = this._service.Spaces.building(this.active_space);
            return building || ({} as any);
        }
        return {} as any;
    }

    /** Whether catering can be provided if no menu is available */
    public get is_catered() {
        return !!this.active_building.has_catering;
    }

    /** Whether the selected room has catering */
    public get has_catering(): boolean {
        if (this.active_space && this.catering) {
            const value = this.catering.control.value;
            const space = value[this.active_space.id] || {};
            return (space ? space.has_catering : null) || false;
        }
        return false;
    }

    public set has_catering(state: boolean) {
        if (this.active_space && this.catering) {
            const value = this.catering.control.value;
            const space = value[this.active_space.id] || {};
            space.has_catering = state;
            value[this.active_space.id] = space;
            this.catering.setValue(value);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.spaces && this.spaces) {
            if (!this.active_space || this.spaces.findIndex(i => i.id === this.active_space.id) < 0) {
                this.active_space = this.spaces[0];
            }
            this.loadMenu();
        }
    }

    /**
     * Load menu categories for the selected rooms
     */
    public loadMenu() {
        if (this.spaces && this.spaces instanceof Array) {
            this.spaces.forEach(i => {
                this.loading[i.id] = true;
                this.menu_map[i.id] = null;
                this._service.CateringMenu.query({ room_id: i.id }).then(
                    list => {
                        this.loading[i.id] = false;
                        this.menu_map[i.id] = list || [];
                    },
                    () => {
                        this.loading[i.id] = false;
                        this.menu_map[i.id] = [];
                    }
                );
            });
        }
    }

    /**
     * Update the active space
     * @param space New active space
     */
    public setSpace(space: ISpace) {
        this.category = null;
        this.timeout('set_space', () => (this.active_space = space), 100);
    }

    /**
     * Filter category items
     */
    public search() {
        if (this.active_space && this.search_str) {
            const search = this.search_str.toLowerCase();
            const categories = this.menu_map[this.active_space.id];
            const list = {
                id: 'search',
                name: 'Search results',
                src: '',
                description: '',
                package: false,
                zones: [],
                items: categories.reduce((v, i) => {
                    v = v.concat(
                        i.items.filter((j: any) => {
                            if (j.items) {
                                return (
                                    j.name.toLowerCase().indexOf(search) >= 0 ||
                                    j.items.findIndex(k => k.name.toLowerCase().indexOf(search) >= 0) >= 0
                                );
                            }
                            return j.name.toLowerCase().indexOf(search) >= 0;
                        })
                    );
                    return v;
                }, [])
            };
            this.category = list;
        } else {
            this.category = null;
        }
    }
}
