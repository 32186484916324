
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/globals/base.component';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-catering',
    templateUrl: './catering.component.html',
    styleUrls: ['./catering.component.scss']
})
export class CateringComponent extends BaseComponent implements OnInit {
    constructor(private _service: AppService, private _route: ActivatedRoute) {
        super();
    }

    /** Name of the current page shown on the booking flow */
    public page: string;

    ngOnInit(): void {
        this.subscription('route', this._route.paramMap.subscribe(params => {
            if (params.has('page')) {
                this.page = params.get('page') as any;
            }
        }));
     }
}

