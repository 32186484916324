import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { AppService } from '../../services/app.service';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { generateBookingFormMetadata } from '../../shared/utilities/booking.utilities';
import * as dayjs from 'dayjs';
import { IEvent } from '../../services/data/models/interfaces';

@Component({
    selector: 'start-edit-modal',
    templateUrl: './start-edit-modal.template.html',
    styleUrls: ['./start-edit-modal.styles.scss'],
})
export class StartEditModalComponent extends OverlayContentComponent<AppService> {
    public model: any = {};
    public form_fields: ADynamicFormField[];

    public init(): void {
        this.form_fields = generateBookingFormMetadata(
            this.model.booking as IEvent,
            this.service.Settings.get('app.booking.fields'),
            this.service
        );
    }

    public finished() {
        this.updateBooking();
    }

    private updateBooking() {
        const date = this.form_fields.find(i => i.key === 'date').getValue();
        const duration = this.form_fields.find(i => i.key === 'duration').getValue();
        const now = dayjs();
        const time = dayjs(date);

        if (time.isBefore(now, 'm') && now.diff(time, 'm') > 5) {
            this.service.error('Booking date and time need to be in future');
            return;
        }

        this.model.booking.date = date;
        this.model.booking.duration = duration;

        if (this.model.booking.room) {
            const roomId = this.model.booking.room.id as string;
            const setup = this.model.booking.room.setup;
            const breakdown = this.model.booking.room.breakdown;

            this.service.Spaces.available({
                room_ids: roomId,
                date,
                duration,
                setup,
                breakdown
            }).then(() => {
                this.sendBookingRequest();
            }, () => {
                if (setup || breakdown) {
                    this.service.error(`Selected spaces are not free at the given time(${date}).
                    Meetings with additional requirements or catering require a minimum buffer of 15 minutes outside the selected period.`);
                } else {
                    this.service.error(`Selected spaces are not free at the given time(${date})`);
                }
            });
        } else {
            this.sendBookingRequest();
        }
    }

    private sendBookingRequest() {
        this.service.Events.update(this.model.booking.id, this.model.booking).then(() => {
            this.service.Spaces.updateTimeline(this.model.booking.room.id, [this.model.booking]);
        }, (e) => {
            this.service.error(`Booking Error: ${e}`);
        });
    }
}