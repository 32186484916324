
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { BaseComponent } from '../../../../shared/globals/base.component';
import { AppService } from '../../../../services/app.service';

import { IBuilding, ICateringMenuCategory, ICateringMenuItem } from '../../../../services/data/models/interfaces';
import { CateringCategoryModalComponent } from '../../overlays/category-modal/category-modal.component';


@Component({
    selector: 'catering-menus',
    templateUrl: './menus.component.html',
    styleUrls: ['./menus.component.scss']
})
export class MenusComponent extends BaseComponent implements OnInit {
    constructor(private service: AppService) { super(); }

    /** Seach input */
    @ViewChild('input', { static: true }) private input: ElementRef<HTMLInputElement>;
    /** Current search string */
    public search_str: string;
    /** Loading state */
    public loading:boolean = true;
    /** Current tab in view - opted for this over custom routes */
    public current_tab: number = 0;
    /** Currently selected menu category */
    public active_category: ICateringMenuCategory;
    /** List of catering menu categories for the active building */
    public catering_menu: ICateringMenuCategory[] = [];
    /** List of catering menu categories for the active building */
    public filtered_menu: ICateringMenuCategory[] = [];
    /** Active building */
    public building: IBuilding;

    /** Currrency symbol for the active building */
    public get symbol(): string {
        const bld = this.building || this.service.Buildings.current();
        return (bld as any).currency || 'USD';
    }

    public ngOnInit(): void {
        this.loading = false;
        this.subscription('bld', this.service.Buildings.listen((bld) => bld ? this.loadMenu() : ''));
        this.loadMenu();
    }

    /**
     * Update the selected category to the given index
     * @param index Index to set category
     */
    public select(index: number, event?: any) {
        if (this.catering_menu.length > index) {
            this.current_tab = index;
            this.active_category = this.filtered_menu[index];
            if (event && event.target) {
                const el: HTMLElement = event.target;
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    /**
     * Add new category to the menu
     */
    public newCategory() {
        this.categoryAction({});
    }

    /**
     * Edit the given category
     * @param item Category to edit
     */
    public editCategory(item: ICateringMenuCategory) {
        this.categoryAction({ category: item });
    }

    /**
     * Edit or Add new category based of the passed data
     * @param data
     */
    private categoryAction(data: { category?: ICateringMenuCategory }) {
        this.service.Overlay.openModal('catering-category', {
            cmp: CateringCategoryModalComponent,
            data
        }, (event) => {
            if (event.type === 'finish') {
                this.loadMenu();
            }
            event.close();
        });
    }

    public deleteCategory(item: ICateringMenuCategory) {
        if (item.id) {
            (item as any).deleting = true;
            this.service.CateringCategories.delete(item.id).then(() => {
                this.service.success('Succesfully deleted item group');
            }, (e) => {
                (item as any).deleting = false;
                if (e !== 'User cancelled'){
                    this.service.error('Failed to delete item group');
                }
            });
        }
    }

    public filter() {
        const search = (this.search_str || '').toLowerCase();
        this.filtered_menu = search ? <any>this.filterSubItems(search, this.catering_menu) : this.catering_menu;
        const tab = this.active_category ? this.filtered_menu.find(i => i.id === this.active_category.id) : null;
        this.active_category = tab ? tab : (this.filtered_menu.length > 0 ? this.filtered_menu[0] : null);
        this.current_tab = this.filtered_menu.findIndex(i => i === this.active_category);
    }

    public filterSubItems(filter: string, list: (ICateringMenuCategory | ICateringMenuItem)[]) {
        const filtered = [];
        list.map(i => ({ ...i })).forEach(i => {
            const match = (i.name || '').toLowerCase().indexOf(filter) >= 0 || (i.description || '').toLowerCase().indexOf(filter) >= 0
            if (!match && (i as any).items) {
                (i as any).items = this.filterSubItems(filter, (i as any).items);
            }
            if (match || ((i as any).items && (i as any).items.length)) {
                filtered.push(i);
            }
        });
        return filtered;
    }

    /**
     * Load list of catering menu categories for the active building
     */
    public loadMenu() {
        this.timeout('load-menu', () => {
            const bld = this.service.Buildings.current();
            if (bld) {
                this.building = bld;
                this.loading = true;
                this.service.CateringMenu.query({ zone_id: bld.id })
                    .then((menu) => {
                        this.catering_menu = menu || [];
                        this.select(0);
                        this.filter();
                        this.loading = false;
                    }, () => this.loading = false);
            }
        }, 100);
    }

    /**
     * Searchbar focus event
     */
    public focus(){

    }
}