
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IBuilding, ICateringMenuItem, ICateringMenuCategory } from '../../../../../../services/data/models/interfaces';
import { BaseComponent } from '../../../../../../shared/globals/base.component';
import { CateringGroupModalComponent } from '../../../../overlays/group-modal/group-modal.component';
import { AppService } from '../../../../../../services/app.service';
import { CateringItemModalComponent } from '../../../../overlays/item-modal/item-modal.component';

@Component({
    selector: 'catering-category-item',
    templateUrl: './category-item.component.html',
    styleUrls: ['./category-item.component.scss']
})
export class CateringCategoryItemComponent extends BaseComponent {
    @Input() public item: ICateringMenuCategory | ICateringMenuItem;
    @Input() public parent: ICateringMenuCategory;
    /** Emitter for change events to the menu */
    @Output() public event = new EventEmitter();
    /** Whether the item is being edited */
    public editing: boolean;
    /** Show options for the item */
    public show_options: boolean;
    /** Active building */
    public building: IBuilding;

    /** Currrency symbol for the active building */
    public get symbol(): string {
        const bld = this.building || this.service.Buildings.current();
        return bld['currency'] || 'USD';
    }

    public get price(): number {
        return this.item ? this.item.price / 100 : 0
    }

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit(): void {
        this.subscription('bld', this.service.Buildings.listen((bld) => this.building = bld || this.building));
    }

    /**
     * Add new item to the menu category
     */
    public newItem() {
        this.itemAction({});
    }

    /**
     * Edit the given item
     * @param item Item to edit
     */
    public editItem(item: ICateringMenuItem) {
        this.itemAction({ item });
    }

    /**
     * Edit or Add new item based of the passed data
     * @param data
     */
    private itemAction(data: { item?: ICateringMenuItem }) {
        const category = !data.item || data.item !== this.item ? this.item : this.parent;
        this.show_options = false;
        this.service.Overlay.openModal('catering-item', {
            cmp: CateringItemModalComponent,
            data: { ...data, category }
        }, (event) => {
            if (event.type === 'finish') {
                this.event.emit('update');
            }
            event.close();
        });
    }

    public deleteItem(item: ICateringMenuItem) {
        if (item.id) {
            this.show_options = false;
            (item as any).deleting = true;
            this.service.CateringItems.delete(item.id).then(() => {
                this.event.emit('removed');
                this.service.success('Succesfully deleted item');
            }, (e) => {
                (item as any).deleting = false;
                if (e !== 'User cancelled'){
                    this.service.error('Failed to delete item');
                }
            });
        }
    }

    public deleteGroup(item: ICateringMenuCategory) {
        if (item.id) {
            this.show_options = false;
            (item as any).deleting = true;
            this.service.CateringCategories.delete(item.id).then(() => {
                this.event.emit('removed');
                this.service.success('Succesfully deleted item group');
            }, (e) => {
                    (item as any).deleting = false;
                    if (e !== 'User cancelled'){
                        this.service.error('Failed to delete item group');
                    }
            });
        }
    }

    /**
     * Add new sub-category or package to the menu
     */
    public newGroup() {
        const category = this.parent;
        this.groupAction({ category });
    }

    /**
     * Edit the given group
     * @param item Group to edit
     */
    public editGroup(item: ICateringMenuCategory) {
        const category = this.parent;
        this.groupAction({ category, group: item });
    }

    /**
     * Edit or Add new category based of the passed data
     * @param data
     */
    private groupAction(data: { category: ICateringMenuCategory, group?: ICateringMenuCategory }) {
        this.show_options = false;
        this.service.Overlay.openModal('catering-category', {
            cmp: CateringGroupModalComponent,
            data
        }, (event) => {
            if (event.type === 'finish') {
                this.event.emit('update');
            }
            event.close();
        });
    }
}

