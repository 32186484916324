import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { IEvent, ISpace, IUser ,ICateringOrderList } from '../../services/data/models/interfaces';

import { generateBookingFormMetadata } from '../../shared/utilities/booking.utilities';
import { formatSpaces } from '../../shared/utilities/formatting.utilities';

import * as dayjs from 'dayjs';
import { IBookingFlowEvent } from '../booking-modal';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'catering-modal',
    templateUrl: './catering-modal.template.html',
    styleUrls: ['./catering-modal.styles.scss'],
})
export class CateringModalComponent extends OverlayContentComponent<AppService> {
    public model: { [name: string]: any } = {};
    /** Currently displayed page of the booking modal flow */
    public page: 'catering' | 'catering-details';
    /** Booking ID */
    public id: string;
    /** List of form fields associated with a booking */
    public form_fields: ADynamicFormField[];
    /** Form field for the selected date */
    public date_field: ADynamicFormField;
    /** Time of the last booking event */
    public book: number;
    /** Whether booking succeeded */
    public success: boolean;
    /** Whether catering details are being saved */
    public loading: boolean;

    public init(): void {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.loadForm();
    }

    public get form() {
        return this.formToBooking();
    }

    /** Form field for the list of selected spaces */
    public get space_list(): ADynamicFormField {
        const index = this.form_fields.findIndex(i => i.key === 'room');
        return index ? this.form_fields[index]: null;
    }

    /** Form field for listing selected catering items */
    public get catering() {
        return this.form_fields.find(i => i.key === 'catering');
    }

    /** Form field for equipment notes */
    public get notes() {
        return this.form_fields.find(i => i.key === 'equipment');
    }

    /** Form field for catering notes */
    public get catering_notes() {
        return this.form_fields.find(i => i.key === 'catering_notes');
    }

    /** Form field for catering cost code */
    public get catering_code() {
        return this.form_fields.find(i => i.key === 'catering_code');
    }

    /** Form field for equipment cost code */
    public get cost_code() {
        return this.form_fields.find(i => i.key === 'equipment_code');
    }

    /** Whether the form is valid */
    public get valid(): boolean {
        return this.form_fields.reduce((a, v) => {
            const valid = v.children && v.children.length > 0
                ? v.children.reduce((r, i) => r && (i.isValid() || !i.required || i.disabled), true)
                : (v.isValid() || !v.required || v.disabled);
            return a && valid;
        }, true);
    }

    /** List of selected rooms for the booking */
    public get spaces(): ISpace[] {
        const field = this.space_list;
        if (field) {
            return field.control.value || [];
        }
        return [];
    }

    /** Datetime selected for the booking */
    public get date(): number {
        const field = this.form_fields.find(i => i.key === 'date');
        if (field) {
            return field.control.value;
        }
        return dayjs()
            .startOf('m')
            .valueOf();
    }

    /** Selected duration for the booking */
    public get duration(): number {
        let field: any = this.form_fields.find(i => i.key === 'duration');
        if (field) {
            return field.control.value;
        }
        field = this.form_fields.find(i => i.key === 'time_group');
        if (field) {
            return field.control.controls.duration.value;
        }
        return 60;
    }

    /** Number of items in the catering order */
    public get has_ordered(): number {
        const empty = { control: { value: {} } };
        const order: ICateringOrderList = (this.form_fields.find(i => i.key === 'catering') || empty).control.value;
        return order ? Object.keys(order).reduce((v, key) => {
            const room_order = order[key];
            if (room_order && room_order.items) {
                v += room_order.items.reduce((c, i) => c + (i.amount || 0), 0);
            }
            return v;
        }, 0): 0;
    }

    /** Type of booking */
    public get type(): string {
        const empty = { control: { value: {} } };
        const type = ((this.form_fields || []).find(i => i.key === 'booking_type') || empty).control.value || '';
        return typeof type === 'string' ? type : type.id;
    }

    /**
     * Progress to the next step in the flow
     */
    public progress(event: IBookingFlowEvent) {
        if (event.type === 'next') {
            switch (this.page) {
                case 'catering-details':
                    this.save();
                    break;
                default:
                    this.form_fields.forEach(i => i.control.markAsDirty());
                    this.page = 'catering-details';
                    break;
            }
        } else if (event.type === 'previous') {
            switch (this.page) {
                case 'catering-details':
                    this.page = 'catering';
                    break;
                default:
                    this.close();
                    break;
            }
        } else if (event.type === 'start') {
            this.page = 'catering';
        } else if (event.type === 'save') {
            this.save();
        }
    }

    /**
     * Clear any form data
     */
    private clearForm() {
        if (localStorage) {
            localStorage.removeItem('CONCIERGE.booking_form');
        }
        this.loadForm();
    }

    /**
     * Load old form data and intialise the form
     */
    private loadForm() {
        let booking: any = {};
        if (localStorage) {
            booking = JSON.parse(localStorage.getItem('CONCIERGE.booking_form') || '{}');
        }
        this.form_fields = generateBookingFormMetadata(
            booking as IEvent,
            this.service.Settings.get('app.booking.fields'),
            this.service
        );
        this.date_field = this.form_fields
            .reduce((v, i) => v.concat(i.children && i.children.length ? i.children : [i]), [])
            .find(i => i.key === 'date');
        const CUSTOM_FIELDS = ['room', 'catering', 'equipment', 'catering_notes', 'catering_code', 'equipment_code', 'id'];
        const CUSTOM_DEFAULTS = [[], {}, '', '', '', '', ''];
        CUSTOM_FIELDS.forEach((key, i) => {
            this.form_fields.push(new ADynamicFormField({
                key,
                label: key,
                type: 'action',
                format: key === 'room' ? formatSpaces : null,
                hide: true,
                value: booking[key] || CUSTOM_DEFAULTS[i]
            }));
        });
        this.form_fields.forEach(i => {
            if (i.children && i.children.length) {
                i.children.forEach(j => {
                    this.subs.obs[j.key] = j.control.valueChanges.subscribe(v => {
                        if (v && j.control.valid) { this.saveForm(); }
                    });
                });
            } else {
                this.subs.obs[i.key] = i.control.valueChanges.subscribe(v => {
                    if (v && i.control.valid) { this.saveForm(); }
                });
            }
        });
        const empty = { control: { value: true } };
        const id = (this.form_fields.find(i => i.key === 'id') || empty).control.value;
        const time = (this.form_fields.find(i => i.key === 'start') || empty);
        const room = (this.form_fields.find(i => i.key === 'room'));
        if (room) {
            room.setValue(booking.room_list || [booking.room]);
        }

        (time as ADynamicFormField).setDisabled(this.duration > 450);
        this.id = id ? '10' : '';
        console.log('Booking:', booking);
        console.log('Form:', this.formToBooking());
    }

    /**
     * Save changes to the form data
     */
    private saveForm() {
    }

    /**
     * Convert form field data to a Booking object
     */
    private formToBooking(): IEvent {
        return this.form_fields.reduce((v, i) => {
            if (i.children && i.children.length) {
                i.children.forEach(j => (v[j.key] = j.control.value));
            } else {
                v[i.key] = i.control.value;
            }
            return v;
        }, {}) as IEvent;
    }

    /**
     * Get list of users available to select as the meeting host
     */
    private getHostOptions(): IUser[] {
        const user = this.service.Users.current();
        const list: IUser[] = [user];
        if (user.delegates) {
            for (const email of user.delegates) {
                const delegate = this.service.Users.item(email);
                if (delegate) {
                    list.push(delegate);
                } else {
                    this.timeout(`load_user|${email}`, () =>
                        this.service.Users.show(email, { update: true }).then(() => {
                            this.timeout('update_host_options', () => {
                                const host = this.form_fields.find(i => i.key === 'host');
                                if (host) {
                                    host.metadata = { service: this.service.Events, options: this.getHostOptions() };
                                    host.setValue(host.getValue());
                                }
                            });
                        })
                    );
                }
            }
        }
        return list;
    }

    private save() {
        let booking: any = {};
        if (localStorage) {
            booking = JSON.parse(localStorage.getItem('CONCIERGE.booking_form') || '{}');
        }
        this.service.confirm({
            title: 'Save Catering',
            message: `Save catering details for meeting?`,
            icon: 'restaurant',
            accept: 'Ok',
            cancel: true
        }, (event) => {
            if (event.type === 'Accept') {
                this.loading = true;
                booking = { ...booking, ...this.formToBooking() };
                console.log('Booking:', booking);
                this.service.Events.updateItem(booking.id, booking).then((bkn) => {
                    this.loading = false;
                    this.model.booking = bkn;
                    this.event('finished');
                    this.clearForm();
                    this.close();
                }, () => {
                    this.loading = false;
                    this.service.error('Error saving catering.')
                })
            }
            event.close();
        })
    }

    public timedClose() {
        this.timeout('close', () => this.close());
    }

    public cancelClose() {
        this.timeout(
            'cancel-close',
            () => this.clearTimer('close'),
            10
        );
    }
}
