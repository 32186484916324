import { Component, OnDestroy, OnInit, Inject, forwardRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { BaseComponent } from '../../../globals/base.component';
import { AppService } from '../../../../services/app.service';

import { Utils } from '../../../utility.class';
import { IUser } from '../../../../services/data/models/interfaces';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

@Component({
    selector: 'custom-user-search-field',
    templateUrl: './user-search-field.component.html',
    styleUrls: ['./user-search-field.component.scss']
})
export class CustomUserSearchFieldComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Currently logged in user */
    public current: IUser;
    /** List of delegate emails for the current user */
    public delegates: string[] = [];
    /** List of user details for delegates */
    public results: IUser[] = [];
    /** Whether to show search results */
    public show: boolean;
    /** List of filtered users */
    public filtered: IUser[] = [];
    /** Subject for changes to the search string */
    private filter$ = new Subject<string>();
    /** Currently selected user */
    public user: IUser;
    /** Search string for filtering available users */
    public search_str: string;
    /** Whether users are being searched */
    public loading: boolean;

    constructor(
        protected field: ADynamicFormField,
        protected group: FormGroup,
        @Inject(forwardRef(() => AppService)) private service: AppService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        this.updateDisplay();
        this.current = this.service.Users.current();
        this.subscription(
            'filter',
            this.filter$
                .pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    switchMap(filter => {
                        if (filter && filter.length >= 3) {
                            this.show = true;
                            this.loading = true;
                            return this.service.Users.query({ q: filter });
                        }
                    })
                )
                .subscribe(results => {
                    this.loading = false;
                    this.results = results;
                    this.filterResult(this.search_str);
                    this.show = true;
                })
        );
        this.subscription('control', this.field.control.valueChanges.subscribe(() => this.updateDisplay()));
        this.updateDisplay();
    }

    public search(filter: string) {
        this.filter$.next(filter);
    }

    public filterResult(filter) {
        const group = [...this.results, this.service.Contacts.list()];
        this.filtered = Utils.filter(filter, group, ['name', 'email']);
        this.filtered.forEach((i: any) => {
            if (i.match_name) {
                i.match_name = i.match_name.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                i.match_name = i.match_name.replace(/\`/g, '');
            }
            if (i.match_email) {
                i.match_email = i.match_email.replace(/\`[a-zA-Z0-9\@\.\_]*\`/g, '<span class="highlight">$&</span>');
                i.match_email = i.match_email.replace(/\`/g, '');
            }
        });
    }

    public updateDisplay() {
        this.user = this.field.control.value || null;
        if (this.user) {
            this.search_str = this.user.name;
        }
    }

    public reset() {
        this.timeout('reset', () => {
            this.search_str = (this.user || this.service.Users.current()).name;
            this.show = false;
        });
    }

    public select(user: IUser) {
        if (user) {
            (this.field.control as any).setValue(user);
        }
        this.show = false;
    }
}

CUSTOM_FIELD_REGISTER.host = CustomUserSearchFieldComponent;
// CUSTOM_FIELD_REGISTER.host_index = CustomUserSearchFieldComponent;
CUSTOM_FIELD_REGISTER.user = CustomUserSearchFieldComponent;
