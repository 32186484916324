
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseComponent } from '../../../globals/base.component';
import { AppService } from '../../../../services/app.service';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

@Component({
    selector: 'custom-guest-settings-field',
    templateUrl: './guest-settings-field.component.html',
    styleUrls: ['./guest-settings-field.component.scss'],
})
export class CustomGuestSettingsFieldComponent extends BaseComponent implements OnInit, OnDestroy {

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup, private service: AppService) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit(): void {
        if (this.group) {
            if (this.group.controls.attendees) {
                this.subscription('attendees', this.group.controls.attendees.valueChanges.subscribe(() => this.updateDisplay()));
            }
        }
        let last_value = null;
        this.subscription('control', this.field.control.valueChanges.subscribe((value) => {
            if (value !== last_value) {
                last_value = value;
                this.updateDisplay();
            }
        }));
        if (this.field.control.value) {
            const notify_users = this.field.control.value;
            const email = notify_users ? notify_users[0] : null;
            if (email) {
                this.service.Users.query({ q: email }).then(res => {
                    const user = res && res.length > 0 ? res[0] : null;
                    this.setValue({ ...user, email });
                })
            }
        }
        this.updateDisplay();
    }

    public updateDisplay() {
        if (this.group.controls.attendees && this.group.controls.attendees.value) {
            this.field.setDisabled(!(this.group.controls.attendees.value || []).find(i => i.external));
        } else {
            this.field.setDisabled(true);
        }
    }

    public setGuestSettings() {
        const host = this.group.value.host;
        const user = this.field.control.value;
        if (host) {
            this.service.Overlay.openModal('guest-settings', { data: { host, user }})
                .then((inst: any) => inst.subscribe((event) => {
                    if (event.type === 'Accept') {
                        const greeter = event.data.greeter;
                        this.setValue(greeter.user);
                        event.close();
                    }
                    if (event.type === 'close') { event.close(); }
                }));
        }
    }

    private getHostOptions() {
        const user = this.service.Users.current();
        const list = [user];
        if (user.delegates) {
            for (const email of user.delegates) {
                const delegate = this.service.Users.item(email);
                if (delegate) {
                    list.push(delegate);
                }
            }
        }
        return list;
    }

    public setValue(value: any) {
        (this.field.control as any).setValue(value);
    }
}

CUSTOM_FIELD_REGISTER.notify_user = CustomGuestSettingsFieldComponent;
