
import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { WidgetsModule, DynamicFormComponentModule } from '@acaprojects/ngx-widgets';

import { SharedComponentModule } from '../../shared/components/shared.module';
import { CateringComponent } from './catering.component';
import { OrdersComponent } from './components/orders/orders.component';
import { MenusComponent } from './components/menus/menus.component';

import { CateringCategoryListComponent } from './components/menus/category-list/category-list.component';
import { CateringCategoryItemComponent } from './components/menus/category-list/category-item/category-item.component';
import { CateringCategoryModalComponent } from './overlays/category-modal/category-modal.component';
import { CateringGroupModalComponent } from './overlays/group-modal/group-modal.component';
import { CateringItemModalComponent } from './overlays/item-modal/item-modal.component';


const ROUTES: Route[] = [
    { path: '', component: CateringComponent },
    { path: ':page', component: CateringComponent},
    { path: '**', redirectTo: '' }
]

export const CATERING_ENTRY_COMPONENTS: Type<any>[] = [
    CateringCategoryModalComponent,
    CateringGroupModalComponent,
    CateringItemModalComponent
];

@NgModule({
    declarations: [
        CateringComponent,
        OrdersComponent,
        MenusComponent,
        CateringCategoryListComponent,
        CateringCategoryItemComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedComponentModule,
        RouterModule.forChild(ROUTES),
        WidgetsModule,
        DynamicFormComponentModule
    ],
    exports: [
    ],
    providers: [],
    entryComponents: [
    ]
})
export class ConciergeCateringModule {}
