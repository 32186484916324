import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { IAttendee } from '../../services/data/models/interfaces';
import { Utils } from '../../shared/utility.class';

const VALID_DOMAINS = ['@pwc-it.com', '@external.pwc-it.com', '@quantumblack.com'];

@Component({
    selector: 'add-visitor-modal',
    templateUrl: './add-visitor-modal.component.html',
    styleUrls: ['./add-visitor-modal.component.scss']
})
export class AddVisitorModalComponent extends OverlayContentComponent {
    public model: any = {};
    /** List of new visitors to add to the meeting */
    public attendees: IAttendee[] = [];
    /** Whether to user input email is valid */
    public valid_email = false;
    /** Whether there is already an visitor with the form email */
    public valid_match = false;
    /** Form parameters */
    public form = { name: '', email: '' };
    /** Count of the number of new users */
    public new_users: number = 0;

    public init() {
        this.attendees = this.model.attendees || [];
    }

    /**
     * Add new visitor with the given form values
     */
    public addVisitor() {
        if (this.valid_email || !this.form.email) {
            if (VALID_DOMAINS.findIndex(i => this.form.email.indexOf(i) >= 0) >= 0) {
                return this.service.error('Visitors email need to be on an external domain')
            }
            if (!this.form.email) {
                this.form.email = `${Math.floor(Math.random() * 9999_9999)}@guest.pwc-it.com`;
            }
            this.form.email = this.form.email.toLowerCase();
            if (!this.form.name) {
                this.form.name = this.form.email
                    .split('@')[0]
                    .split('.')
                    .join(' ')
                    .split('_')
                    .join(' ');
            }
            this.attendees.push(this.form as IAttendee);
            this.form = { name: '', email: '' };
            this.new_users = this.attendees.filter(f => !f.visit_expected).length;
            this.clearForm();
        }
    }

    /**
     * Remove visitor with the given email address
     * @param email Email address of the user to remove
     */
    public removeVisitor(email: string) {
        this.attendees = this.attendees.filter(i => i.email !== email);
        this.new_users = this.attendees.reduce((n, i) => i.visit_expected ? ++n : n, 0);
    }

    /**
     * Clear the form fields
     */
    public clearForm() {
        this.form.name = '';
        this.form.email = '';
    }

    /**
     * Post new visitors to the parent component
     */
    public submit() {
        this.model.loading = true;
        this.model.visitors = this.attendees;
        this.event('finish');
    }

    /**
     * Validate the email address input by the user
     */
    public validateEmail() {
        this.valid_match = false;
        if (this.form.email) {
            this.valid_email = Utils.validateEmail(this.form.email);
            if (this.attendees.findIndex(i => i.email === this.form.email.toLowerCase()) >= 0) {
                this.valid_match = true;
                this.valid_email = false;
            }
        } else {
            this.valid_email = false;
        }
    }
}
