
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import { ISpace, IBuilding, ILevel } from '../../../../services/data/models/interfaces';
import { BaseComponent } from '../../../../shared/globals/base.component';
import { AppService } from '../../../../services/app.service';

@Component({
    selector: 'booking-flow-space-item',
    templateUrl: './space-item.component.html',
    styleUrls: ['./space-item.component.scss']
})
export class BookingFlowSpaceItemComponent extends BaseComponent implements OnChanges {
    /** Space to display */
    @Input() public space: ISpace;
    /** Whether the space has been selected for booking */
    @Input() public selected: boolean;
    /** Emitter for changes to selected state of the space */
    @Output() public selectedChange = new EventEmitter<boolean>();
    /** Building associated with the set space */
    public building: IBuilding;

    public level: ILevel;

    constructor(private _service: AppService) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.space && this.space) {
            this.timeout('building', () => {
                this.building = this._service.Spaces.building(this.space);
                this.level = this._service.Spaces.level(this.space);
            }, 100);
        }
    }

    /** Booking type for the space */
    public get book_type() {
        // return (this.space ? this.space.book_type : null) || 'Book';
        return 'Book';
    }

    /**
     * Toggle the selected state of the space
     */
    public toggle() {
        this.selected = !this.selected;
        this.selectedChange.emit(this.selected);
    }

    /**
     * Open modal to show the location of the space on a map
     */
    public showMap() {
        this._service.Overlay.openModal('view-room', { data: {
            room: this.space,
        }}, (e) => e.close());
    }
}

