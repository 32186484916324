import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { NewVisitorModalComponent } from '../../overlays/new-visitor-modal/new-visitor-modal.component';

import * as moment from 'moment';
import * as dayjs from 'dayjs';
import { IEvent, IAttendee } from './models/interfaces';

@Injectable({
    providedIn: 'root',
})
export class GuestsService extends BaseService<IAttendee> {
    constructor(protected http: CommsService) {
        super();
        this.model.name = 'visitor';
        this.model.route = '/guests';
        this.subjects.list = new BehaviorSubject<IAttendee[]>([]);
        this.observers.list = this.subjects.list.asObservable();
        this.set('timeline', {});
    }

    public load() {
        this.parent.Overlay.setupModal('new-visitor', { cmp: NewVisitorModalComponent });
    }

    public guestsForDate(zoneId: string = '', date: Date = new Date()) {
        const period_start = dayjs(date).startOf('day').unix();
        const period_end = dayjs(date).endOf('day').unix();

        return Promise.all([
            this.parent.Events.query({
                period_start,
                period_end,
                zone_ids: zoneId ? zoneId : this.parent.Buildings.current().id,
            }),
            this.query({
                period_start,
                period_end,
                zone_ids: zoneId ? zoneId : this.parent.Buildings.current().id,
            })
        ]).then(values => {
            const events = values[0];
            const guests = values[1];
            const guestDict = {}; guests.forEach(m => guestDict[m.email] = m);

            const hosts = [];
            const visitors = [];

            events.forEach((event) => {
                hosts.push({ event, attendee: event.attendees.find((f) => f.email === event.host) });
                const v = event.attendees.filter((f) => !!f.visit_expected);
                v.forEach((visitor) => {
                    const guest = {...guestDict[visitor.email], ...visitor};
                    visitors.push({ event, attendee: guest });
                });
            });

            const sorter = (a: { event: IEvent }, b: { event: IEvent }): number => {
                return a.event.event_start - b.event.event_start || a.event.event_end - b.event.event_end;
            };
            return { hosts: hosts.sort(sorter), visitors: visitors.sort(sorter) };
        });
    }

    public add(data: { [name: string]: any }) {
        const bld = this.parent.Buildings.current() || null;
        const booking = {
            attendees: [this.parent.Users.current(), ...(data.visitors || [])],
            date: data.date,
            title: data.name,
            duration: data.duration || 60,
            host: data.host,
            walk_in: data.walk_in,
            room: null, // this.parent.Rooms.item(bld.visitor_space),
            level_id: data.level_id || (data.level ? data.level.id : null),
        };
        if (booking.level_id) {
            delete booking.room;
        }
        return this.parent.Events.add(booking);
    }

    public updateItem(id: string, data: { [name: string]: any }) {
        const booking = {
            id,
            attendees: [this.parent.Users.current(), ...(data.visitors || [])],
            date: data.date,
            duration: 60,
            room: { id: '' }, // this.parent.Buildings.current().visitor_space || '' },
        };
        return this.parent.Events.updateItem(id, booking);
    }

    protected format(data: { [name: string]: any }) {
        for (const v of data.visitors || []) {
            if (v.organisation && typeof v.organisation !== 'string') {
                v.organisation_id = (v.organisation as any).id || v.organisation_id;
                v.organisation_name = (v.organisation as any).name || v.organisation_name;
            } else if (v.organisation) {
                v.organisation_name = (v.organisation as string) || v.organisation_name;
            }
        }
        const now = moment();
        const id = `${now.unix().toString()}_${Math.floor(Math.random() * 89999 + 10000).toString()}`;
        const formatted_data: any = {
            id,
            group: data.visitors.length > 1,
            visitors: data.visitors,
            group_name: data.visitors.length === 1 ? data.visitors[0].name : data.name,
            date: Math.floor(data.date / 1000),
        };
        if (data.room_id) {
            formatted_data.room_id = data.room_id;
        }
        return formatted_data;
    }

    /**
     * Open modal to view visitor details
     * @param item Visitor data
     */
    public view(item: IAttendee) {
        if (this.parent) {
            this.parent.Overlay.openModal('visitor-details', { data: { visitor: item } }).then((inst: any) =>
                inst.subscribe((event) => {
                    if (event.type === 'close') {
                        event.close();
                    }
                })
            );
        }
    }

    /**
     * Open modal to create new visitor
     * @param next Callback for events post by modal
     */
    public new(data?: { [name: string]: any }) {
        return new Promise((resolve) => {
            if (this.parent) {
                this.parent.Overlay.openModal('new-visitor', { data: {} }, (event) => {
                    if (event.type === 'close') {
                        event.close();
                    }
                    resolve(event);
                });
            } else {
                resolve(null);
            }
        });
    }

    /**
     * Checkin a visitor group or individual
     * @param id Visitor Group ID
     * @param fields Body of the request
     */
    public checkin(eventId: string, attendeeId: string, state: boolean) {
        const url = `${this.parent.Events.endpoint}/${eventId}/guests/${attendeeId}/checkin?state=${state}`;
        return this.http.post(url).toPromise();
    }

    /**
     * Get the status of a visitor group or individual
     * @param group Visitor group to check
     * @param user_id ID of the individual to check
     */
    public status(group: /*IVisitorGroup*/ any, user_id?: string) {
        const key = `checkin|${group.id}|${user_id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                let url = `${this.endpoint}/${group.id}`;
                if (user_id) {
                    url += `?q=${user_id}`;
                }
                let value = null;
                this.http.get(url).subscribe(
                    (d) => (value = d),
                    (e) => {
                        reject(e);
                        this.promises[key] = null;
                    },
                    () => {
                        resolve(value);
                        this.promises[key] = null;
                    }
                );
            });
        }
        return this.promises[key];
    }

    public clearList() {
        super.clearList();
        this.set('timeline', {});
    }

    public updateList(list: IAttendee[], clear: boolean = false) {
        super.updateList(list, clear);
        const timeline = this.get('timeline') || {};
        // Add bookings to timeline
        for (const item of list) {
            const date = moment(item.start);
            const day = timeline[date.format('YYYY/MM/DD')] || [];
            const found = day.findIndex((i) => item.email === i.email);
            if (found >= 0) {
                day.splice(found, 1);
            }
            day.push(item);
            timeline[date.format('YYYY/MM/DD')] = day;
        }
        // Sort events in the timeline
        for (const key in timeline) {
            if (timeline.hasOwnProperty(key)) {
                timeline[key].sort((a, b) => a.date - b.date);
            }
        }
        this.set('timeline', timeline);
        this.set('last_update', moment().valueOf());
    }

    public removeFromList(list: any[]) {
        super.removeFromList(list);
        const timeline = this.get('timeline') || {};
        for (const item of list) {
            const date = moment(item.date).format('YYYY/MM/DD');
            const day = timeline[date];
            if (day) {
                const found = day.findIndex((i) => item.id === i.id);
                if (found >= 0) {
                    day.splice(found, 1);
                }
            }
        }
        this.set('timeline', timeline);
    }

    /**
     * Convert visitor data to local format
     * @param item Visitor data
     */
    public processItem(item: { [name: string]: any }) {
        return item as IAttendee;
    }
}
