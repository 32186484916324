import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { Utils } from '../../shared/utility.class';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'room-select-modal',
    templateUrl: './room-select-modal.template.html',
    styleUrls: ['./room-select-modal.styles.scss'],
})
export class RoomSelectModalComponent extends OverlayContentComponent<AppService> {
    public model: any = {};

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        if (this.model.spaces) {
            this.model.select_list = [ ...this.model.spaces ];
        }
        this.subs.obs.bld = this.service.Buildings.listen((bld) => {
            this.initLists();
        });
        this.initLists();
        this.updateList();
    }

    public initLists() {
        this.model.bld_list = this.service.Buildings.list();
        this.model.zone = this.service.Buildings.current().id;
        this.model.index = this.model.bld_list.findIndex(i => i.id === this.model.zone);

        const room_types = this.service.Buildings.organisation().space_types;
        this.model.room_types = Object.keys(room_types).map(i => ({ id: i, name: room_types[i] }));
        this.model.room_types.unshift({ id: -1, name: 'All Room Types' });
        this.model.type = -1;
        this.model.type_index = 0;
    }

    public updateList() {
        const date = moment(this.model.date || undefined);
        this.model.loading = true;
        const zones = [this.model.zone || this.service.Buildings.current().id];
        if (this.model.type !== -1) {
            zones.push(this.model.type);
        }
        this.service.Spaces.available({
            date: date.valueOf(),
            duration: this.model.duration || 60,
            zone_ids: zones.reduce((a, i) => a += a ? ',' + i : i, ''),
            ignore: this.model.id
        }).then((list) => {
            this.model.rooms = list.filter(i => zones.reduce((a, v) => a && i.zones.indexOf(v) >= 0, true));
            this.model.rooms.sort((a, b) => (a.level.name || '').localeCompare(b.level.name) || (a.name || '').localeCompare(b.name));
            this.model.loading = false;
        }, _ => this.model.loading = false);
    }

    public select(item) {
        this.model.selected = item;
        if (this.model.multi) {
            if (!this.model.select_list) { this.model.select_list = []; }
            item.selected = true;
            this.model.select_list.push(item);
            this.model.select_list = Utils.unique(this.model.select_list, 'id');
        } else {
            this.event('Select');
        }
    }

    public remove(item) {
        if (this.model.select_list) {
            const index = this.model.select_list.findIndex(i => i.id === item.id);
            if (index >= 0) {
                this.model.select_list[index].selected = false;
                this.model.select_list.splice(index, 1);
            }
        }
    }

    public finished() {
        if (this.model.select_list && this.model.select_list.length > 0) {
            this.event('Select');
        }
    }
}
