/*
 * @Author: alex.sorafumo
 * @Date:   2017-04-03 15:50:46
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-06-19 13:27:00
 */

import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';

import { BaseMockBackend } from './base.mock';
import { MOCK_BUILDINGS, MOCK_ORG, MOCK_LEVELS } from './mock-data';

export class MockBuildingsBackend extends BaseMockBackend {

    private endpoint = `/api/engine/v2`;
    private allData = [...MOCK_ORG, ...MOCK_BUILDINGS, ...MOCK_LEVELS];

    constructor(protected model) {
        super(model);
        MOCK_REQ_HANDLER.unregister(`${this.endpoint}/zones`);
    }

    protected load() {
        if (this.model.log) { this.model.log('MOCK', 'Loading request for zones'); }
        setTimeout(() => {
            MOCK_REQ_HANDLER.register(`${this.endpoint}/zones`, this.model.bld_data, (e) => this.handleZone(e));
            MOCK_REQ_HANDLER.register(`${this.endpoint}/zones/:id/metadata`, this.model.bld_data, (e) => this.handleZoneMetadata(e));
            this.state.next(true);
        }, 50);
    }

    private handleZone(event) {
        if (event.fragment.tags) {
            return this.allData.filter(f => f.tags === event.fragment.tags);
        }
        return null;
    }

    private handleZoneMetadata(event) {
        if (event.params.id) {
            return this.allData.find(f => f.id === event.params.id);
        }
        return null;
    }
}
