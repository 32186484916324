
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';

import { AppService } from '../../../../services/app.service';
import { ICateringMenuCategory } from '../../../../services/data/models/interfaces';
import { Utils } from '../../../../shared/utility.class';
import { UploadManager } from '@acaprojects/ngx-uploads';

import * as blobUtil from 'blob-util';
@Component({
    selector: 'catering-group-modal',
    templateUrl: './group-modal.component.html',
    styleUrls: ['./group-modal.component.scss']
})
export class CateringGroupModalComponent extends OverlayContentComponent<AppService> {

    public model: { [name: string]: any } = {};
    /** Form data for catering category */
    public form: { [name: string]: any } = {};
    /** Whether a request is being processed */
    public loading: boolean;
    /** Whether a image upload request is being processed */
    public loading_image: boolean;
    /** Whether the last request was successful */
    public success: boolean;

    public type_options: string[] = ['none', 'kitchen', 'pantry'];

    /** Parent category */
    public get category(): ICateringMenuCategory {
        return this.model.category;
    }

    /** Category to edit */
    public get group(): ICateringMenuCategory {
        return this.model.group;
    }

    /** Whether the form fields are valid */
    public get valid(): boolean {
        return !!this.form.name && this.type >= 0;
    }

    /** Index of the active catering type */
    public get type(): number {
        return this.type_options.indexOf(this.form.catering_type || this.type_options[0]);
    }

    constructor(private _upload_manager: UploadManager, protected _cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    public init(): void {
        if (this.group) {
            this.form = { catering_type: this.category.catering_type, ...this.group };
        }
    }

    /**
     * Save new category or changes to the existing category
     */
    public save() {
        const bld = this.service.Buildings.current();
        if (this.service && bld) {
            this.form.zones = Utils.unique([ ...(this.category.zones || []), bld.id]);
            this.form.parent = [this.category.id];
            this.loading = true;
            const request = this.form.id
                ? this.service.CateringCategories.updateItem(this.form.id, this.form)
                : this.service.CateringCategories.add(this.form)
            request.then(() => {
                this.success = true;
                this.timeout('close', () => this.event('finish'), 3000);
            }, () => {
                this.loading = false;
                this.service.error(`Failed to ${this.category ? 'update' : 'create'} category`);
            })
        }
    }

    /** Upload the image to the cloud */
    public uploadImage(event) {
        if (event && event.target && event.target.files) {
            const files: FileList = event.target.files;
            if (files.length) {
                const fileReader = new FileReader();
                fileReader.onloadend = (e: any) => {
                    const arrayBuffer = e.target.result;
                    const blob = blobUtil.arrayBufferToBlob(arrayBuffer, 'image/png');
                    this._upload_manager.upload([blob], { file_name: files[0].name });
                    const upload = this._upload_manager.uploads[this._upload_manager.uploads.length - 1];
                    this.interval('upload', () => {
                        if (upload.complete || upload.error) {
                            if (upload.access_url && upload.complete) {
                                this.form.src = upload.access_url;
                            }
                            console.log('Complete:', upload);
                            this.clearInterval('upload');
                        }
                    })
                };
                fileReader.readAsArrayBuffer(files[0]);
            }
        }
    }
}

