
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ADynamicFormField } from '@acaprojects/ngx-dynamic-forms';

import { BaseComponent } from '../../../globals/base.component';

import * as dayjs from 'dayjs';
import { CUSTOM_FIELD_REGISTER } from '../../../globals/custom-field-register';

@Component({
    selector: 'custom-date-field',
    templateUrl: './date-field.component.html',
    styleUrls: ['./date-field.component.scss']
})
export class CustomDateFieldComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Display value of the currently selected date */
    public display: string;
    /** Whether to show date picker tooltip */
    public show_tooltip: boolean;
    /** Timestamp of the current time in ms from UTC epoch */
    public today: number;

    constructor(protected _field: ADynamicFormField, protected _group: FormGroup) {
        super();
    }

    public get field(): ADynamicFormField {
        return this._field;
    }
    public get group(): FormGroup {
        return this._group
    }

    public ngOnInit(): void {
        this.today = dayjs().valueOf();
        this.subscription('control', this._field.control.valueChanges.subscribe(() => this.updateDisplay()));
        this.updateDisplay();
    }

    public updateDisplay() {
        this.display = dayjs(this._field.getValue() || null).format('ddd DD MMM YYYY');
    }

    public setValue(value: number) {
        const old_value = this._field.control.value;
        const old_date = dayjs(old_value);
        const date = dayjs(value).hour(old_date.hour()).minute(old_date.minute());
        (this._field.control as any).setValue(date.valueOf());
        this.show_tooltip = false;
    }
}

CUSTOM_FIELD_REGISTER.date = CustomDateFieldComponent;
