import { Component } from '@angular/core';
import { OverlayContentComponent, IDynamicFieldOptions } from '@acaprojects/ngx-widgets';
import { Validators } from '@angular/forms';

import { AppService } from '../../services/app.service';
import { IUser } from '../../services/data/models/interfaces';
import { CustomDateFieldComponent } from '../../shared/components/custom-fields/date-field/date-field.component';
import { CustomTimeFieldComponent } from '../../shared/components/custom-fields/time-field/time-field.component';

import * as moment from 'moment';
import { CUSTOM_FIELD_REGISTER } from '../../shared/globals/custom-field-register';

@Component({
    selector: 'new-visitor-modal',
    templateUrl: './new-visitor-modal.component.html',
    styleUrls: ['./new-visitor-modal.component.scss']
})
export class NewVisitorModalComponent extends OverlayContentComponent<AppService> {

    public model: { [name: string]: any } = {};

    public init() {
        const bld = this.service.Buildings.current() || { levels: [] };
        const fields: IDynamicFieldOptions<any>[] = [
            {
                key: 'host', label: 'Host', flex: true, control_type: 'custom', cmp: CUSTOM_FIELD_REGISTER.user,
                metadata: { service: this.service.Users }, required: true
            },
            {
                key: 'level', label: 'Meeting Point', flex: true, control_type: 'dropdown',
                options: bld.levels, required: true
            },
            {
                key: 'date', label: 'Date', control_type: 'custom',
                cmp: CustomDateFieldComponent, required: true
            },
            {
                key: 'time', label: 'Time', control_type: 'custom',
                cmp: CustomTimeFieldComponent, required: true, refs: ['date']
            }
        ];
        this.model.purpose_list = ['Business', 'Project', 'Delivery', 'Interview', 'Maintainance', 'Social', 'Training', 'Other'];
        this.model.levels = bld.levels;
        this.service.Buildings.listen((bldn) => {
            if (bldn) {
                fields[1].options = bldn.levels;
                this.model.levels = bldn.levels;
                this.model.booking_fields = [ ...fields ];
            }
        });
        this.model.booking_fields = fields;
        this.processUserFields();
    }

    public processUserFields() {
        const enable = this.model.new_user && this.model.new_user.email;
        const email = enable ? this.model.new_user.email : '';
        const first_name = enable ? this.model.new_user.first_name : '';
        const last_name = enable ? this.model.new_user.last_name : '';
        const phone = enable ? this.model.new_user.phone : '';
        const host = this.model.details ? this.model.details.host : null;
        const fields: IDynamicFieldOptions<any>[] = [
            { key: 'host', label: 'Host', hide: true, value: host },
            {
                key: 'contact', label: 'Search', flex: true, control_type: 'custom', cmp: CUSTOM_FIELD_REGISTER.user,
                metadata: { service: this.service.Contacts }, disabled: !host, refs: ['host']
            },
            { key: 'email', label: 'Email', flex: true, control_type: 'text', validators: [Validators.email], required: true, value: email, disabled: !host },
            { key: 'first_name', label: 'First Name', flex: true, control_type: 'text', required: true, disabled: !enable || !host, value: first_name },
            { key: 'last_name', label: 'Last Name', flex: true, control_type: 'text', required: true, disabled: !enable || !host, value: last_name },
            { key: 'phone', label: 'Contact', flex: true, control_type: 'text', type: 'tel', disabled: !enable || !host, value: phone },
            {
                key: 'purpose', label: 'Purpose', flex: true, control_type: 'dropdown', disabled: !enable,
                options: this.model.purpose_list
            },
        ];
        this.model.user_fields = fields;
    }

    public processDetails(form: { [name: string]: any }) {
        this.model.details = form;
        this.processUserFields();
    }

    public processUser(form: { [name: string]: any }, clear: boolean = false) {
        this.timeout('process', () => {
            const email = this.model.new_user ? this.model.new_user.email : null;
            this.model.new_user = form;
            if ((form && form.contact && form.contact.email !== this.model.new_user.email) || clear) {
                this.model.new_user = form.contact;
                this.processUserFields();
                this.model.validate = !this.model.validate;
            } else if (email !== form.email && !email) {
                this.processUserFields();
            }
        }, 500);
    }

    public addUser(user: IUser) {
        if (!this.model.visitors) { this.model.visitors = []; }
        user.name = `${user.first_name} ${user.last_name}`;
        user.external = true;
        user.purpose = this.model.purpose_list[user.purpose];
        this.model.visitors.push(user);
    }

    public removeUser(user: IUser) {
        if (!this.model.visitors) { return; }
        this.model.visitors.splice(this.model.visitors.indexOf(user), 1);
    }

    public post() {
        this.model.loading = true;
        this.model.processing = true;
        const date = moment(this.model.details.date).seconds(0).millisecond(0).valueOf();
        this.service.Guests.add({
            visitors: this.model.visitors,
            date,
            name: this.model.group_name,
            host: this.model.details.host,
            level: this.model.levels[this.model.details.level]
        }).then(() => {
            this.service.success('Successfully added new visitors');
            this.model.processing = false;
            this.timeout('close', () => this.close());
        }, () => {
            this.service.error('Error adding new visitors');
            this.model.loading = false;
        });
    }

}
