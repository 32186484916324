
import { Component, ChangeDetectorRef, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppService } from '../../../services/app.service';

import { ISpace, IUser, ICateringOrder } from '../../../services/data/models/interfaces';

import * as dayjs from 'dayjs';
import { BaseComponent } from '../../../shared/globals/base.component';

@Component({
    selector: 'booking-flow-details',
    templateUrl: './booking-details.component.html',
    styleUrls: ['./booking-details.component.scss'],
    animations: [
        trigger('show', [
            state('show', style({ opacity: 1, height: '*' })),
            state('hide', style({ opacity: 0, height: 0 })),
            transition('show <=> hide', animate('200ms')),
        ])
    ]
})
export class BookingDetailsComponent extends BaseComponent implements OnChanges {
    @Input() public form: { [name: string]: any } = {};

    @Input() public book: number;

    @Output() public event = new EventEmitter<boolean>();
    /** Whether the booking request is being processed */
    public loading: boolean;
    /** Whether the room availability is being checked */
    public check: boolean;
    /** Whether the booking request succeeded */
    public success: boolean;
    /** Whether to show the list of spaces */
    public show_spaces: boolean;
    /** Whether to show the catering details */
    public show_catering: boolean;
    /** Whether to show the list of attendees */
    public show_attendees: boolean;

    constructor(private _service: AppService) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.form) {
            if (this.form.time_group) {
                this.form = { ...this.form, ...this.form.time_group };
            }
            if (this.form.period_group) {
                this.form = { ...this.form, ...this.form.period_group };
            }
        }
        if (changes.book) {
            const now = dayjs();
            if (this.book > now.valueOf()) {
                this.makeBooking();
            }
        }
    }

    /** Host of the booking */
    public get host(): IUser {
        return this.form.host || {};
    }

    /** Length of the booking */
    public get duration(): number {
        return this.form.duration || 60;
    }

    /** Display date for the given booking */
    public get date(): string {
        const day = dayjs(this.form.date);
        return day.format('DD MMM YYYY');
    }

    /** Display period for the given booking */
    public get period(): string {
        const day = dayjs(this.form.date);
        const end = day.add(this.form.duration || 60, 'm');
        return `${day.format('h:mm A')} - ${end.format('h:mm A')}`
    }

    /** List of attendees for the booking */
    public get attendees(): IUser[] {
        return this.form.attendees || [];
    }

    /** Display string for the names of the attendees */
    public get attendee_list(): string {
        return this.attendees.map(i => i.name).join(', ');
    }

    /** Conferencing details of the booking */
    public get conferencing(): string {
        return this.form.conference || '';
    }

    /** List of the selected rooms for the booking */
    public get spaces(): ISpace[] {
        return this.form.room || []
    }

    /** Display string for the names of the selected rooms */
    public get space_list(): string {
        return this.spaces.map(i => i.name).join(', ');
    }

    /** List of catering orders for booking */
    public get catering(): ICateringOrder[] {
        return Object.keys(this.form.catering).reduce((v, i) => {
            if (this.form.catering && this.form.catering[i]) {
                this.form.catering[i].count = (this.form.catering[i].items || []).reduce((v, j) => v + (j.amount || 0), 0);
                v.push(this.form.catering[i]);
            }
            return v;
        }, []);
    }

    /** Total number of catering items in booking */
    public get item_count(): number {
        return this.catering.reduce((c, i) => c + ((i.items || []).reduce((v, j) => v + (j.amount || 0), 0) || 0), 0);
    }

    /** Private notes for spaces and equipment */
    public get notes(): string[] {
        const notes = this.form.equipment;
        const note_list = Object.keys(notes).reduce((a, v) => { a.push(notes[v]); return a; }, []);
        return note_list;
    }

    /** Private notes for catering */
    public get catering_notes(): string {
        return this.form.catering_notes;
    }

    /** Cost codes for equipment and catering */
    public get cost_codes(): string {
        const eq_codes = this.form.equipment_code || {};
        const code_list = Object.keys(eq_codes).reduce((a, v) => { a.push(eq_codes[v]); return a; }, []);
        const codes = code_list.join(', ');
        return `${ codes || 'none'} | ${this.form.catering_code || 'none'}`;
    }

    /**
     * Perform pre-booking checks and send booking request if the pass
     */
    public makeBooking() {
        const room_ids = this.spaces.map(i => i.id).join(',');
        const date = this.form.date;
        const duration = this.form.duration || 60;
        const now = dayjs();
        const time = dayjs(date);
        if (time.isBefore(now, 'm') && now.diff(time, 'm') > 5) {
            this._service.error('Booking date and time need to be in future');
            this.event.emit(false);
            return;
        }
        this.check = true;
        this.loading = true;
        if (room_ids) {
            const setup = (this.item_count > 0) || this.notes ? 15 * 60 : 0;
            const breakdown = (this.item_count > 0) || this.notes ? 15 * 60 : 0;
            this._service.Spaces.available({
                room_ids,
                date,
                duration,
                setup,
                breakdown
            }).then(() => {
                this.check = false;
                this.loading = false;
                this.sendBookingRequest();
            }, () => {
                this.loading = false;
                this.event.emit(false);
                if (setup || breakdown) {
                    this._service.error(
                        `Selected spaces are not free at the given time(${this.date} ${this.period}).
                         Meetings with additional requirements or catering require a minimum buffer of 15 minutes outside the selected period.`
                    );
                } else {
                    this._service.error(`Selected spaces are not free at the given time(${this.date} ${this.period})`);
                }
            });
        } else {
            this.check = false;
            this.loading = false;
            this.sendBookingRequest();
        }
    }

    /**
     * Send booking request to the server
     */
    public sendBookingRequest() {
        this.loading = true;
        this._service.Events.add(this.form).then((booking) => {
            for (const room of this.form.room) {
                this._service.Spaces.updateTimeline(room.id, [booking]);
            }
            this.loading = false;
            this.success = true;
            this.event.emit(true);
        }, (e) => {
            this.loading = false;
            this._service.error(`Booking Error: ${e}`);
            this.event.emit(false);
        })
    }
}

