/*
 * @Author: Alex Sorafumo
 * @Date:
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time:
 */

import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import { AppService } from '../app.service';
import { Utils } from '../../shared/utility.class';

import * as moment from 'moment';

const FORBIDDEN: string[] = ['model', 'observers', 'subjects'];

export interface IBaseObject {
    id?: string;
    name?: string;
    email?: string;
    [name: string]: any;
}

export class BaseService<T> {
    public parent: AppService = null;
    protected model: { [name: string]: any } = {};
    protected subjects: { [name: string]: BehaviorSubject<any> } = {};
    protected observers: { [name: string]: Observable<any> } = {};
    protected promises: { [name: string]: Promise<any> } = {};
    protected failures: { [name: string]: number } = {};
    protected subs: {
        timers: { [name: string]: number },
        intervals: { [name: string]: number },
        obs: { [name: string]: Subscription | (() => void) }
    } = {
        timers: {},     // Store for timers
        intervals: {},  // Store for intervals
        obs: {}         // Store for observables
    };

    /**
     * Store for DI composer comms service to access http get and post methods for accessing APIs
     */
    protected http: CommsService;

    constructor() {
        this.set<{ [id: string]: T }>('map', {});
        this.set<T[]>('list', []);
    }

    public init() {
        if (!this.parent || !this.parent.Settings.setup || (this.parent.Settings.get('mock') && !(window as any).backend.is_loaded)) {
            return setTimeout(() => this.init(), 500);
        }
        this.load();
    }

    protected load() { }

    /**
     * Get API endpoint
     */
    get endpoint() {
        return this.parent ? `${this.parent.api_endpoint}${this.model.route}` : `/control/api${this.model.route}`;
    }

    get engine_endpoint() {
        return '/api/engine/v2';
    }

    /**
     * Get the stored list of items
     */
    public list(): T[] {
        return this.get('list') || [];
    }

    /**
     * Get filtered list
     * @param filter Filter string
     * @param fields Fields to filter on
     * @param items List of items to filter on. Defaults to the value returned by the `list` method
     */
    public filter(filter: string, fields: string[] = ['id', 'name'], items: T[] = this.list()) {
        return Utils.filter(filter, items, fields);
    }

    /**
     * Empties the list of value stored from `query` requests
     */
    public clearList() {
        this.set<T[]>('list', []);

    }

    /**
     * Listen to changes of given property
     * @param name Name of the property
     * @param next Callback for changes to properties value
     */
    public listen<U>(name: string, next: (data: U) => void) {
        if (this.subjects[name]) {
            return this.observers[name].subscribe(next);
        } else {
            // Create new variable to store property's value
            this.subjects[name] = new BehaviorSubject<U>(this[name] instanceof Function ? null : this[name]);
            this.observers[name] = this.subjects[name].asObservable();
            // Create raw getter and setter for property
            if (!(this[name] instanceof Function)) {
                Object.defineProperty(this, name, {
                    get: (): U => this.get(name),
                    set: (v: U) => this.set<U>(name, v)
                });
            }
            return this.observers[name].subscribe(next);
        }
    }

    /**
     * Get the current value of the given property
     * @param name Name of the property
     */
    public get(name: string) {
        return this.subjects[name] ? this.subjects[name].getValue() : null;
    }

    /**
     * Get item from list with the specific ID
     * @param id ID to search for
     */
    public item(id: string): T {
        if (!id || typeof id !== 'string') { return null; }
        const list = this.get('list') || [];
        for (const item of list) {
            const email = item.email || '';
            if (item.id === id || item.name === id || email.toLowerCase() === id.toLowerCase()) {
                return item;
            }
        }
        return null;
    }

    /**
     * Get observable for property
     * @param name Name of the property. Possible values bookings, new_booking, and update_booking
     */
    public observer(name: string) {
        return this.subjects[name] ? this.observers[name] : null;
    }

    /**
     * Get item listing
     * @param fields Key, value pairs for query parameters
     * Uses endpoint() method to generate the end point for each of the data services based on the route e.g. /staff/bookings for booking component
     * Maintains https calls in the object promises{} with key as the a combination of endpoint and query parameters
     */
    public query(fields?: { [name: string]: any }): Promise<T[]> {
        const query = Utils.generateQueryString(fields);
        let update = true;
        if (fields && !fields.update) {
            for (const f in fields) {
                if (fields.hasOwnProperty(f) && f !== 'offset' && f !== 'limit') {
                    update = false;
                    break;
                }
            }
        }

        const key = `query|${query}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise<T[]>((resolve, reject) => {
                const url = `${this.endpoint}${query ? '?' + query : ''}`;
                this.http.get(url).subscribe(
                    (resp: any) => {
                        const item_list = this.processList(resp.results ? resp.results || resp : resp);
                        if (update) { this.updateList(item_list); }
                        resolve(item_list);
                        setTimeout(() => this.promises[key] = null, 5 * 1000);
                    }, (err) => {
                        this.promises[key] = null;
                        reject(err);
                    });
            });
        }
        return this.promises[key];
    }
    /**
     * Get item with the given ID
     * @param id ID to get the data for
     * @param fields Key, value pairs for query parameters
     */
    public show(id: string, fields?: { [name: string]: any }): Promise<T> {
        if (!id) { return new Promise<T>((rs, rj) => rj('ID is not set')); }
        const key = `show|${id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise<T>((resolve, reject) => {
                const control = fields && fields.control;
                if (control) { delete fields.control; }
                const query = Utils.generateQueryString(fields) || (fields ? 'complete=true' : '');
                const url = `${control ? ('/control/api' + this.model.route) : this.endpoint}/${id}${query ? '?' + query : ''}`;
                this.http.get(url).subscribe(
                    (resp: any) => {
                        const item = this.processItem(resp);
                        resolve(item);
                        this.updateHashMap([item]);
                        if (fields && fields.update) { this.updateList([item]); }
                        setTimeout(() => this.promises[key] = null, 1 * 1000);
                    }, (err) => {
                        if (!this.failures[id]) { this.failures[id] = 0; }
                        this.failures[id]++;
                        if (this.failures[id] > 2) {
                            const new_item = { id, name: id.split('@')[0], email: id } as any;
                            this.updateHashMap([new_item]);
                            this.updateList([new_item]);
                        }
                        this.promises[key] = null;
                        reject(err);
                    });
            });
        }
        return this.promises[key];
    }

    /**
     * Add new item with the given parameters
     * @param data
     */
    public add(data: { [name: string]: any }) {
        const key = `add|${data.id || moment().seconds(0).unix()}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const formatted_data = this.format(data);
                const url = `${this.endpoint}`;
                this.http.post(url, formatted_data).subscribe(
                    (resp: any) => {
                        const item = this.processItem(resp);
                        resolve(item);
                        this.parent.Analytics.event((this.model.name || '').toUpperCase(), `created_${this.model.name}`);
                        this.updateList([item && (item as IBaseObject).id ? item : this.processItem(formatted_data)]);
                        setTimeout(() => this.promises[key] = null, 2 * 1000);
                    }, (err) => {
                        this.parent.Analytics.event((this.model.name || '').toUpperCase(), `create_${this.model.name}_fail`);
                        this.promises[key] = null;
                        reject(err);
                    });
            });
        }
        return this.promises[key];
    }

    /**
     * Alias for add method
     * @param data
     */
    public new(data: { [name: string]: any }) {
        return this.add(data);
    }

    /**
     * Update item with given ID
     * @param id ID of the item
     * @param data New values to replace on the old item
     */
    public update(id: string, data: { [name: string]: any }, link?: string) {
        return new Promise((resolve, reject) => {
            if (!id) { return reject('Invalid ID given'); }
            data.link = link;
            this.parent.confirm({
                ...this.confirmSettings('update', data),
                event: (event) => {
                    if (event.type === 'Accept') {
                        this.updateItem(id, data).then((d) => resolve(d), (e) => reject(e));
                    } else {
                        reject('User cancelled');
                    }
                    event.close();
                }
            });
        });
    }

    /**
     * Request to the server to update the given item
     * @param id ID of the item to update
     * @param data Data to replace on the server's copy
     */
    public updateItem(id: string, data: { [name: string]: any }) {
        if (!id) { return new Promise((rs, rj) => rj('Invalid ID given')); }
        const key = `update|${id || moment().seconds(0).unix()}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const formatted_data = this.format(data);
                const url = `${this.endpoint}/${id}`;
                this.http.put(url, formatted_data).subscribe(
                    (resp: any) => {
                        this.removeFromList([{ id }] as any[]);
                        const item = this.processItem(resp);
                        // Update item data
                        this.updateList([item]);
                        resolve(item);
                        formatted_data.id = id;
                        this.postUpdate(formatted_data, item);
                        this.parent.Analytics.event((this.model.name || '').toUpperCase(), `updated_${this.model.name}`);
                        setTimeout(() => this.promises[key] = null, 2 * 1000);
                    }, (err) => {
                        this.promises[key] = null;
                        this.parent.Analytics.event((this.model.name || '').toUpperCase(), `update_${this.model.name}_fail`);
                        reject(err);
                    });
            });
        }
        return this.promises[key];
    }

    protected postUpdate(data: any, item: T) { }

    /**
     * Execute task for the the given item
     * @param id Item ID
     * @param task Name of the task to execute
     */
    public task(id: string, task: string, fields?: { [name: string]: any }) {
        const key = `task|${id}|${task}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const url = `${this.endpoint}/${id}/${task}`;
                const body: { [name: string]: any } = {
                    id,
                    _task: task
                };
                if (fields) {
                    for (const k in fields) {
                        if (fields.hasOwnProperty(k)) {
                            body[k] = fields[k];
                        }
                    }
                }
                this.http.post(url, body).subscribe(
                    (resp: any) => {
                        resolve(resp || {});
                        setTimeout(() => this.promises[key] = null, 200);
                    }, (err) => {
                        this.promises[key] = null;
                        reject(err instanceof Array ? err[0] : err);
                    });
            });
        }
        return this.promises[key];
    }

    /**
     * Remove item with the given ID
     * @param id ID of the item
     */
    public remove(id: string, fields?: { [name: string]: any }) {
        return new Promise((resolve, reject) => {
            if (!id) { return reject('Invalid ID given'); }
            const item = this.item(id) || {};
            this.parent.confirm(
                this.confirmSettings('delete', { ...item, link: fields ? fields.link : ''}),
                (event) => {
                    if (event.type === 'Accept') {
                        this.deleteItem(id, fields).then((d) => resolve(d), (e) => reject(e));
                    } else {
                        reject('User cancelled');
                    }
                    event.close();
                });
        });
    }

    /**
     * Alias for the remove method
     * @param id
     */
    public delete(id: string, fields?: { [name: string]: any }) {
        return this.remove(id, fields);
    }

    /**
    * Alias for the remove method when a meeting is declined by a non host
    * @param id
    */
    public decline(id: string, fields?: any) {
        return this.remove(id, fields);
    }

    /**
     * Request to delete item on the server
     * @param id ID of the item to delete
     */
    protected deleteItem(id: string, fields?: { [name: string]: any }) {
        const query = Utils.generateQueryString(fields);
        const key = `delete|${id || moment().seconds(0).unix()}|${query}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const url = `${this.endpoint}/${id}${query ? '?' + query : ''}`;
                this.http.delete(url).subscribe(
                    (resp: any) => {
                        const list = [{ id }];
                        this.removeFromList(list as any);
                        resolve();
                        this.parent.Analytics.event((this.model.name || '').toUpperCase(), `removed_${this.model.name}`);
                        setTimeout(() => this.promises[key] = null, 2 * 1000);
                    }, (err) => {
                        this.promises[key] = null;
                        this.parent.Analytics.event((this.model.name || '').toUpperCase(), `remove_${this.model.name}_fail`);
                        reject(err);
                    });
            });
        }
        return this.promises[key];
    }

    /** Following operations are performed using updateList()
     * a. Sorts items list in alphabetic order
     * b. Adds new items and
     * c. updates existing items in the item list store
     * d. clears the list to empty if clear:boolean is set to true
     *
     * @param input_list List of new/updated items
     * @param clear boolean to clear the list
     */
    protected updateList(input_list: T[], clear: boolean = false) {
        // Get current list
        const item_list = clear ? [] : this.list() || [];
        // Add any new items to the list
        for (const i of (input_list || [])) {
            const input = i as IBaseObject;
            let found = false;
            for (const i2 of item_list) {
                const item = i2 as IBaseObject;
                if (item.id === input.id || (input.email && item.email === input.email)) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                item_list.push(input as any);
            }
        }
        // Sort list
        (item_list as IBaseObject[]).sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        // Store changes to the list
        this.updateHashMap(item_list);
        this.set('list', item_list);
    }

    /**
     * Remove given items from the item list store
     * @param list list of items to remove
     */
    protected removeFromList(list: T[]) {
        const item_list = this.list() || [];
        for (const item of list) {
            for (const i of item_list) {
                if ((i as IBaseObject).id === (item as IBaseObject).id) {
                    item_list.splice(item_list.indexOf(i), 1);
                    break;
                }
            }
        }
        this.set('list', item_list);
    }

    public clear(fields?: { [name: string]: any }) {
        this.set<T[]>('list', []);
        if ((this as any).timeline) {
            this.set<{ [id: string]: T[] }>('timeline', {});
        }
    }

    /**
     * Update random access list of items
     * @param list List of items. Each item requires an id parameter
     */
    protected updateHashMap(list: T[]) {
        const map: { [id: string]: T } = {};
        for (const item of list) {
            map[(item as IBaseObject).id] = item;
        }
        this.set('map', map);
    }

    public fromMap(id: string): T {
        const map: { [id: string]: T } = this.get('map');
        return map[id] || null;
    }

    /**
     * Process array of items from the server
     * @param input_list Array of items
     */
    public processList(input_list: { [name: string]: any }[]) {
        const output_list: T[] = [];
        for (const key in (input_list || [])) {
            if (input_list.hasOwnProperty(key) && input_list[key]) {
                const out = this.processItem(input_list[key], key);
                if (out) { output_list.push(out); }
            }
        }
        (output_list as IBaseObject).sort((a, b) => (a.name || a.id || '').localeCompare(b.name || b.id || ''));
        return output_list;
    }

    /**
     * Post processing for server data to be used locally
     * @param data Data to format
     */
    protected processItem(raw_item: { [name: string]: any }, id?: string): T {
        return <T>raw_item;
    }

    /**
     * Pre-processing for local data to be sent to the server
     * @param data Data to format
     */
    protected format(data: { [name: string]: any }) {
        const formatted_data = data;
        return formatted_data;
    }

    /**
     * Set the value of the given property
     * @param name Name of the property
     * @param value New value to assign to the property
     */
    protected set<U>(name: string, value: U) {
        if (this.subjects[name]) {
            this.subjects[name].next(value);
        } else {
            // Create new variable to store property's value
            this.subjects[name] = new BehaviorSubject<U>(value);
            this.observers[name] = this.subjects[name].asObservable();
            // Create raw getter and setter for property
            if (!(this[name] instanceof Function)) {
                Object.defineProperty(this, name, {
                    get: (): U => this.get(name),
                    set: (v: U) => this.set(name, v)
                });
            }
        }
    }

    /**
     * Generate settings for confirm modal
     * @param key
     * @param fields
     */
    protected confirmSettings(key: string, fields: { [name: string]: any } = {}) {
        const settings: { [name: string]: any } = {
            title: '',
            message: '',
            icon: '',
            link: fields.link,
            accept: 'Ok',
            cancel: true
        };
        const name = (fields.name || fields.title || '');
        switch (key) {
            case 'delete':
                settings.title = `Delete ${this.model.name}`;
                settings.message = `Are you sure you wish to delete ${this.model.name} ${name ? '\'' + name + '\'' : ''}?`;
                settings.icon = 'delete';
                break;
            case 'decline':
                settings.title = `Decline ${this.model.name}`;
                settings.message = `Are you sure you wish to decline ${this.model.name} '${fields.name || fields.title || ''}'?`;
                settings.icon = 'decline';
                break;
            case 'update':
                settings.title = `Update ${this.model.name}`;
                settings.message = `Update ${this.model.name} '${fields.name ? fields.name : ''}'?`;
                settings.icon = 'cloud_upload';
                break;
            case 'add':
                settings.title = `New ${this.model.name}`;
                settings.message = `Create new ${this.model.name} '${fields.name ? fields.name : ''}'?`;
                settings.icon = 'add';
                break;
        }
        return settings;
    }

    /**
     * Wrapper for setTimeout. Clears timer with same name
     * @param name Name of the timer
     * @param fn Callback function
     * @param delay Timeout delay in milliseconds
     */
    public timeout(name: string, fn: () => void, delay: number = 300) {
        this.clearTimer(name);
        if (!(fn instanceof Function)) { return; }
        this.subs.timers[name] = <any>setTimeout(() => fn(), delay);
    }

    /**
     * Clears timer with the given name
     * @param name Name of the timer
     */
    public clearTimer(name: string) {
        if (this.subs.timers[name]) {
            clearTimeout(this.subs.timers[name]);
            this.subs.timers[name] = null;
        }
    }

    /**
     * Wrapper for setInterval. Clears interval with same name
     * @param name Name of the interval
     * @param fn Callback function
     * @param delay Timeout delay in milliseconds
     */
    public interval(name: string, fn: () => void, delay: number = 300) {
        this.clearInterval(name);
        if (!(fn instanceof Function)) { return; }
        this.subs.intervals[name] = <any>setInterval(() => fn(), delay);
    }

    /**
     * Clears interval with the given name
     * @param name Name of the interval
     */
    public clearInterval(name: string) {
        if (this.subs.intervals[name]) {
            clearInterval(this.subs.intervals[name]);
            this.subs.intervals[name] = null;
        }
    }
}
