
import { AppShellComponent } from './shell.component';
import { SHELL_COMPONENTS } from './components';
import { WeekViewComponent } from './week-view/week-view.component';
import { BookingListComponent } from './week-view/booking-list/booking-list.component';
import { AppNotificationsComponent } from './notifications/notifications.component';

export const APP_COMPONENTS: any[] = [
    AppShellComponent,
    WeekViewComponent,
    BookingListComponent,
    AppNotificationsComponent,
    ...SHELL_COMPONENTS
];

export const APP_ENTRY_COMPONENTS: any[] = [

];
