import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { BaseComponent } from '../../../shared/globals/base.component';
import { IEvent } from '../../../services/data/models/interfaces';

import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as dayjs from 'dayjs';
import { AppService } from '../../../services/app.service';
dayjs.extend(advancedFormat);

@Component({
    selector: 'booking-list',
    templateUrl: './booking-list.template.html',
    styleUrls: ['./booking-list.styles.scss']
})
export class BookingListComponent extends BaseComponent implements OnChanges {
    @Input() public bookings: IEvent[];
    @Input() public time: number;
    @Output() public event = new EventEmitter();

    public model: any = {};

    public constructor(private service: AppService) {
        super();
    }

    public ngOnChanges(changes: any) {
        if (changes.time && this.time) {
            const time = dayjs(this.time || '');
            this.model.period = `${time.format('ddd Do MMM h:mm A')} - ${time.add(1, 'h').format('h:mm A')}`;
        }
        if (changes && changes.bookings && changes.bookings.currentValue) {
            changes.bookings.currentValue.forEach(event => {
                const lvl = this.service.Spaces.level(event.system.id);
                if (lvl) {
                    event.extension_data['level'] = lvl.name;
                }
                event.extension_data.start = dayjs(event.event_start * 1000).format('h:mm a');
                event.extension_data.end = dayjs(event.event_end * 1000).format('h:mm a');
            });
        }
    }
}
