import { Directive, ElementRef, Input, OnInit, EventEmitter, Renderer2, OnDestroy, Output } from '@angular/core';
import { DragDropRegistry, DragRef, DropListRef } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[dropPoint]',
})
export class DropPointDirective implements OnInit, OnDestroy {
    /** CSS class add to parent element when dragged item is inside */
    @Input() public klass = 'cdk-drop-hover';
    /** Emitter for release events that occure within the element */
    @Output() public dropped = new EventEmitter<void>();

    /** Listener for global scroll events */
    private _scroll_listener: () => void;
    /** Listener for DragDrop move events */
    private _move_listener: Subscription = Subscription.EMPTY;
    /** Listener for DragDrop release events */
    private _up_listener: Subscription = Subscription.EMPTY;
    /** Cached position of the attached element */
    private _cached_position: ClientRect;

    constructor(
        private el: ElementRef<HTMLElement>,
        private _drag_drop_registry: DragDropRegistry<DragRef, DropListRef>,
        private _renderer: Renderer2) {

    }

    public ngOnInit(): void {
        this._scroll_listener = this._renderer.listen('window', 'wheel',
            (e) => { this._cached_position = this.el.nativeElement.getBoundingClientRect(); });

        // Listen for drag item move events
        this._move_listener = this._drag_drop_registry.pointerMove.subscribe((e) => {
            const position = {
                x: e instanceof MouseEvent ? e.clientX : e.touches[0].clientX,
                y: e instanceof MouseEvent ? e.clientY : e.touches[0].clientY,
            };
            if (!this._cached_position) {
                this._cached_position = this.el.nativeElement.getBoundingClientRect();
            }
            const box = this._cached_position;
            if (box && (position.x >= box.left && position.x <= box.right) &&
                (position.y >= box.top && position.y <= box.bottom)) {
                this.el.nativeElement.classList.add(this.klass);
            } else {
                this.el.nativeElement.classList.remove(this.klass);
            }
        });

        // Listen for drag item drop events
        this._up_listener = this._drag_drop_registry.pointerUp.subscribe((e) => {
            if (!e || (!(e instanceof MouseEvent) && (!e.touches || !e.touches[0]))) { return; }
            const position = {
                x: e instanceof MouseEvent ? e.clientX : e.touches[0].clientX,
                y: e instanceof MouseEvent ? e.clientY : e.touches[0].clientY,
            };
            if (!this._cached_position) {
                this._cached_position = this.el.nativeElement.getBoundingClientRect();
            }
            const box = this._cached_position;
            this.el.nativeElement.classList.remove(this.klass);
            if (box && (position.x >= box.left && position.x <= box.right) &&
                (position.y >= box.top && position.y <= box.bottom)) {
                this.dropped.emit();
            }
        });
    }

    public ngOnDestroy(): void {
        // Clear listeners
        if (this._move_listener) {
            this._move_listener.unsubscribe();
            this._up_listener = Subscription.EMPTY;
        }
        if (this._up_listener) {
            this._up_listener.unsubscribe();
            this._up_listener = Subscription.EMPTY;
        }
        if (this._scroll_listener) {
            this._scroll_listener();
        }
    }
}