import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';

import * as faker from 'faker';

function createMockCatering(store: { [service: string]: any }) {
    const menu = createMockMenu();
    MOCK_REQ_HANDLER.register('/api/staff/menu', null, () => menu);
    MOCK_REQ_HANDLER.register(
        '/api/staff/catering/category',
        null,
        e => {
            const item = e.body;
            console.log('Add category');
            if (item) {
                addToMenu(menu, item, true);
            }
            return ':)';
        },
        'POST'
    );
    MOCK_REQ_HANDLER.register(
        '/api/staff/catering/item',
        null,
        e => {
            const item = e.body;
            console.log('Add item');
            if (item) {
                addToMenu(menu, item);
            }
            return ':)';
        },
        'POST'
    );
    store.catering = menu;
}

function createMockMenu() {
    return Array(6)
        .fill(0)
        .map((_, i) => createMockMenuGroup(i));
}

function createMockMenuGroup(index?: number, subgroup: boolean = false) {
    const names = ['Breakfast', 'Lunch', 'Dinner', 'Desert', 'Beverages', 'Other'];
    return {
        id: `CG-${Math.floor(Math.random() * 9999_9999)}`,
        name: `${index === undefined ? faker.commerce.productName() : names[index]}`,
        items: Array(Math.floor(Math.random() * 5 + 1))
            .fill(0)
            .map(i => createMockMenuItem(subgroup))
    };
}

export function createMockMenuItem(subgroup: boolean) {
    const group = !subgroup && Math.floor(Math.random() * 9999_9999) % 5 === 0;
    if (group) {
        return createMockMenuGroup(undefined, true);
    } else {
        return {
            id: `CI-${Math.floor(Math.random() * 9999_9999)}`,
            name: faker.commerce.productName(),
            image_path: faker.image.food(),
            price: Math.floor(Math.random() * 20 + 2) * 50
        };
    }
}

function addToMenu(menu, item, category: boolean = false) {
    if (category && !item.parent_categories) {
        menu.push(item);
    } else {
        let group = menu.find(
            i =>
                (item.parent_categories || item.categories).indexOf(i.id) > -1 ||
                (item.items && item.items.find(j => (item.parent_categories || item.categories).indexOf(j.id) > -1))
        );
        if (group) {
            if (item.parent_categories.indexOf(group.id) < 0) {
                group = group.items.find(j => (item.parent_categories || item.categories).indexOf(j.id) > -1);
            }
            if (group) {
                if (!group.items) {
                    group.items = [];
                }
                console.log('Added', item, 'to', group);
                group.items.push(item);
            }
        }
    }
}

if (!(window as any).backend) {
    (window as any).backend = {};
}

createMockCatering((window as any).backend);
